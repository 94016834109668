import { TConnectionItem, subscribe } from '@chocolate-soup-inc/cs-api-consumer-utils';
import _ from 'lodash';
import { useEffect, useMemo } from 'react';
import { serializeError } from 'serialize-error';
import { client } from '../../config/apollo/api';
import { onSubscriptionData } from '../../config/apollo/cache';
import {
  GetOfficeDocument,
  OfficeFieldsFragmentDoc,
  OnAnyOfficeChangedDocument,
  TGetOfficeQuery,
  TGetOfficeQueryVariables,
  TListAllOfficesQuery,
  TListAllOfficesQueryVariables,
  TListCompanyOfficesQuery,
  TListCompanyOfficesQueryVariables,
  TOfficeFieldsFragment,
  TOnAnyOfficeChangedSubscription,
  TOnAnyOfficeChangedSubscriptionVariables,
  useGetOfficeLazyQuery,
  useListAllOfficesLazyQuery,
  useListCompanyOfficesLazyQuery,
} from '../../generated/graphql';
import { useAllAddressesMap } from '../addresses/queries';
import { useAllCompaniesMap } from '../companies/queries';
import { useFragmentOrFetch } from '../shared/useFragmentOrFetch';
import { useMap } from '../shared/useMap';
import { useQueryAll } from '../shared/useQueryAll';

// SUBSCRIBE

const queryOfficeOnSubscriptionData = (
  data?: TOnAnyOfficeChangedSubscription,
  vars?: TOnAnyOfficeChangedSubscriptionVariables,
) => {
  const { id, companyId, _deleted } = data?.onAnyOfficeChanged || {};

  if (_deleted) {
    return onSubscriptionData(data as Record<string, any>, vars);
  } else if (id && companyId) {
    client
      .query<TGetOfficeQuery, TGetOfficeQueryVariables>({
        query: GetOfficeDocument,
        variables: {
          id,
          companyId,
        },
      })
      .then((response) => {
        const officeData = response?.data?.getOffice;

        if (officeData) {
          const subscriptionData = {
            onAnyOfficeChanged: officeData as TConnectionItem,
          };

          // CHANGED DATA FOR QUERIES THAT DEPEND ON NO VARIABLE (SINCE VARS SHOULD BE AN EMPTY OBJECT IN THIS SITUATION)
          onSubscriptionData(subscriptionData, vars);

          // CHANGED DATA FOR ADDRESS TOO!
          onSubscriptionData(
            {
              onAnyAddressChanged: officeData.address as TConnectionItem,
            },
            vars,
          );
        }
      })
      .catch((error) => {
        console.error('Error', serializeError(error));
      });
  }
};

let anyOfficeChangedSubscribed = false;

export const useSubscribeToOfficeChanged = () => {
  useEffect(() => {
    if (!anyOfficeChangedSubscribed) {
      subscribe({
        client,
        query: OnAnyOfficeChangedDocument,
        onSubscriptionData: (data, vars) => {
          return queryOfficeOnSubscriptionData(
            data as TOnAnyOfficeChangedSubscription,
            vars as TOnAnyOfficeChangedSubscriptionVariables,
          );
        },
        variables: {},
      });

      anyOfficeChangedSubscribed = true;
    }
  }, []);
};

// LIST

export const useFullOffices = <T extends TOfficeFieldsFragment>(offices: T[]) => {
  const { data: companiesMap, error: companiesError, loading: companiesLoading } = useAllCompaniesMap();

  const { data: addressesMap, error: addressesError, loading: addressesLoading } = useAllAddressesMap();

  const fullOffices = useMemo(() => {
    return offices.map((o) => {
      return {
        ...o,
        company: o.companyId ? companiesMap[o.companyId] : undefined,
        address: addressesMap[`${process.env.REACT_APP_OFFICES_TABLE_NAME}|${o.id}`],
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressesMap, companiesMap, JSON.stringify(offices)]);

  return {
    data: fullOffices,
    error: companiesError || addressesError,
    loading: companiesLoading || addressesLoading,
  };
};

export type TOfficeType<T extends TOfficeFieldsFragment = TOfficeFieldsFragment> = ReturnType<
  typeof useFullOffices<T>
>['data'][number];

export const useQueryAllCompanyOffices = (variables: TListCompanyOfficesQueryVariables) => {
  useSubscribeToOfficeChanged();

  const { data, error, loading } = useQueryAll<
    TListCompanyOfficesQuery,
    TListCompanyOfficesQueryVariables,
    TOnAnyOfficeChangedSubscriptionVariables
  >({
    useQuery: useListCompanyOfficesLazyQuery,
    variables,
  });

  const { data: offices, error: fullError, loading: fullLoading } = useFullOffices(_.compact(data?.listOffices.items));

  return {
    data: offices,
    error: error || fullError,
    loading: loading || fullLoading,
  };
};

export const useQueryAllOffices = () => {
  useSubscribeToOfficeChanged();

  const { data, error, loading } = useQueryAll<
    TListAllOfficesQuery,
    TListAllOfficesQueryVariables,
    TOnAnyOfficeChangedSubscriptionVariables
  >({
    useQuery: useListAllOfficesLazyQuery,
    variables: {},
  });

  const {
    data: offices,
    error: fullError,
    loading: fullLoading,
  } = useFullOffices(_.compact(data?.listAllOffices.items));

  return {
    data: offices,
    error: error || fullError,
    loading: loading || fullLoading,
  };
};

export const useAllOfficesMap = () => {
  const { data, error, loading } = useQueryAllOffices();

  const officesMap = useMap<(typeof data)[number]>(data);

  return {
    data: officesMap,
    error,
    loading: loading || officesMap == null,
  };
};

// ITEMS

export const useFragmentOrFetchOffice = (variables: TGetOfficeQueryVariables) => {
  useSubscribeToOfficeChanged();

  const { data, loading, error } = useFragmentOrFetch<TOfficeFieldsFragment, TGetOfficeQuery, TGetOfficeQueryVariables>(
    {
      fragmentDoc: OfficeFieldsFragmentDoc,
      useLazyQuery: useGetOfficeLazyQuery,
      variables,
      __typename: 'Office',
    },
  );

  const {
    data: offices,
    error: fullError,
    loading: fullLoading,
  } = useFullOffices(_.compact([data]) as TOfficeFieldsFragment[]);

  return {
    data: offices[0],
    error: error || fullError,
    loading: loading || fullLoading,
  };
};
