import { compareAsc, format, startOfDay } from 'date-fns';
import _ from 'lodash';
import { quickScore } from 'quick-score';
import { useMemo } from 'react';
import { TAddressParentTypes, TGiftFieldsFragment, TGiftTypes } from '../../generated/graphql';
import { getIconFromAddressParentType, getReadableAddressParentType } from '../addresses/shared';
import { getRemovedInfoFromRecipientData } from '../gifts/shared';
import { TShipmentType } from './queries';

type TGetShipmentAddressInfoProps = {
  shipment: TShipmentType;
};

export type TShipmentAddressInfo = {
  addressTypeIcon?: string;
  readableAddressType?: string;
  recipientName?: string;
};

export const getShipmentAddressInfo = (props: TGetShipmentAddressInfoProps): TShipmentAddressInfo => {
  const { shipment } = props;
  const { company, office, employee } = shipment;

  const officeName = office?.name;
  const officeContactName = office?.contact?.name;
  const companyContactName = company?.contact?.name;

  const addressTypeIcon = getIconFromAddressParentType(shipment?.address?.parentType);

  const readable = getReadableAddressParentType(shipment?.address?.parentType);
  let readableAddressType: string | undefined = readable;
  if (readable === 'Office' && officeName) {
    readableAddressType = officeName;
  } else if (shipment?.address?.parentType === TAddressParentTypes.Company) {
    readableAddressType = 'Main Office';
  }

  let recipientName: string | undefined;
  if (shipment?.address?.parentType === TAddressParentTypes.Employee) {
    recipientName = employee?.fullName as string;
  } else if (shipment?.address?.parentType === TAddressParentTypes.Office) {
    recipientName = officeContactName || `Office ${officeName}`;
  } else if (shipment?.address?.parentType === TAddressParentTypes.Company) {
    recipientName = companyContactName || 'Main Office';
  }

  if (!recipientName) {
    for (const g of shipment?.gifts || []) {
      if (!g?.recipientData) continue;
      recipientName = getRemovedInfoFromRecipientData(g);
    }
  }

  return {
    addressTypeIcon,
    readableAddressType,
    recipientName,
  };
};

export const useShipmentAddressInfo = (shipment?: TShipmentType) => {
  return useMemo(() => {
    if (shipment) {
      return getShipmentAddressInfo({
        shipment,
      });
    } else {
      return {};
    }
  }, [shipment]);
};

type TUseShipmentsWithAddressInfoProps = {
  shipments: TShipmentType[];
};

export type TShipmentWithAddressInfo = TShipmentType & TShipmentAddressInfo;

export const useShipmentsWithAddressInfo = (props: TUseShipmentsWithAddressInfoProps) => {
  const { shipments } = props;

  return useMemo(() => {
    return shipments.map((s) => {
      return {
        ...s,
        ...getShipmentAddressInfo({ shipment: s }),
      };
    });
  }, [shipments]);
};

type TRecipientFilterProps = {
  filter?: string;
  shipment: TShipmentWithAddressInfo;
};

export const filterByRecipient = (props: TRecipientFilterProps) => {
  const { shipment, filter } = props;

  if (filter == null || filter === '') return true;

  if (shipment.recipientName && quickScore(shipment.recipientName, filter) > 0.7) {
    return true;
  }

  for (const gift of shipment.gifts || []) {
    if (gift.recipient?.fullName && quickScore(gift.recipient?.fullName, filter) > 0.7) {
      return true;
    }
  }

  return false;
};

type TGiftTypeFilterProps = {
  filter?: TGiftTypes | 'subscription' | 'one-off';
  shipment: TShipmentWithAddressInfo;
};

export const filterByGiftType = (props: TGiftTypeFilterProps) => {
  const { shipment, filter } = props;

  if (filter == null) return true;

  for (const gift of _.compact(shipment?.gifts || [])) {
    if (gift.type === filter) {
      return true;
    } else if (filter === 'subscription' && [TGiftTypes.Birthday, TGiftTypes.WorkAnniversary].includes(gift.type)) {
      return true;
    } else if (
      filter === 'one-off' &&
      [
        TGiftTypes.BabyGift,
        TGiftTypes.CarePackage,
        TGiftTypes.CustomBricks,
        TGiftTypes.Other,
        TGiftTypes.ReplacementEmployeeBirthday,
        TGiftTypes.ReplacementWorkAnniversary,
        TGiftTypes.Wedding,
      ].includes(gift.type)
    ) {
      return true;
    }
  }

  return false;
};

export const getShipDate = (shipment: TShipmentType | undefined): Date | undefined => {
  if (!shipment) {
    return undefined;
  }
  if (!shipment.label) {
    return undefined;
  }
  const created = shipment.label.createdAt;
  if (!created) {
    return undefined;
  }
  return new Date(created);
};

export const getEventDate = (shipment: TShipmentType): Date | undefined => {
  const items = shipment.gifts ?? [];
  if (items.length === 0) {
    return undefined;
  }
  const item = items[0] as TGiftFieldsFragment;
  const date = item.eventDate;
  if (!date) {
    return undefined;
  }
  return new Date(date);
};

export const getEventDateString = (shipment: TShipmentType): string => {
  const formatString = 'do MMM, yyyy';
  const items = shipment.gifts ?? [];
  const dates = items
    .map((item) => {
      const date = item.eventDate as unknown as Date;
      return startOfDay(date);
    })
    .sort(compareAsc);

  const uniqueDateStrings = _.uniqBy(dates, (date) => date.toString());

  if (items.length === 1) {
    return format(dates[0], formatString);
  }

  if (uniqueDateStrings.length === 1) {
    const a = format(uniqueDateStrings[0], formatString);
    return `${items.length} events on ${a}`;
  }

  const a = format(dates[0], formatString);
  const b = format(dates[dates.length - 1], formatString);
  return `${items.length} events between ${a} and ${b}`;
};
