import Joi from 'joi';
import countries from 'i18n-iso-countries';
import enLocale from './enLocale.json';

countries.registerLocale(enLocale);

const dictionary: Record<string, string[]> = {
  USA: ['united states'],
  BRA: ['brasil'],
};

const countryExtension = (joi: Joi.Root): Joi.Extension => ({
  type: 'country',
  base: joi.string(),
  messages: {
    'country.invalid': 'The {{#label}} is not a valid country.',
  },
  validate: (value: string | undefined | null, helpers: Joi.CustomHelpers<string | undefined | null>) => {
    if (value == null || value.trim() === '') {
      return {
        value: undefined,
      };
    }

    value = value.trim().toLowerCase();
    if (countries.isValid(value)) {
      // ALREADY ISO FORMAT - CONVERT TO ALPHA 3 OR RETURN ITSELF
      return {
        value: (countries.alpha2ToAlpha3(value.toUpperCase()) || value).toUpperCase(),
      };
    }

    const alhpa3 = countries.getAlpha3Code(value, 'en');
    if (alhpa3) return { value: alhpa3.toUpperCase() };

    for (const [key, possibilities] of Object.entries(dictionary)) {
      if (possibilities.includes(value)) return { value: key.toUpperCase() };
    }

    return {
      value: value.toUpperCase(),
      errors: [helpers.error('country.invalid')],
    };
  },
});

export default countryExtension;
