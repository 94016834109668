import {
  CommonButton,
  ConfirmationModal,
  ErrorPage,
  FloatingMenu,
  Icon,
  IconButton,
  LoadingPage,
  TableInner,
  TConfirmationModalProps,
  TMenuItemProps,
  Tooltip,
} from '@chocolate-soup-inc/cs-frontend-components';
import { CellContext } from '@tanstack/react-table';
import clsx from 'clsx';
import _ from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { serializeError } from 'serialize-error';
import { useFragmentOrFetchCompany } from '../../../entities/companies/queries';
import { TUserType, useAdminCognitoUserMap, useQueryAllUsers } from '../../../entities/users/queries';
import { useDeleteUserMutation } from '../../../generated/graphql';
import { INVITE_PATH } from '../../../routes/paths';
import { CompanyModal } from '../CompanyModal/CompanyModal';
import styles from './CompanyUsers.module.scss';

const UserOptions = (
  props: CellContext<TUserType, unknown> & {
    setConfirmationProps: (v: any) => void;
    setActionLoading: (v: boolean) => void;
  },
) => {
  const { cell, setConfirmationProps, setActionLoading } = props;

  const { companyId, email, name, _version: version } = cell.row.original;

  const [deleteUser, { loading: deleteUserLoading }] = useDeleteUserMutation({
    variables: {
      email,
      companyId,
      version,
    },
  });

  const onActualDelete = useCallback(() => {
    setActionLoading(true);
    return deleteUser()
      .then()
      .catch((error) => {
        console.error(serializeError(error));
        toast.error(error.message);
      })
      .then(() => {
        setConfirmationProps(undefined);
        setActionLoading(false);
      });
  }, [deleteUser, setActionLoading, setConfirmationProps]);

  const onDeleteClick = useCallback(() => {
    setConfirmationProps({
      headline: 'Delete user?',
      supportingText: `Are you sure you want to delete the user ${name}?`,
      confirm: 'Delete',
      onConfirmClick: onActualDelete,
    });
  }, [name, onActualDelete, setConfirmationProps]);

  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const options = useMemo(() => {
    const list: TMenuItemProps[] = [];
    const disabled = deleteUserLoading;

    list.push({
      className: styles.removeUserMenuItem,
      disabled,
      label: 'Remove User',
      loading: deleteUserLoading,
      onClick: onDeleteClick,
      type: 'text',
    });

    return list;
  }, [deleteUserLoading, onDeleteClick]);

  if (options.length === 0) return null;

  return (
    <div className={styles.userOptions}>
      <FloatingMenu placement='bottom-end' open={menuOpen} onOpenChange={setMenuOpen} options={options}>
        <IconButton icon='more_vert' onClick={() => setMenuOpen(!menuOpen)} variant='standard' />
      </FloatingMenu>
    </div>
  );
};

const getCognitoUserStatus = (cognitoUser: any): { message: string; icon: string } => {
  let message = 'NOT logged in',
    icon = 'close';
  if (!cognitoUser) return { message, icon };
  if (cognitoUser['UserStatus'] === 'CONFIRMED') {
    message = 'Regular Auth';
    icon = 'check_circle_outline';
  }
  if (cognitoUser['google:UserStatus']) {
    message = 'Logged in with Google';
    icon = 'check_box';
  }
  return { message, icon };
};

export const CompanyUsers = () => {
  const navigate = useNavigate();
  const { companyId } = useParams();

  const {
    data: company,
    loading: companyLoading,
    error: companyError,
  } = useFragmentOrFetchCompany({
    id: companyId as string,
  });

  const { data: cognitoUsers } = useAdminCognitoUserMap(company?.configuration?.authUserPoolId ?? '0');

  const [confirmationProps, setConfirmationProps] =
    useState<Omit<TConfirmationModalProps, 'closeModal' | 'confirmLoading' | 'onCancelClick'>>();

  const [actionLoading, setActionLoading] = useState<boolean>();

  const {
    data: users,
    loading,
    error,
  } = useQueryAllUsers({
    companyId: companyId as string,
  });

  const getRowId = useCallback((user: TUserType) => {
    return user.email;
  }, []);

  const onInviteUserClick = useCallback(() => {
    navigate(generatePath(INVITE_PATH));
  }, [navigate]);

  if (loading || companyLoading)
    return (
      <CompanyModal headline='Company Users'>
        <LoadingPage />
      </CompanyModal>
    );

  if (error || companyError) return <ErrorPage error={error || companyError} />;

  return (
    <CompanyModal headline='Company Users'>
      {!_.isEmpty(confirmationProps) && (
        <ConfirmationModal
          {...confirmationProps}
          closeModal={() => setConfirmationProps(undefined)}
          confirmLoading={actionLoading}
          onCancelClick={() => setConfirmationProps(undefined)}
        />
      )}
      <CommonButton leadingIcon='add' label='Add user' onClick={onInviteUserClick} variant='filled' />
      {users.length > 0 && (
        <TableInner<TUserType>
          columns={[
            { header: 'Name', accessorKey: 'name' },
            { header: 'Email', accessorKey: 'email' },

            {
              header: 'Account type',
              accessorKey: '',
              cell: ({ cell }: CellContext<TUserType, unknown>) => {
                const { isCompanyOwner, type } = cell.row.original;

                return (
                  <span>
                    {isCompanyOwner && 'Primary Owner'}
                    {!isCompanyOwner && (type === 'admin' ? 'Admin' : 'Team Leader')}
                  </span>
                );
              },
            },
            {
              header: 'Active',
              cell: ({ cell }) => {
                const cognitoUser = Object.entries(cognitoUsers).find(([key]) => key === cell.row.original.userId)?.[1];

                const { message, icon } = getCognitoUserStatus(cognitoUser);

                return (
                  <div className={clsx(styles.activeUser)}>
                    <Tooltip message={message}>
                      <Icon icon={icon} />
                    </Tooltip>
                  </div>
                );
              },
            },
            {
              header: '',
              accessorKey: 'type',
              cell: (cell) => (
                <UserOptions
                  {...cell}
                  setConfirmationProps={setConfirmationProps}
                  setActionLoading={setActionLoading}
                />
              ),
            },
          ]}
          data={users}
          emptyText='No users registered. Create the first user so the company can access the client portal.'
          getRowId={getRowId}
          virtual={false}
        />
      )}
    </CompanyModal>
  );
};
