import { Login, OauthCallback, PrivateOutlet } from '@chocolate-soup-inc/cs-frontend-components';
import { Navigate, Route, Routes } from 'react-router-dom';
import Layout from '../pages/shared/Layout';
import {
  ASSEMBLING_PATH,
  COMPANIES_PATH,
  COMPANY_PATH,
  DEPENDANTS_PATH,
  DETAILS_PATH,
  EDIT_PATH,
  EMPLOYEES_PATH,
  EMPLOYEE_ACCOUNT_PATH,
  EMPLOYEE_GIFTS_PATH,
  EMPLOYEE_PATH,
  EVENTS_PATH,
  GIFTS_PATH,
  GIFT_PATH,
  HOME_PATH,
  IMPORT_LIST_PATH,
  IMPORT_PATH,
  INVITE_PATH,
  LOGIN_PATH,
  NEW_HIRES_PATH,
  NEW_PATH,
  OAUTH_CALLBACK_PATH,
  OFFICE_PATH,
  ROOT_PATH,
  SET_FOR_PICKUP_PATH,
  SHIPMENTS_PATH,
  SHIPMENT_GIFTS_PATH,
  SHIPMENT_PATH,
  SKIPPED_EVENTS_PATH,
  SUBSCRIPTIONS_PATH,
  TRACKING_PATH,
  UPCOMING_PATH,
  USERS_PATH,
  LOGS_PATH,
  ONE_OFF_EVENTS_PATH,
  ONE_OFF_GIFTS_PATH,
  GIFT_PRODUCTS_LIST_PATH,
  GIFT_PACKAGES_LIST_PATH,
  PRODUCT_PATH,
  PACKAGE_PATH,
  CUSTOM_PACKAGE_PATH,
} from './paths';

import { List as CompaniesList } from '../pages/companies/List/List';
import { CreateForm as CompanyCreateForm } from '../pages/companies/CreateForm/CreateForm';
import { CompanyDetails } from '../pages/companies/CompanyDetails/CompanyDetails';
import { InviteUserForm } from '../pages/users/InviteUserForm';
import { UpcomingHomeEvents } from '../pages/upcomingEvents/subscriptions/UpcomingEvents/UpcomingHomeEvents';
import { SkippedEvents } from '../pages/upcomingEvents/SkippedEvents/SkippedEvents';
import { UpcomingOfficeEvents } from '../pages/upcomingEvents/subscriptions/UpcomingOfficeEvents/UpcomingOfficeEvents';
import { UpcomingNewHireEvents } from '../pages/upcomingEvents/UpcomingNewHireEvents/UpcomingNewHireEvents';
import { EventsOneOffList } from '../pages/upcomingEvents/UpcomingOneOffEvents/EventsOneOffList/EventsOneOffList';
import { EventCreateForm } from '../pages/upcomingEvents/UpcomingOneOffEvents/EventCreateForm/EventCreateForm';
import { SubscriptionGiftsAssembling } from '../pages/giftAssembling/SubscriptionGiftsAssembling/SubscriptionGiftsAssembling';
import { GiftDetailsWrapper } from '../pages/giftAssembling/GiftDetails/GiftDetails';
import { NewHireGiftsAssembling } from '../pages/giftAssembling/NewHireGiftsAssembling/NewHireGiftsAssembling';
import { Shipments } from '../pages/shipments/Shipments/Shipments';
import { CompanyUsers } from '../pages/companies/CompanyUsers/CompanyUsers';
import { ShipmentModalDetails } from '../pages/shipments/ShipmentModal/ShipmentModalDetails';
import { ShipmentModalGifts } from '../pages/shipments/ShipmentModal/ShipmentModalGifts';
import { ShipmentsTracking } from '../pages/shipments/ShipmentsTracking/ShipmentsTracking';
import { ShipmentsSetForPickup } from '../pages/shipments/ShipmentsSetForPickup/ShipmentsSetForPickup';
import { Employees } from '../pages/employees/Employees/Employees';
import { EmployeeAccount } from '../pages/employees/EmployeeAccount/EmployeeAccount';
import { EmployeeDependants } from '../pages/employees/EmployeeDependants/EmployeeDependants';
import { EmployeeGifts } from '../pages/employees/EmployeeGifts/EmployeeGifts';
import { EmployeesImport } from '../pages/employees/EmployeesImport/EmployeesImport';
import { EmployeesImportList } from '../pages/employees/EmployeesImportList/EmployeesImportList';
import { CompanyUpdateForm } from '../pages/companies/UpdateForm/UpdateForm';
import { ChangelogHistory } from '../pages/changelogHistory/ChangelogHistory';
import { OneOffGiftsAssembling } from '../pages/giftAssembling/OneOffGiftAssembling/OneOffGiftsAssembling';
import { GiftProductList } from '../pages/giftPackConfig/GiftProduct/GiftProductList/GiftProductList';
import { GiftPackageList } from '../pages/giftPackConfig/GiftPackage/GiftPackageList/GiftPackageList';
import { GiftProductCreate } from '../pages/giftPackConfig/GiftProduct/GiftProductCreate/GiftProductCreate';
import { GiftProductUpdate } from '../pages/giftPackConfig/GiftProduct/GiftProductUpdate/GiftProductUpdate';
import { GiftPackageCreate } from '../pages/giftPackConfig/GiftPackage/GiftPackageCreate/GiftPackageCreate';
import { GiftPackageDetails } from '../pages/giftPackConfig/GiftPackage/GiftPackageDetails/GiftPackageDetails';
import { CompanyConfigurePackage } from '../pages/companies/CompanyConfigurePackage/CompanyConfigurePackage';

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path={ROOT_PATH} element={<Navigate to={COMPANIES_PATH} replace={true} />} />
      <Route element={<PrivateOutlet loginPath={LOGIN_PATH} />}>
        <Route element={<Layout />}>
          <Route path={COMPANIES_PATH}>
            <Route path='' element={<CompaniesList />} />
            <Route path={NEW_PATH} element={<CompanyCreateForm />} />
            <Route path={COMPANY_PATH}>
              <Route path={EDIT_PATH} element={<CompanyUpdateForm />} />
              <Route path={DETAILS_PATH} element={<CompanyDetails />} />
              <Route path={USERS_PATH}>
                <Route path='' element={<CompanyUsers />} />
                <Route path={INVITE_PATH} element={<InviteUserForm />} />
              </Route>
              <Route path={CUSTOM_PACKAGE_PATH} element={<CompanyConfigurePackage />} />
            </Route>
          </Route>
          <Route path={EMPLOYEES_PATH}>
            <Route path='' element={<Employees />} />
            <Route path={IMPORT_PATH} element={<EmployeesImport />} />
            <Route path={IMPORT_LIST_PATH} element={<EmployeesImportList />} />
            <Route path={COMPANY_PATH}>
              <Route path={EMPLOYEE_PATH}>
                <Route path={EMPLOYEE_ACCOUNT_PATH} element={<EmployeeAccount />} />
                <Route path={DEPENDANTS_PATH} element={<EmployeeDependants />} />
                <Route path={EMPLOYEE_GIFTS_PATH} element={<EmployeeGifts />} />
              </Route>
            </Route>
          </Route>
          <Route path={LOGS_PATH} element={<ChangelogHistory />} />
          <Route path={GIFT_PACKAGES_LIST_PATH}>
            <Route path='' element={<GiftPackageList />} />
            <Route path={NEW_PATH} element={<GiftPackageCreate />} />
            <Route path={PACKAGE_PATH}>
              <Route path={DETAILS_PATH} element={<GiftPackageDetails />} />
              <Route path={EDIT_PATH} element={<GiftProductUpdate />} />
            </Route>
          </Route>
          <Route path={GIFT_PRODUCTS_LIST_PATH}>
            <Route path='' element={<GiftProductList />} />
            <Route path={NEW_PATH} element={<GiftProductCreate />} />
            <Route path={PRODUCT_PATH}>
              <Route path={EDIT_PATH} element={<GiftProductUpdate />} />
            </Route>
          </Route>
          <Route path={EVENTS_PATH}>
            <Route path={UPCOMING_PATH}>
              <Route path={SUBSCRIPTIONS_PATH}>
                <Route path='' element={<Navigate to={HOME_PATH} replace={true} />} />
                <Route path={HOME_PATH} element={<UpcomingHomeEvents />} />
                <Route path={OFFICE_PATH} element={<UpcomingOfficeEvents />} />
              </Route>
              <Route path={NEW_HIRES_PATH} element={<UpcomingNewHireEvents />} />
              <Route path={ONE_OFF_EVENTS_PATH}>
                <Route path='' element={<EventsOneOffList />} />
                <Route path={NEW_PATH} element={<EventCreateForm />} />
              </Route>
              <Route path={SKIPPED_EVENTS_PATH} element={<SkippedEvents />} />
            </Route>
          </Route>
          <Route path={GIFTS_PATH}>
            <Route path={ASSEMBLING_PATH}>
              <Route path={SUBSCRIPTIONS_PATH}>
                <Route path='' element={<SubscriptionGiftsAssembling />} />
                <Route path={`${COMPANY_PATH}/${GIFT_PATH}`}>
                  <Route path='' element={<GiftDetailsWrapper />} />
                </Route>
              </Route>
              <Route path={NEW_HIRES_PATH}>
                <Route path='' element={<NewHireGiftsAssembling />} />
                <Route path={`${COMPANY_PATH}/${GIFT_PATH}`}>
                  <Route path='' element={<GiftDetailsWrapper />} />
                </Route>
              </Route>
              <Route path={ONE_OFF_GIFTS_PATH}>
                <Route path='' element={<OneOffGiftsAssembling />} />
                <Route path={`${COMPANY_PATH}/${GIFT_PATH}`}>
                  <Route path='' element={<GiftDetailsWrapper />} />
                </Route>
              </Route>
            </Route>
          </Route>
          <Route path={SHIPMENTS_PATH}>
            <Route path='' element={<Shipments />} />
            <Route path={`${COMPANY_PATH}/${SHIPMENT_PATH}`}>
              <Route path={DETAILS_PATH} element={<ShipmentModalDetails />} />
              <Route path={SHIPMENT_GIFTS_PATH} element={<ShipmentModalGifts />} />
            </Route>
            <Route path={`${TRACKING_PATH}`} element={<ShipmentsTracking />} />
            <Route path={`${SET_FOR_PICKUP_PATH}`} element={<ShipmentsSetForPickup />} />
          </Route>
        </Route>
      </Route>
      <Route path={LOGIN_PATH} element={<Login afterLoginPath={ROOT_PATH} />} />
      <Route path={OAUTH_CALLBACK_PATH} element={<OauthCallback afterOauthPath={ROOT_PATH} loginPath={LOGIN_PATH} />} />
    </Routes>
  );
};
