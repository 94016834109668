// import { useFragment } from '@apollo/client';
// import { getFragmentName } from '@chocolate-soup-inc/cs-api-consumer-utils';
// import _ from 'lodash';
// import { useEffect, useMemo, useState } from 'react';
// import {
//   DependantWithEmployeeFieldsFragmentDoc,
//   EmployeeFieldsFragmentDoc,
//   TDependantWithEmployeeFieldsFragment,
//   TGetRecipientQuery,
//   TGetRecipientQueryVariables,
//   TRecipientFieldsFragment,
//   useGetRecipientLazyQuery,
// } from '../../generated/graphql';
import { TDeliveryMethod } from '../../generated/graphql';
import { useQueryAddressById } from '../addresses/queries';
import { TDependantType } from '../dependants/queries';
import { TEmployeeType } from '../employees/queries';

export type TRecipientType = TEmployeeType | TDependantType;

type TGetRecipientAddressId = {
  deliveryMethod?: TDeliveryMethod;
  companyId: string;
  employeeId: string;
  officeId?: string | null;
};

export const isDependant = (recipient: TRecipientType): recipient is TDependantType => {
  return (recipient as TDependantType).employeeId != null;
};

export const useGetRecipientAddressId = (props: TGetRecipientAddressId) => {
  const { companyId, officeId, employeeId, deliveryMethod } = props;

  let addressId: string | undefined;
  let address;

  if (deliveryMethod === TDeliveryMethod.Home) {
    addressId = `${process.env.REACT_APP_EMPLOYEES_TABLE_NAME}|${employeeId}`;
  } else if (deliveryMethod === TDeliveryMethod.Office) {
    addressId = officeId
      ? `${process.env.REACT_APP_OFFICES_TABLE_NAME}|${officeId}`
      : `${process.env.REACT_APP_COMPANIES_TABLE_NAME}|${companyId}`;
  } else if (deliveryMethod === TDeliveryMethod.Hybrid) {
    addressId = officeId
      ? `${process.env.REACT_APP_OFFICES_TABLE_NAME}|${officeId}`
      : `${process.env.REACT_APP_EMPLOYEES_TABLE_NAME}|${employeeId}`;
  }

  address = useQueryAddressById(addressId as string);

  if (!address?.data) {
    addressId = `${process.env.REACT_APP_COMPANIES_TABLE_NAME}|${companyId}`;
  }

  address = useQueryAddressById(addressId as string);

  if (!address?.data) addressId = undefined;

  return addressId;
};

// export const isDependant = (recipient: TRecipientFieldsFragment): recipient is TDependantWithEmployeeFieldsFragment => {
//   return (recipient as TDependantWithEmployeeFieldsFragment).employeeId != null;
// };

// export type TGetRecipient = Exclude<TGetRecipientQuery['getRecipient'], null | undefined>;

// export const useFragmentOrFetchRecipient = (variables: TGetRecipientQueryVariables) => {
//   const [fetched, setFetched] = useState<boolean>(false);

//   const [tableName, id] = variables.id.split('|');

//   const typeName = useMemo(() => {
//     if (tableName.includes('employee')) {
//       return 'Employee';
//     } else {
//       return 'Dependant';
//     }
//   }, [tableName]);

//   const fragmentDoc = useMemo(() => {
//     if (typeName === 'Employee') return EmployeeFieldsFragmentDoc;
//     else return DependantWithEmployeeFieldsFragmentDoc;
//   }, [typeName]);

//   const fragmentName = useMemo(() => {
//     return getFragmentName(fragmentDoc);
//   }, [fragmentDoc]);

//   const { data: fragmentData, missing } = useFragment<
//     Exclude<TGetRecipientQuery['getRecipient'], null | undefined>,
//     TGetRecipientQueryVariables
//   >({
//     from: {
//       id,
//       __typename: typeName,
//     },
//     fragment: fragmentDoc,
//     fragmentName,
//   });

//   const [fetchData, { data: fetchedData, error, loading }] = useGetRecipientLazyQuery({
//     variables,
//     fetchPolicy: 'network-only',
//   });

//   useEffect(() => {
//     if ((_.isEmpty(fragmentData) || !_.isEmpty(missing)) && !loading && !error && !fetched) {
//       setFetched(true);
//       fetchData({
//         variables,
//       });
//     }
//   }, [fragmentData, error, fetchData, fetched, loading, missing, variables]);

//   return {
//     data: fragmentData,
//     fetchedData,
//     error,
//     loading,
//   };
// };
