import _ from 'lodash';
import { useMemo } from 'react';
import { TBasicLogFieldsFragment, TLogUserTypes, useListAllLogsQuery } from '../../generated/graphql';
import { useAllCompaniesMap } from '../companies/queries';
import { useAllEmployeesMap } from '../employees/queries';
import { useAdminCognitoUserMap } from '../users/queries';
import { splitLogFieldName } from './shared';

export const useFullLogs = <T extends TBasicLogFieldsFragment>(logs: T[]) => {
  const { data: companiesMap, error: companiesError, loading: companiesLoading } = useAllCompaniesMap();
  const { data: employeesMap, error: employeesError, loading: employeesLoading } = useAllEmployeesMap();
  const {
    data: adminCognitoUsersMap,
    error: adminCognitoUsersError,
    loading: adminCognitoUsersLoading,
  } = useAdminCognitoUserMap();

  const getUserIdentity = (userType: string, identity: any) => {
    let identityUserName = '-';
    let identityUserEmail = '-';
    if (userType === TLogUserTypes.Client) {
      identityUserName = identity?.resolverContext?.name as string;
      identityUserEmail = identity?.resolverContext?.email as string;
    }
    if (userType === TLogUserTypes.Internal) {
      const userId = identity?.username;
      identityUserName = adminCognitoUsersMap[userId]?.name;
      identityUserEmail = adminCognitoUsersMap[userId]?.email;
    }
    return { identityUserName, identityUserEmail };
  };

  const fullLogs = useMemo(() => {
    return logs.map((e) => {
      const response = JSON.parse(e.response ?? '{}');
      const identity = JSON.parse(e.identity ?? '{}');
      const variables = JSON.parse(e.variables ?? '{}');
      const [action, entity] = splitLogFieldName(e?.fieldName as string);
      const getCompany = () => {
        if (!e.companyId && entity == 'Company') return response;
        if (e.companyId) return companiesMap[e.companyId];
        return undefined;
      };
      const getEmployee = () => {
        if (response?.event && response?.event?.employeeId) return employeesMap[response?.event?.employeeId];
        if (response?.gift && response?.gift?.employeeId) return employeesMap[response?.gift?.employeeId];
        if (!response?.employeeId && entity == 'Employee') return response;
        if (response?.employeeId) return employeesMap[response?.employeeId];
        if (entity.includes('Shipment') && response?.addressId.includes(process.env.REACT_APP_EMPLOYEES_TABLE_NAME))
          return employeesMap[response?.addressId.split('|')[1]];
        return undefined;
      };
      return {
        ...e,
        company: getCompany(),
        employee: getEmployee(),
        identity,
        action,
        entity,
        ...getUserIdentity(e?.userType as string, identity),
        response,
        variables,
      };
    });
  }, [companiesMap, employeesMap, JSON.stringify(logs)]); // eslint-disable-line

  return {
    data: fullLogs,
    error: companiesError || employeesError || adminCognitoUsersError,
    loading: companiesLoading || employeesLoading || adminCognitoUsersLoading,
  };
};

export type TLogType<T extends TBasicLogFieldsFragment = TBasicLogFieldsFragment> = ReturnType<
  typeof useFullLogs<T>
>['data'][number];

export type TLogFullType = TLogType & {
  identityUserName?: string;
  identityUserEmail?: string;
  action?: string;
  entity?: string;
};

export const useQueryAllLogs = () => {
  const {
    data: clientLogs,
    error: clientLogsError,
    loading: clientLogsLoading,
  } = useListAllLogsQuery({
    variables: {
      userType: TLogUserTypes.Client,
    },
  });

  const {
    data: internalLogs,
    error: internalLogsError,
    loading: interLogsLoading,
  } = useListAllLogsQuery({
    variables: {
      userType: TLogUserTypes.Internal,
    },
  });

  const {
    data: fullLogs,
    loading: fullLogsLoading,
    error: fullLogsError,
  } = useFullLogs(
    _.compact(
      _.orderBy(_.concat(clientLogs?.listAllLogs?.items, internalLogs?.listAllLogs?.items), ['occurredAt'], ['desc']),
    ),
  );

  return {
    data: fullLogs,
    error: clientLogsError || internalLogsError || fullLogsError,
    loading: clientLogsLoading || interLogsLoading || fullLogsLoading,
  };
};
