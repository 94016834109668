import { addMinutes } from 'date-fns';
import _ from 'lodash';
import { quickScore } from 'quick-score';
import { useCallback, useMemo, useState } from 'react';
import { TEventWithSubRows } from '../../entities/events/queries';

const generateMonthFilter = (d: Date) => {
  return `${d.getFullYear()}-${d.getMonth() + 1}`;
};

type TUseEventsFiltersProps = {
  events: TEventWithSubRows[];
};

export const useEventsFilters = (props: TUseEventsFiltersProps) => {
  const { events } = props;

  const [monthFilter, setMonthFilter] = useState<string>();
  const [companyFilter, setCompanyFilter] = useState<string>();
  const [recipientNameFilter, setRecipientNameFilter] = useState<string>();
  const [orderByFilter, setOrderByFilter] = useState<string>();

  const companies = useMemo(() => {
    return _.sortBy(_.uniqBy(_.compact(events.map((e) => e.company)), 'id'), 'name');
  }, [events]);

  const filterByCompany = useCallback(
    (e: TEventWithSubRows) => {
      if (companyFilter && e.companyId !== companyFilter) return false;
      return true;
    },
    [companyFilter],
  );

  const months = useMemo(() => {
    return _.uniq(
      _.compact(
        _.sortBy(events, 'eventDate').map((e) => {
          let d = new Date(e.eventDate);
          d = addMinutes(d, d.getTimezoneOffset());

          return generateMonthFilter(d);
        }),
      ),
    );
  }, [events]);

  const filterByMonth = useCallback(
    (e: TEventWithSubRows) => {
      let d = new Date(e.eventDate);
      d = addMinutes(d, d.getTimezoneOffset());
      if (monthFilter && generateMonthFilter(d) !== monthFilter) return false;
      return true;
    },
    [monthFilter],
  );

  const companyEventsFilteredByDate = useMemo(() => {
    return events.reduce<Record<string, number>>((acc, event) => {
      if (!filterByMonth(event))
        return {
          ...acc,
          [event.companyId]: acc[event.companyId] ?? 0,
        };
      if (event.companyId in acc)
        return {
          ...acc,
          [event.companyId]: acc[event.companyId] + 1,
        };
      return { ...acc, [event.companyId]: 1 };
    }, {});
  }, [events, filterByMonth]);

  const filteredCompanies = useMemo(() => {
    return companies.filter((c) => companyEventsFilteredByDate[c.id]);
  }, [companies, companyEventsFilteredByDate]);

  const filterOrderBy = useCallback(
    (e: TEventWithSubRows[]) => {
      switch (orderByFilter) {
        case 'date_asc':
          return _.orderBy(e, ['eventDate'], ['asc']);
        case 'date_desc':
          return _.orderBy(e, ['eventDate'], ['desc']);
        case undefined:
        default:
          return e;
      }
    },
    [orderByFilter],
  );

  const filterByRecipientName = useCallback(
    (e: TEventWithSubRows) => {
      if (
        recipientNameFilter != null &&
        recipientNameFilter !== '' &&
        (e.recipient?.fullName == null || quickScore(e.recipient.fullName, recipientNameFilter) < 0.7)
      ) {
        return false;
      }

      return true;
    },
    [recipientNameFilter],
  );

  return {
    companyEventsFilteredByDate,
    filteredCompanies,
    companies,
    months,
    filterByMonth,
    setMonthFilter,
    monthFilter,
    filterByCompany,
    setCompanyFilter,
    companyFilter,
    filterByRecipientName,
    setRecipientNameFilter,
    recipientNameFilter,
    setOrderByFilter,
    orderByFilter,
    filterOrderBy,
  };
};
