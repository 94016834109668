// SUBSCRIBE

import { subscribe, TConnectionItem } from '@chocolate-soup-inc/cs-api-consumer-utils';
import _ from 'lodash';
import { useEffect, useMemo } from 'react';
import { serializeError } from 'serialize-error';
import { client } from '../../config/apollo/api';
import { onSubscriptionData } from '../../config/apollo/cache';
import {
  GetGiftDocument,
  GiftFieldsFragmentDoc,
  OnAnyEventGiftCreatedDocument,
  OnAnyGiftChangedDocument,
  OnAnyGiftRemovedFromGroupDocument,
  OnAnyShipmentRecreatedDocument,
  TAddressFieldsFragment,
  TBasicGiftFieldsFragment,
  TGetGiftQuery,
  TGetGiftQueryVariables,
  TGiftFieldsFragment,
  TGiftTypes,
  TListEmployeeAndDependatsGiftsQuery,
  TListEmployeeAndDependatsGiftsQueryVariables,
  TListNotDoneGiftsQuery,
  TListNotDoneGiftsQueryVariables,
  TListRecentlyDoneGiftsQuery,
  TListRecentlyDoneGiftsQueryVariables,
  TListShipmentGiftsQuery,
  TListShipmentGiftsQueryVariables,
  TOnAnyGiftChangedSubscription,
  TOnAnyGiftChangedSubscriptionVariables,
  useGetGiftLazyQuery,
  useListEmployeeAndDependatsGiftsLazyQuery,
  useListNotDoneGiftsLazyQuery,
  useListRecentlyDoneGiftsLazyQuery,
  useListShipmentGiftsLazyQuery,
} from '../../generated/graphql';
import { useAllAddressesMap } from '../addresses/queries';
import { useAllCompaniesMap } from '../companies/queries';
import { useAllDependantsMap } from '../dependants/queries';
import { useAllEmployeesMap } from '../employees/queries';
import { useSubscribeToEventChanges } from '../events/queries';
import { calculatedShippingDate } from '../shared/shippingDate';
import { useFragmentOrFetch } from '../shared/useFragmentOrFetch';
import { useQueryAll } from '../shared/useQueryAll';

// SUBSCRIBE

export const onGiftSubscriptionData = (
  data?: TOnAnyGiftChangedSubscription,
  vars?: TOnAnyGiftChangedSubscriptionVariables,
) => {
  const { companyId, id, _deleted } = data?.onAnyGiftChanged || {};

  if (_deleted) {
    return onSubscriptionData(data as Record<string, any>, vars);
  } else if (companyId && id) {
    client
      .query<TGetGiftQuery, TGetGiftQueryVariables>({
        query: GetGiftDocument,
        variables: {
          id,
          companyId,
        },
      })
      .then((response) => {
        const giftData = response?.data?.getGift;

        if (giftData) {
          const subscriptionData = {
            onAnyGiftChanged: giftData as TConnectionItem,
          };

          // CHANGED DATA FOR QUERIES THAT DEPEND ON NO VARIABLE (SINCE VARS SHOULD BE AN EMPTY OBJECT IN THIS SITUATION)
          onSubscriptionData(subscriptionData, vars);

          // CHANGED DATA FOR QUERIES THAT DEPEND ON SHIPMENT ID AND COMPANY ID
          if (giftData.shipmentId && giftData.companyId) {
            onSubscriptionData(subscriptionData, {
              shipmentId: giftData.shipmentId,
              companyId: giftData.companyId,
            });
          }
        }
      })
      .catch((error) => {
        console.error('Error', serializeError(error));
      });
  }
};

let giftChangedSubscribed = false;
let eventGiftCreatedSubscribed = false;
let giftRemovedFromGroupSubscribed = false;
let recreateShipmentSubscribed = false;

export const useSubscribeToGiftChanged = () => {
  useSubscribeToEventChanges();

  useEffect(() => {
    if (!giftChangedSubscribed) {
      subscribe({
        client,
        query: OnAnyGiftChangedDocument,
        onSubscriptionData: (data, vars) => {
          return onGiftSubscriptionData(
            data as TOnAnyGiftChangedSubscription,
            vars as TOnAnyGiftChangedSubscriptionVariables,
          );
        },
        variables: {},
      });

      giftChangedSubscribed = true;
    }
  }, []);

  useEffect(() => {
    if (!eventGiftCreatedSubscribed) {
      subscribe({
        client,
        query: OnAnyEventGiftCreatedDocument,
        onSubscriptionData: (data, vars) => {
          const { gift } = data?.onAnyEventGiftCreated || {};
          onGiftSubscriptionData(
            {
              onAnyGiftChanged: gift,
            },
            vars as TOnAnyGiftChangedSubscriptionVariables,
          );
        },
        variables: {},
      });

      eventGiftCreatedSubscribed = true;
    }
  });

  useEffect(() => {
    if (!giftRemovedFromGroupSubscribed) {
      subscribe({
        client,
        query: OnAnyGiftRemovedFromGroupDocument,
        onSubscriptionData: (data, vars) => {
          const { items } = data?.onAnyGiftRemovedFromGroup || {};

          if (items && Array.isArray(items) && items.length > 0) {
            items.forEach((item) => {
              onGiftSubscriptionData(
                {
                  onAnyGiftChanged: item,
                },
                vars as TOnAnyGiftChangedSubscriptionVariables,
              );
            });
          }
        },
        variables: {},
      });

      giftRemovedFromGroupSubscribed = true;
    }
  }, []);

  useEffect(() => {
    if (!recreateShipmentSubscribed) {
      subscribe({
        client,
        query: OnAnyShipmentRecreatedDocument,
        onSubscriptionData: (data, vars) => {
          const { items } = data?.onAnyShipmentRecreated || {};

          if (items && Array.isArray(items) && items.length > 0) {
            items.forEach((item) => {
              onGiftSubscriptionData(
                {
                  onAnyGiftChanged: item,
                },
                vars as TOnAnyGiftChangedSubscriptionVariables,
              );
            });
          }
        },
        variables: {},
      });

      recreateShipmentSubscribed = true;
    }
  }, []);
};

// LIST

export const getShippingDate = (
  gift: TGiftFieldsFragment & { address?: TAddressFieldsFragment },
  referenceEventDate: string | Date,
) => {
  return calculatedShippingDate(referenceEventDate, gift.address);
};

export const useFullGifts = <T extends TBasicGiftFieldsFragment>(gifts: T[]) => {
  const { data: companiesMap, error: companiesError, loading: companiesLoading } = useAllCompaniesMap();

  const { data: employeesMap, error: employeesError, loading: employeesLoading } = useAllEmployeesMap();

  const { data: dependantsMap, error: dependantsError, loading: dependantsLoading } = useAllDependantsMap();

  const { data: addressesMap, error: addressesError, loading: addressesLoading } = useAllAddressesMap();

  const fullGifts = useMemo(() => {
    return gifts.map((g) => {
      let recipient: (typeof employeesMap)[string] | (typeof dependantsMap)[string] | undefined;

      if (g.recipientId != null) {
        const [tableName, recipientId] = g.recipientId.split('|');

        if (tableName === process.env.REACT_APP_DEPENDANTS_TABLE_NAME) {
          recipient = dependantsMap[recipientId];
        } else if (tableName === process.env.REACT_APP_EMPLOYEES_TABLE_NAME) {
          recipient = employeesMap[recipientId];
        }
      }

      const giftWithRelations = {
        ...g,
        company: g.companyId ? companiesMap[g.companyId] : undefined,
        employee: g.employeeId ? employeesMap[g.employeeId] : undefined,
        recipient: recipient,
        address: g.addressId ? addressesMap[g.addressId] : undefined,
      };

      return {
        ...giftWithRelations,
        shippingDate: getShippingDate(giftWithRelations, g.eventDate),
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressesMap, companiesMap, dependantsMap, employeesMap, JSON.stringify(gifts)]);

  return {
    data: fullGifts,
    error: companiesError || employeesError || dependantsError || addressesError,
    loading: companiesLoading || employeesLoading || dependantsLoading || addressesLoading,
  };
};

export type TGiftType<T extends TBasicGiftFieldsFragment = TBasicGiftFieldsFragment> = ReturnType<
  typeof useFullGifts<T>
>['data'][number];

export const useQueryAllAssemblingGifts = () => {
  useSubscribeToGiftChanged();

  const {
    data: notDoneGiftsData,
    error: notDoneError,
    loading: notDoneLoading,
  } = useQueryAll<TListNotDoneGiftsQuery, TListNotDoneGiftsQueryVariables>({
    useQuery: useListNotDoneGiftsLazyQuery,
    variables: {},
  });

  const {
    data: recentlyDoneGiftsData,
    error: recentlyDoneError,
    loading: recentlyDoneLoading,
  } = useQueryAll<TListRecentlyDoneGiftsQuery, TListRecentlyDoneGiftsQueryVariables>({
    useQuery: useListRecentlyDoneGiftsLazyQuery,
    variables: {},
  });

  const giftAssemblingBoardGifts = useMemo(() => {
    return _.uniqBy(
      _.compact(notDoneGiftsData?.listNotDoneGifts.items || [])
        .filter((g) => {
          if (g.isDone === 2) return true;
          else return g?.doneAt == null;
        })
        .concat(
          _.compact(recentlyDoneGiftsData?.listRecentlyDoneGifts.items || []).filter((g) => {
            if (g.isDone === 2) return true;
            if (g.doneAt == null) return false;
            if (g.shipmentId) return false;
            const d = new Date(g.doneAt);
            const now = new Date();
            return new Date(now.setDate(now.getDate() - 14)).getTime() <= d.getTime();
          }),
        ),
      'id',
    );
  }, [notDoneGiftsData?.listNotDoneGifts.items, recentlyDoneGiftsData?.listRecentlyDoneGifts.items]);

  const {
    data: fullGifts,
    error: fullError,
    loading: fullLoading,
  } = useFullGifts<TGiftFieldsFragment>(giftAssemblingBoardGifts);

  return {
    data: fullGifts,
    error: fullError || notDoneError || recentlyDoneError,
    loading: fullLoading || notDoneLoading || recentlyDoneLoading,
  };
};

export type TGroupedGiftType<T extends TBasicGiftFieldsFragment = TBasicGiftFieldsFragment> = TGiftType<T> & {
  groupedGifts?: TGiftType<T>[];
};

export const getGiftReferenceEventDate = <T extends TGroupedGiftType>(gift: T): string => {
  if (gift.address?.parentType != 'employee') {
    const d = new Date(new Date(gift.eventDate).setUTCDate(1)).setUTCHours(0, 0, 0, 0);
    return new Date(d).toISOString().split('T')[0];
  } else {
    if (gift.group == null) {
      return gift.eventDate;
    } else {
      return (gift.groupedGifts || [gift]).sort(
        (a, b) => new Date(a.eventDate).getTime() - new Date(b.eventDate).getTime(),
      )[0].eventDate;
    }
  }
};

export const useQueryAllGroupedAssemblingGifts = () => {
  const { data, error, loading } = useQueryAllAssemblingGifts();

  const groupedGifts = useMemo(() => {
    return data.map((g) => {
      if (g.group == null) return g;
      else {
        return {
          ...g,
          groupedGifts: data
            .filter((g1) => g1.group === g.group)
            .sort((a, b) => new Date(a.eventDate).getTime() - new Date(b.eventDate).getTime()),
        };
      }
    });
  }, [data]);

  const gifts = useMemo(() => {
    return groupedGifts
      .map((g) => {
        const referenceEventDate = getGiftReferenceEventDate(g);
        const calculatedShippingDate = getShippingDate(g, referenceEventDate);
        let shippingDate = calculatedShippingDate;

        if (calculatedShippingDate.getTime() < new Date().getTime()) {
          shippingDate = new Date();
        }

        return {
          ...g,
          referenceEventDate,
          shippingDate,
          calculatedShippingDate: calculatedShippingDate,
        };
      })
      .sort((a, b) => {
        const referenceEventDateDiff =
          new Date(a.referenceEventDate).getTime() - new Date(b.referenceEventDate).getTime();
        if (referenceEventDateDiff !== 0) return referenceEventDateDiff;

        const groupDiff = (a.group || '').localeCompare(b.group || '');
        if (groupDiff !== 0) return groupDiff;

        const eventDateDiff = new Date(a.eventDate).getTime() - new Date(b.eventDate).getTime();
        if (eventDateDiff !== 0) return eventDateDiff;

        return a.id.localeCompare(b.id);
      });
  }, [groupedGifts]);

  return {
    data: gifts,
    error,
    loading,
  };
};

export const useQueryAllSubscriptionAssemblingGifts = () => {
  const { data, error, loading } = useQueryAllGroupedAssemblingGifts();

  const gifts = useMemo(() => {
    const dataFilter = data.filter((g) => g.type === TGiftTypes.Birthday || g.type === TGiftTypes.WorkAnniversary);
    return _.orderBy(dataFilter, ['eventDate', 'recipient.fullName'], ['desc', 'asc']);
  }, [data]);

  return {
    data: gifts,
    error,
    loading,
  };
};

export const useQueryAllNewHireAssemblingGifts = () => {
  const { data, error, loading } = useQueryAllGroupedAssemblingGifts();

  const gifts = useMemo(() => {
    const dataFilter = data.filter((g) => g.type === TGiftTypes.NewHire);
    return _.orderBy(dataFilter, ['eventDate', 'recipient.fullName'], ['desc', 'asc']);
  }, [data]);

  return {
    data: gifts,
    error,
    loading,
  };
};

export const useQueryAllOneOffAssemblingGifts = () => {
  const { data, error, loading } = useQueryAllGroupedAssemblingGifts();

  const gifts = useMemo(() => {
    const dataFilter = data.filter(
      (g) => g.type !== TGiftTypes.NewHire && g.type !== TGiftTypes.WorkAnniversary && g.type !== TGiftTypes.Birthday,
    );
    return _.orderBy(dataFilter, ['eventDate', 'recipient.fullName'], ['desc', 'asc']);
  }, [data]);

  return {
    data: gifts,
    error,
    loading,
  };
};

export const useQueryAllShipmentGifts = (variables: TListShipmentGiftsQueryVariables) => {
  useSubscribeToGiftChanged();

  const { data, error, loading } = useQueryAll<TListShipmentGiftsQuery, TListShipmentGiftsQueryVariables>({
    useQuery: useListShipmentGiftsLazyQuery,
    variables,
    fetchPolicy: 'network-only',
  });

  const {
    data: gifts,
    error: fullError,
    loading: fullLoading,
  } = useFullGifts(_.compact(data?.listShipmentGifts.items));

  const sortedGifs = useMemo(() => {
    return gifts.sort((a, b) => {
      return new Date(a.eventDate).getTime() - new Date(b.eventDate).getTime();
    });
  }, [gifts]);

  return {
    data: sortedGifs,
    error: error || fullError,
    loading: loading || fullLoading,
  };
};

export const useQueryAllEmployeeAndDependantsGifts = (variables: TListEmployeeAndDependatsGiftsQueryVariables) => {
  useSubscribeToGiftChanged();

  const { data, error, loading } = useQueryAll<
    TListEmployeeAndDependatsGiftsQuery,
    TListEmployeeAndDependatsGiftsQueryVariables
  >({
    useQuery: useListEmployeeAndDependatsGiftsLazyQuery,
    variables,
  });

  const {
    data: gifts,
    error: fullError,
    loading: fullLoading,
  } = useFullGifts(_.compact(data?.listEmployeeAndDependatsGifts.items));

  const filteredGifts = useMemo(() => {
    return gifts.filter((g) => g.employeeId === variables.employeeId && g.companyId === variables.companyId);
  }, [gifts, variables.companyId, variables.employeeId]);

  return {
    data: filteredGifts,
    error: error || fullError,
    loading: loading || fullLoading,
  };
};

// ITEMS

export const useFragmentOrFetchGift = (variables: TGetGiftQueryVariables) => {
  useSubscribeToGiftChanged();

  const { data, error, loading } = useFragmentOrFetch<
    TGiftFieldsFragment,
    TGetGiftQuery,
    TGetGiftQueryVariables,
    TOnAnyGiftChangedSubscriptionVariables
  >({
    useLazyQuery: useGetGiftLazyQuery,
    variables,
    fragmentDoc: GiftFieldsFragmentDoc,
    __typename: 'Gift',
  });

  const {
    data: gifts,
    error: fullError,
    loading: fullLoading,
  } = useFullGifts(_.compact([data]) as TGiftFieldsFragment[]);

  return {
    data: gifts[0],
    error: error || fullError,
    loading: loading || fullLoading,
  };
};
