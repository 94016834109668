import {
  ModalForm,
  TControlledFormComponentProps,
  TControlledFormProps,
} from '@chocolate-soup-inc/cs-frontend-components';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import { serializeError } from 'serialize-error';
import { AWSDateSchema } from '../../../entities/shared/utils';
import { TPrintShipmentLabelMutationInput, TProduct, usePrintShipmentLabelMutation } from '../../../generated/graphql';

import { TCustomsMappingType } from '../../../entities/giftPackages/queries';
import { pickProductsMapDefaultValues } from '../../../entities/products/shared';
import { TShipmentType } from '../../../entities/shipments/queries';
import { useShipmentAddressInfo } from '../../../entities/shipments/shared';
import { getDefaultCustoms } from './customs';
import styles from './PrintLabelModal.module.scss';

export type TPrintLabelModalProps = {
  shipment: TShipmentType;
  onCancel: () => void;
  onSuccess: () => void;
  defaultPackages: TCustomsMappingType;
  fromMultiple?: boolean;
  setShipmentsToUpdate?: React.Dispatch<React.SetStateAction<TShipmentType[]>> | null;
};

type TTrailingTextProps = {
  text: string;
};

const TrailingText = (props: TTrailingTextProps) => {
  const { text } = props;
  return <span className={styles.trailingText}>{text}</span>;
};

export const PrintLabelModal = (props: TPrintLabelModalProps) => {
  const { shipment, onCancel, onSuccess, defaultPackages, fromMultiple, setShipmentsToUpdate } = props;
  const { recipientName } = useShipmentAddressInfo(shipment);

  const isInternational = useMemo(() => shipment.address?.country !== 'CAN', [shipment]);

  const defaultCustoms = useMemo(() => {
    if (!isInternational) return null;
    if (!shipment.customsData) return getDefaultCustoms(shipment, defaultPackages);
    return pickProductsMapDefaultValues(shipment.customsData as TProduct[]);
  }, [shipment, isInternational, defaultPackages]);

  const formFields: TControlledFormComponentProps[] = useMemo(() => {
    const fields: TControlledFormComponentProps[] = [];

    if (!fromMultiple) {
      fields.push({
        type: 'datePicker',
        label: 'Shipping Date',
        name: 'actualShippingDate',
        disabled: fromMultiple,
      });
    }

    if (isInternational) {
      fields.push({
        type: 'fieldArray',
        name: 'customsData',
        addLabel: 'Add Customs Item',
        fields: [
          {
            type: 'textField',
            label: 'Value',
            name: 'value',
            inputOptions: {
              autoComplete: 'off',
              className: styles.numberInput,
              multiline: false,
              trailingIcon: <TrailingText text='CAD' />,
              type: 'number',
            },
          },
          {
            type: 'textField',
            label: 'HS Code',
            name: 'hsCode',
            inputOptions: {
              autoComplete: 'off',
              mask: ['0000{.}00'],
              multiline: false,
            },
          },
          {
            type: 'textField',
            label: 'Quantity',
            name: 'quantity',
            inputOptions: {
              autoComplete: 'off',
              className: styles.numberInput,
              multiline: false,
              type: 'number',
            },
          },
          {
            type: 'textField',
            label: 'Description',
            name: 'description',
            inputOptions: {
              multiline: false,
              autoComplete: 'off',
            },
          },
          {
            type: 'countrySelect',
            label: 'Country',
            name: 'country',
            inputOptions: {
              autoComplete: 'off',
              variant: 'outlined',
            },
          },
        ],
        label: 'Customs Item',
        removeLabel: 'Remove Customs Item',
      });
    }

    return fields;
  }, [isInternational, fromMultiple]);

  const schema = useMemo(() => {
    return Joi.object()
      .unknown(false)
      .keys({
        actualShippingDate: !fromMultiple ? AWSDateSchema.required() : AWSDateSchema.optional(),
        customsData: isInternational
          ? Joi.array()
              .items(
                Joi.object()
                  .unknown(false)
                  .keys({
                    value: Joi.number().required(),
                    hsCode: Joi.string()
                      .custom((value, helpers) => {
                        const regex = /^\d{4}\.\d{2}$/;

                        if (!regex.test(value)) {
                          return helpers.message({ custom: 'HS Code must be 0000.00' });
                        }
                        return value;
                      })
                      .required(),
                    quantity: Joi.number().required(),
                    description: Joi.string().required(),
                    country: Joi.string().required(),
                  }),
              )
              .required()
              .min(1)
          : Joi.optional().allow(null),
      });
  }, [fromMultiple, isInternational]);

  const headline = useMemo(() => {
    return `Print Label for ${recipientName} at ${shipment.company?.name ?? '-'}`;
  }, [recipientName, shipment]);

  const supportingText = useMemo(() => {
    return `Set the actual shipping date ${isInternational ? 'and customs data' : ''} for this shipment.`;
  }, [isInternational]);

  const [printLabel] = usePrintShipmentLabelMutation();

  const onSubmit = useCallback<TControlledFormProps<TPrintShipmentLabelMutationInput>['onValidSubmit']>(
    (data) => {
      if (fromMultiple) {
        try {
          if (setShipmentsToUpdate) {
            setShipmentsToUpdate((prev) => {
              const index = prev.findIndex((s) => s.id === shipment.id);
              if (index === -1) return prev;
              prev[index].customsData = data.customsData;
              return prev;
            });
          }
          onSuccess();
        } catch (error) {
          toast.error('There was an error updating the shipment.');
        }
        return;
      }

      return printLabel({
        variables: {
          id: shipment.id,
          companyId: shipment.companyId,
          version: shipment._version,
          input: data,
        },
      })
        .then(() => {
          if (onSuccess) onSuccess();
        })
        .catch((error) => {
          console.error(serializeError(error));
          toast.error('There was an error printing the shipment labels.');
        });
    },
    [onSuccess, printLabel, shipment, fromMultiple, setShipmentsToUpdate],
  );

  const defaultValues = useMemo(() => {
    return {
      customsData: defaultCustoms,
    };
  }, [defaultCustoms]);

  return (
    <ModalForm
      closeModal={onCancel}
      confirmLabel={!fromMultiple ? 'Print Label' : 'Update Customs'}
      contentClassName={styles.setAsReadyToShipModal}
      controlledFormProps={{
        className: styles.form,
        fields: formFields as TControlledFormComponentProps<TPrintShipmentLabelMutationInput>[],
        formProps: {
          defaultValues,
          resolver: joiResolver(schema, {
            convert: true,
            abortEarly: false,
            stripUnknown: false,
          }),
        },
        onValidSubmit: onSubmit,
        onInvalidSubmit: (errors) => {
          console.error(errors);
          for (const { message } of Object.values(errors)) {
            if (message) {
              toast.error(message);
            }
          }
        },
      }}
      headline={headline}
      onCancelClick={onCancel}
      size='large'
      supportingText={supportingText}
    />
  );
};
