import { ExtendedFAB, Icon, readableDate, TableInner, Tooltip } from '@chocolate-soup-inc/cs-frontend-components';
import { RowSelectionState } from '@tanstack/react-table';
import { createRef, useCallback, useEffect, useMemo, useState } from 'react';
import { CountryName } from '../../../components/coutryName/CountryName';
import { Filters, TFiltersProps } from '../../../components/filters/Filters';
import { FloatingActions } from '../../../components/floatingActions/FloatingActions';
import { TablePage, TTablePageRef } from '../../../components/tablePage/TablePage';
import {
  useCreateEventGiftMutation,
  useNotifyNewHiresMutation,
  useSkipEventMutation,
} from '../../../generated/graphql';

import clsx from 'clsx';
import tableStyles from '../../../components/tablePage/TablePage.module.scss';
import { useFilter } from '../../../contexts/filters';
import { useNewHiresUpcomingEvents } from '../../../entities/events/queries';
import { useEventsFilters } from '../shared';
import styles from './UpcomingNewHireEvents.module.scss';

export const UpcomingNewHireEvents = () => {
  const { filtersPageMode } = useFilter();
  useEffect(() => {
    filtersPageMode();
  }, []); //eslint-disable-line

  const [selectedRows, setSelectedRows] = useState<string[]>();
  const [skipLoading, setSkipLoading] = useState<boolean>(false);
  const [createGiftsLoading, setCreateGiftsLoading] = useState<boolean>(false);

  const tableRef = createRef<TTablePageRef>();

  const { data: newHireEvents, error, loading } = useNewHiresUpcomingEvents();
  const [skipEvent] = useSkipEventMutation();
  const [createEventGift] = useCreateEventGiftMutation();
  const [notifyNewHires] = useNotifyNewHiresMutation();

  const onRowSelectionChange = useCallback((rowSelection: RowSelectionState) => {
    console.log('selectedRows?:', rowSelection);
    setSelectedRows(Object.keys(rowSelection));
  }, []);

  const getRowId = useCallback((event: (typeof newHireEvents)[number]) => {
    return event.id;
  }, []);

  const skipEvents = useCallback(() => {
    setSkipLoading(true);
    return Promise.all(
      (selectedRows || []).map((id) => {
        return new Promise((resolve, reject) => {
          const event = newHireEvents.find((e) => e.id === id);

          if (event == null) return resolve(undefined);

          skipEvent({
            variables: {
              id: event.id,
              companyId: event.companyId,
              version: event._version,
            },
          })
            .then(resolve)
            .catch(reject);
        });
      }),
    )
      .then()
      .then(() => {
        tableRef.current?.resetConfirmationModal();
        setSkipLoading(false);
        setSelectedRows(undefined);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newHireEvents, selectedRows, skipEvent, tableRef, tableRef.current]);

  const createGifts = useCallback(() => {
    const newHiresNotified: Record<string, string>[] = newHireEvents
      .filter((event) => selectedRows?.includes(event.id))
      .map((event) => ({
        id: event.id as string,
        companyId: event.company?.id as string,
        companyName: event.company?.name as string,
        employeeName: event.employee?.fullName as string,
      }));

    setCreateGiftsLoading(true);
    return Promise.all(
      (selectedRows || []).map((id) => {
        return new Promise((resolve, reject) => {
          const event = newHireEvents.find((e) => e.id === id);

          if (event == null) return resolve(undefined);

          return createEventGift({
            variables: {
              eventId: event.id,
              companyId: event.companyId,
              eventVersion: event._version,
            },
          })
            .then(resolve)
            .catch(reject);
        });
      }),
    )
      .then()
      .then(() => {
        notifyNewHires({ variables: { events: newHiresNotified } });
        tableRef.current?.resetConfirmationModal();
        setCreateGiftsLoading(false);
        setSelectedRows(undefined);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createEventGift, newHireEvents, selectedRows, tableRef, tableRef.current]);

  const onSkipEventsClick = useCallback(() => {
    tableRef.current?.setConfirmationProps({
      headline: `Skip ${selectedRows?.length} Events?`,
      supportingText: `Are you sure you want to skip these ${selectedRows?.length} events?`,
      confirmLabel: 'Skip',
      onConfirmClick: skipEvents,
      open: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows?.length, skipEvents, tableRef, tableRef.current]);

  const onCreateGiftsClick = useCallback(() => {
    tableRef.current?.setConfirmationProps({
      headline: `Create ${selectedRows?.length} Gifts?`,
      supportingText: `Are you sure you want to create these ${selectedRows?.length} gifts?`,
      confirmLabel: 'Create',
      onConfirmClick: createGifts,
      open: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createGifts, selectedRows?.length, tableRef, tableRef.current]);

  const {
    companies,
    filterByCompany,
    setCompanyFilter,
    companyFilter,
    months,
    filterByMonth,
    setMonthFilter,
    monthFilter,
    filterByRecipientName,
    setRecipientNameFilter,
    recipientNameFilter,
    setOrderByFilter,
    orderByFilter,
    filterOrderBy,
  } = useEventsFilters({ events: newHireEvents });

  const filters = useMemo(() => {
    const filtersList: TFiltersProps['filters'] = [
      {
        includeEmptyOption: true,
        type: 'singleSelect',
        label: 'Date',
        name: 'date',
        onChange: (v) => {
          if (v == null) setMonthFilter(undefined);
          else setMonthFilter(v as string);
        },
        options: months.map((m) => ({ label: readableDate(m, 'MMM, yyyy') as string, value: m })),
        value: monthFilter,
      },
    ];

    filtersList.push({
      includeEmptyOption: true,
      type: 'singleSelect',
      label: 'Company',
      name: 'companyId',
      onChange: (v) => {
        if (v == null) setCompanyFilter(undefined);
        else setCompanyFilter(v as string);
      },
      options: companies.map((c) => ({ label: c.name, value: c.id })),
      value: companyFilter,
    });

    filtersList.push({
      type: 'textInput',
      label: 'Recipient Name',
      name: 'recipientName',
      onChange: (v) => {
        if (v == null) setRecipientNameFilter(undefined);
        else setRecipientNameFilter(v as string);
      },
      value: recipientNameFilter,
    });

    filtersList.push({
      includeEmptyOption: true,
      type: 'singleSelect',
      label: 'Event Date Order',
      name: 'order',
      onChange: (v) => {
        if (v == null) setOrderByFilter(undefined);
        else setOrderByFilter(v as string);
      },
      options: [
        {
          label: 'Oldest to Newest',
          value: 'date_asc',
        },
        {
          label: 'Newest to Oldest',
          value: 'date_desc',
        },
      ],
      value: orderByFilter,
    });

    return filtersList;
  }, [
    companies,
    companyFilter,
    monthFilter,
    months,
    recipientNameFilter,
    orderByFilter,
    setCompanyFilter,
    setMonthFilter,
    setRecipientNameFilter,
    setOrderByFilter,
  ]);

  const filteredNewHireEvents = useMemo(() => {
    return filterOrderBy(
      newHireEvents.filter((e) => {
        return filterByCompany(e) && filterByMonth(e) && filterByRecipientName(e);
      }),
    );
  }, [filterOrderBy, filterByCompany, filterByMonth, filterByRecipientName, newHireEvents]);

  const getRowClassName = useCallback(
    (index: number) => {
      const event = filteredNewHireEvents[index];
      if (event.address == null) return styles.noAddressRow;
    },
    [filteredNewHireEvents],
  );

  return (
    <TablePage
      actionsLoading={createGiftsLoading || skipLoading}
      dataLoading={loading}
      error={error}
      ref={tableRef}
      title='Upcoming New Hire Events'
    >
      <Filters filters={filters} />
      <TableInner<(typeof newHireEvents)[number]>
        enableSorting={true}
        data={filteredNewHireEvents}
        emptyText={
          newHireEvents.length === 0
            ? 'No upcoming new hire events.'
            : 'No upcoming new hire events for the current filters.'
        }
        expandable={true}
        fillSelectedRows={true}
        fixedHeader={true}
        forceExpanded={true}
        getRowId={getRowId}
        getRowClassName={getRowClassName}
        hideSubRowsSelect={true}
        hoverableRows={!skipLoading && !createGiftsLoading}
        onRowSelectionChange={onRowSelectionChange}
        selectableRows={true}
        selectingChildSelectsFather={true}
        virtual={true}
        columns={[
          {
            header: 'Company Name',
            accessorKey: 'company.name',
            cell: ({ cell }) => {
              return (
                <div className={clsx(styles.companyName, tableStyles.tableSpacePrimary)}>
                  {cell.row.original.wasPaused && (
                    <Tooltip message='This event was recently unpaused.'>
                      <Icon icon='play_circle' />
                    </Tooltip>
                  )}
                  <span>{cell.row.original.company?.name}</span>
                </div>
              );
            },
          },
          {
            header: 'Recipient Name',
            accessorKey: 'recipient.fullName',
            cell: ({ cell }) => {
              let text = '';
              if (cell.row.original.employee?.pausePeriod) {
                const { fromDate, toDate } = cell.row.original.employee.pausePeriod;
                if (fromDate) text += ` since ${readableDate(fromDate)}`;
                if (toDate) text += ` to ${readableDate(toDate)}`;
              }

              return (
                <div className={clsx(tableStyles.tableSpacePrimary)}>
                  {cell.row.original.employee?.isPauseEnabled && (
                    <Tooltip message={`Employee paused${text}.`}>
                      <Icon icon='pause_circle' />
                    </Tooltip>
                  )}
                  <span>{cell.row.original.recipient?.fullName}</span>
                </div>
              );
            },
          },
          {
            header: 'Event Date',
            accessorKey: 'eventDate',
            cell: ({ cell }) => {
              return (
                <div className={clsx(tableStyles.tableSpaceTertiary)}>
                  <span>{readableDate(cell.row.original.eventDate)}</span>
                </div>
              );
            },
          },
          {
            header: 'Country',
            accessorKey: 'address.country',
            cell: ({ cell }) => {
              if (cell.row.original.address != null) {
                return (
                  <div className={clsx(tableStyles.tableSpacePrimary)}>
                    <CountryName name={cell.row.original.address.country || undefined} />
                  </div>
                );
              } else {
                return (
                  <div className={clsx(styles.missingAddress, tableStyles.tableSpacePrimary)}>
                    <Icon icon='warning' />
                    <span>Missing address</span>
                  </div>
                );
              }
            },
          },
        ]}
      />
      {selectedRows && selectedRows.length > 0 && (
        <FloatingActions>
          <ExtendedFAB
            disabled={createGiftsLoading}
            label={`Skip ${selectedRows.length} Events`}
            leadingIcon='exit_to_app'
            loading={skipLoading}
            onClick={onSkipEventsClick}
            variant='tertiary'
          />
          <ExtendedFAB
            disabled={skipLoading}
            label={`Create ${selectedRows.length} Gifts`}
            leadingIcon='add'
            loading={createGiftsLoading}
            onClick={onCreateGiftsClick}
            variant='surface'
          />
        </FloatingActions>
      )}
    </TablePage>
  );
};
