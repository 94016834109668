import {
  ErrorPage,
  Expandable,
  Icon,
  LoadingPage,
  readableDate,
  TableInner,
  Tooltip,
  TTableInnerProps,
} from '@chocolate-soup-inc/cs-frontend-components';
import { getProperty, setProperty } from 'dot-prop';
import Joi from 'joi';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import * as XLSX from 'xlsx';
import { schema as baseDependantSchema } from '../../../../entities/dependants/schema';
import { schema as baseEmployeeSchema } from '../../../../entities/employees/schema';
import { TDependantType } from '../../../../generated/graphql';

import { useFragmentOrFetchCompany } from '../../../../entities/companies/queries';
import { useQueryAllCompanyEmployeesWithDependants } from '../../../../entities/employees/queries';
import { useQueryAllCompanyOffices } from '../../../../entities/office/queries';
import { normalizeString } from '../../../../entities/shared/utils';
import styles from '../EmployeesImport.module.scss';
import clsx from 'clsx';

type TValidatedRowData = {
  value?: Record<string, any>;
  error?: Joi.ValidationError;
};

type TValidatedRowDataWithEmployee = TValidatedRowData & {
  employee?: Record<string, any>;
};

const generateFullName = (row: Record<string, any>) => {
  const { firstName, lastName, preferredFirstName, fullName } = row;

  if (fullName && !firstName && !lastName && !preferredFirstName) return fullName?.trim();

  let name: string | undefined;

  if (firstName != null && typeof firstName === 'string' && firstName !== '') {
    name = firstName.trim();
  }

  if (preferredFirstName != null && typeof preferredFirstName === 'string' && preferredFirstName !== '') {
    if (name == null || name === '') {
      name = preferredFirstName.trim();
    } else {
      name = `${name} (${preferredFirstName.trim()})`;
    }
  }

  if (lastName != null && typeof lastName === 'string' && lastName !== '') {
    if (name == null || name === '') {
      name = lastName.trim();
    } else {
      name = `${name} ${lastName.trim()}`;
    }
  }

  return name;
};

type TEmployeeListProps = {
  includeErrorColumn?: boolean;
  rows: TValidatedRowData[];
};

const EmployeeList = (props: TEmployeeListProps) => {
  const { includeErrorColumn = false, rows } = props;

  const columns: TTableInnerProps<TValidatedRowData>['columns'] = [
    {
      header: 'External ID',
      accessorKey: 'value.externalId',
    },
    {
      header: 'Name',
      cell: ({ cell }) => {
        const { paused } = cell.row.original.value || {};
        const isPausedTrue = ['yes', '1', 'true'].includes(normalizeString(String(paused)));
        return (
          <div className={clsx(styles.reviewStepName)}>
            <div className={clsx(styles.tooltipCenter)}>
              {isPausedTrue && (
                <Tooltip message={`Pause enabled for this employee.`}>
                  <Icon icon='pause_circle' />
                </Tooltip>
              )}
            </div>
            <span>{generateFullName(cell.row.original.value || {})}</span>
          </div>
        );
      },
    },
    {
      header: 'Hire Date',
      cell: ({ cell }) => {
        const { hireDate } = cell.row.original.value || {};

        if (_.isEmpty(hireDate) && !(hireDate instanceof Date)) {
          return '-';
        } else {
          try {
            return readableDate(hireDate);
          } catch (error) {
            return hireDate;
          }
        }
      },
    },
  ];

  if (includeErrorColumn) {
    columns.push({
      header: 'Error',
      cell: ({ cell }) => {
        const { message } = cell.row.original.error || {};

        if (message) {
          return (
            <Tooltip message={message}>
              <span className={styles.errorMessage}>{message}</span>
            </Tooltip>
          );
        }
      },
    });
  }

  return <TableInner<TValidatedRowData> className={styles.table} data={rows} columns={columns} />;
};

type TDependantListProps = {
  includeErrorColumn?: boolean;
  rows: TValidatedRowDataWithEmployee[];
};

const DependantList = (props: TDependantListProps) => {
  const { includeErrorColumn = false, rows } = props;

  const columns: TTableInnerProps<TValidatedRowDataWithEmployee>['columns'] = [
    // {
    //   header: 'Employee External ID',
    //   accessorKey: 'employeeRow.externalId',
    // },
    {
      header: 'Dependant Name',
      cell: ({ cell }) => {
        const fullName = generateFullName(cell.row.original.value || {});
        return fullName;
      },
    },
    {
      header: 'Relation',
      cell: ({ cell }) => {
        const employeeFullName = generateFullName(cell.row.original.employee?.value || {});
        const relation = cell.row.original.value?.type;
        let readableRelation: string;

        switch (relation) {
          case TDependantType.Child:
            readableRelation = 'Child';
            break;
          case TDependantType.Pet:
            readableRelation = 'Pet';
            break;
          case TDependantType.SignificantOther:
            readableRelation = 'Partner';
            break;
          default:
            readableRelation = 'Unknown';
        }

        return `${readableRelation} of ${employeeFullName}`;
      },
    },
  ];

  if (includeErrorColumn) {
    columns.push({
      header: 'Error',
      cell: ({ cell }) => {
        const { message } = cell.row.original.error || {};

        if (message) {
          return (
            <Tooltip message={message}>
              <span className={styles.errorMessage}>{message}</span>
            </Tooltip>
          );
        }
      },
    });
  }

  return <TableInner<TValidatedRowDataWithEmployee> className={styles.table} data={rows} columns={columns} />;
};

type TReviewStepProps = {
  companyId: string;
  file?: File;
  mapping: Record<number, string | undefined>;
  officeMapping?: Record<any, string>;
  setEmptyImport?: React.Dispatch<React.SetStateAction<boolean>>;
  changelog?: Record<string, TValidatedRowData[]>;
  setChangelog?: React.Dispatch<React.SetStateAction<Record<string, any>>>;
};

export const ReviewStep = (props: TReviewStepProps) => {
  const { companyId, file, mapping, officeMapping, setEmptyImport, setChangelog, changelog } = props;

  const [rows, setRows] = useState<any[]>([]);

  const [employeesBeingCreated, setEmployeesBeingCreated] = useState<TValidatedRowData[]>(
    changelog?.employeesBeingCreated ?? [],
  );
  const [employeesBeingUpdated, setEmployeesBeingUpdated] = useState<TValidatedRowData[]>(
    changelog?.employeesBeingUpdated ?? [],
  );
  const [employeesActuallyBeingUpdated, setEmployeesActuallyBeingUpdated] = useState<TValidatedRowData[]>(
    changelog?.employeesActuallyBeingUpdated ?? [],
  );
  const [employeesBeingDeleted, setEmployeesBeingDeleted] = useState<TValidatedRowData[]>(
    changelog?.employeesBeingDeleted ?? [],
  );
  const [dependantsBeingCreated, setDependantsBeingCreated] = useState<TValidatedRowDataWithEmployee[]>(
    changelog?.dependantsBeingCreated ?? [],
  );
  const [dependantsBeingUpdated, setDependantsBeingUpdated] = useState<TValidatedRowDataWithEmployee[]>(
    changelog?.dependantsBeingUpdated ?? [],
  );
  const [dependantsActuallyBeingUpdated, setDependantsActuallyBeingUpdated] = useState<TValidatedRowDataWithEmployee[]>(
    changelog?.dependantsActuallyBeingUpdated ?? [],
  );
  const [dependantsBeingDeleted, setDependantsBeingDeleted] = useState<TValidatedRowDataWithEmployee[]>(
    changelog?.dependantsBeingDeleted ?? [],
  );

  const {
    data: company,
    error: companyError,
    loading: companyLoading,
  } = useFragmentOrFetchCompany({
    id: companyId,
  });

  const {
    data: offices,
    error: officesError,
    loading: officesLoading,
  } = useQueryAllCompanyOffices({
    companyId,
  });

  const {
    data: companyEmployeesAndDependants,
    error,
    loading,
  } = useQueryAllCompanyEmployeesWithDependants(
    {
      companyId,
    },
    'network-only',
  );

  const employeeSchema = useMemo(() => {
    if (company) {
      return baseEmployeeSchema({
        offices,
        companyEmployeesAndDependants,
      });
    }
  }, [company, companyEmployeesAndDependants, offices]);

  const dependantSchema = useMemo(() => {
    return baseDependantSchema();
  }, []);

  useEffect(() => {
    if (file) {
      file.arrayBuffer().then((data) => {
        const workbook = XLSX.read(data, { cellDates: true });
        const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
        const parsedData = XLSX.utils.sheet_to_json<any[]>(firstSheet, {
          header: 1,
          blankrows: false,
          skipHidden: true,
        });

        setRows(parsedData.slice(1));
      });
    }
  }, [file]);

  const mappedRows = useMemo(() => {
    return (rows || []).map((r) => {
      return Object.entries(mapping || []).reduce(
        (agg, [k, v]) => {
          if (v != null && k != null) {
            if (v === 'officeId') {
              if (officeMapping) {
                setProperty(agg, v, officeMapping[r[parseInt(k)]]);
              }
            } else {
              const value = r[parseInt(k)];
              if (v.indexOf('hireDate') > -1 || v.indexOf('birthDate') > -1) {
                if (value instanceof Date) {
                  setProperty(agg, v, value.toISOString().split('T')[0]);
                } else {
                  setProperty(agg, v, value || null);
                }
              } else {
                setProperty(agg, v, value || null);
              }
            }
          }
          return agg;
        },
        {} as Record<string, any>,
      );
    });
  }, [mapping, officeMapping, rows]);

  useEffect(() => {
    if (changelog) return;

    const employeesCreated: TValidatedRowData[] = [];
    const employeesUpdated: TValidatedRowData[] = [];
    const employeesActuallyUpdated: TValidatedRowData[] = [];
    const dependantsCreated: TValidatedRowDataWithEmployee[] = [];
    const dependantsUpdated: TValidatedRowDataWithEmployee[] = [];
    const dependantsActuallyUpdated: TValidatedRowDataWithEmployee[] = [];

    if (employeeSchema != null && dependantSchema != null && mappedRows != null) {
      mappedRows.map((r) => {
        const existingEmployee = companyEmployeesAndDependants.find(
          (e) => e.externalId?.toString() === r.externalId?.toString(),
        );

        const { partners, children, pets, ...row } = r;

        const employeeData = employeeSchema.validate(row, {
          abortEarly: false,
          allowUnknown: true,
          convert: true,
        });

        if (existingEmployee == null) {
          employeesCreated.push(employeeData);
        } else {
          employeesUpdated.push(employeeData);

          if (employeeData.error == null) {
            for (const mappedKey of Object.values(mapping || [])) {
              if (mappedKey == null) continue;

              let existingValue: any = getProperty(existingEmployee, mappedKey);
              let finalValue: any = getProperty(employeeData.value, mappedKey);

              if (mappedKey.indexOf('hireDate') > -1 || mappedKey.indexOf('birthDate') > -1) {
                if (existingValue instanceof Date) {
                  existingValue = existingValue.toISOString().split('T')[0];
                }
              } else if (mappedKey === 'externalId') {
                existingValue = existingValue?.toString();
                finalValue = finalValue?.toString();
              }

              if (existingValue !== finalValue) {
                employeesActuallyUpdated.push(employeeData);
                break;
              }
            }
          }
        }

        for (const { identifier, items, type } of [
          {
            identifier: 'partner',
            items: partners as Record<string, any>[] | undefined,
            type: TDependantType.SignificantOther,
          },
          {
            identifier: 'child',
            items: children as Record<string, any>[] | undefined,
            type: TDependantType.Child,
          },
          {
            identifier: 'pet',
            items: pets as Record<string, any>[] | undefined,
            type: TDependantType.Pet,
          },
        ]) {
          if (items) {
            const dependantsArray: Record<string, any>[] = Object.values(items).filter((i) => !_.every(i, _.isEmpty));

            if (Array.isArray(dependantsArray) && dependantsArray.length > 0) {
              for (const rowDependant of dependantsArray) {
                const existingDependant = _.compact(existingEmployee?.dependants?.items || []).find((d) => {
                  return (
                    d.type === type &&
                    generateFullName(d)?.toLowerCase() === generateFullName(rowDependant)?.toLowerCase()
                  );
                });

                rowDependant.type = type;
                rowDependant.companyId = existingEmployee?.companyId;
                rowDependant.employeeId = existingEmployee?.id;

                const dependantData = dependantSchema.validate(rowDependant, {
                  abortEarly: false,
                  allowUnknown: true,
                  convert: true,
                });

                if (existingDependant == null) {
                  dependantsCreated.push({
                    ...dependantData,
                    employee: employeeData,
                  });
                } else {
                  dependantsUpdated.push({
                    ...dependantData,
                    employee: employeeData,
                  });

                  if (dependantData.error == null) {
                    for (const mappedKey of Object.values(mapping || [])) {
                      if (mappedKey == null) continue;
                      if (!mappedKey.startsWith(identifier)) continue;

                      const propertyKey = mappedKey.split('.').pop();

                      if (propertyKey == null) continue;

                      let existingValue: any = getProperty(existingDependant, propertyKey);
                      let finalValue: any = getProperty(dependantData.value, propertyKey);

                      if (propertyKey.indexOf('hireDate') > -1 || propertyKey.indexOf('birthDate') > -1) {
                        if (existingValue instanceof Date) {
                          existingValue = existingValue.toISOString().split('T')[0];
                        }
                      } else if (propertyKey === 'externalId') {
                        existingValue = existingValue?.toString();
                        finalValue = finalValue?.toString();
                      }

                      if (existingValue !== finalValue) {
                        dependantsActuallyUpdated.push({
                          ...dependantData,
                          employee: {
                            value: existingEmployee,
                          },
                        });
                        break;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      });
    }

    setEmployeesBeingCreated(employeesCreated);
    setEmployeesBeingUpdated(employeesUpdated);
    setEmployeesActuallyBeingUpdated(employeesActuallyUpdated);
    setDependantsBeingCreated(dependantsCreated);
    setDependantsBeingUpdated(dependantsUpdated);
    setDependantsActuallyBeingUpdated(dependantsActuallyUpdated);
  }, [companyEmployeesAndDependants, dependantSchema, employeeSchema, mappedRows, mapping, changelog]);

  useEffect(() => {
    if (changelog) return;

    const employeesDeleted: TValidatedRowData[] = [];
    const dependantsDeleted: TValidatedRowDataWithEmployee[] = [];
    for (const e of _.compact(companyEmployeesAndDependants)) {
      for (const d of _.compact(e?.dependants?.items || [])) {
        const dependantBeingUpdated = dependantsBeingUpdated?.find((dBeingUpdated) => {
          return dBeingUpdated?.employee?.value?.externalId?.toString() === e.externalId?.toString();
        });
        if (dependantBeingUpdated == null) {
          dependantsDeleted.push({
            value: d,
            employee: {
              value: e,
            },
          });
        }
      }

      const employeeBeingUpdated = employeesBeingUpdated?.find((eBeingUpdated) => {
        return eBeingUpdated?.value?.externalId?.toString() === e.externalId?.toString();
      });

      if (employeeBeingUpdated == null) {
        employeesDeleted.push({
          value: e,
        });
      }
    }

    setEmployeesBeingDeleted(employeesDeleted);
    setDependantsBeingDeleted(dependantsDeleted);
  }, [companyEmployeesAndDependants, dependantsBeingUpdated, employeesBeingUpdated, changelog]);

  const validEmployeesBeingCreated = useMemo(() => {
    return (employeesBeingCreated || []).filter((e) => e.error == null);
  }, [employeesBeingCreated]);

  const invalidEmployeesBeingCreated = useMemo(() => {
    return (employeesBeingCreated || []).filter((e) => e.error != null);
  }, [employeesBeingCreated]);

  const validEmployeesBeingUpdated = useMemo(() => {
    return (employeesActuallyBeingUpdated || []).filter((e) => e.error == null);
  }, [employeesActuallyBeingUpdated]);

  const invalidEmployeesBeingUpdated = useMemo(() => {
    return (employeesBeingUpdated || []).filter((e) => e.error != null);
  }, [employeesBeingUpdated]);

  const validDependantsBeingCreated = useMemo(() => {
    return (dependantsBeingCreated || []).filter((e) => e.error == null);
  }, [dependantsBeingCreated]);

  const invalidDependantsBeingCreated = useMemo(() => {
    return (dependantsBeingCreated || []).filter((e) => e.error != null);
  }, [dependantsBeingCreated]);

  const validDependantsBeingUpdated = useMemo(() => {
    return (dependantsActuallyBeingUpdated || []).filter((e) => e.error == null);
  }, [dependantsActuallyBeingUpdated]);

  const invalidDependantsBeingUpdated = useMemo(() => {
    return (dependantsBeingUpdated || []).filter((e) => e.error != null);
  }, [dependantsBeingUpdated]);

  useEffect(() => {
    if (setChangelog)
      setChangelog({
        employeesBeingCreated,
        employeesBeingUpdated,
        employeesActuallyBeingUpdated,
        employeesBeingDeleted,
        dependantsBeingCreated,
        dependantsBeingUpdated,
        dependantsActuallyBeingUpdated,
        dependantsBeingDeleted,
      });
  }, [
    employeesBeingCreated,
    employeesBeingUpdated,
    employeesActuallyBeingUpdated,
    employeesBeingDeleted,
    dependantsBeingCreated,
    dependantsBeingUpdated,
    dependantsActuallyBeingUpdated,
    dependantsBeingDeleted,
    setChangelog,
  ]);

  useEffect(() => {
    if (setEmptyImport)
      setEmptyImport(
        employeesBeingCreated?.length === 0 &&
          employeesActuallyBeingUpdated?.length === 0 &&
          employeesBeingDeleted?.length === 0 &&
          dependantsBeingCreated?.length === 0 &&
          dependantsActuallyBeingUpdated?.length === 0 &&
          dependantsBeingDeleted?.length === 0,
      );
  }, [
    dependantsActuallyBeingUpdated?.length,
    dependantsBeingCreated?.length,
    dependantsBeingDeleted?.length,
    employeesActuallyBeingUpdated?.length,
    employeesBeingCreated?.length,
    employeesBeingDeleted?.length,
    setEmptyImport,
  ]);

  if (error || companyError || officesError) {
    return <ErrorPage error={error || companyError || officesError} />;
  }

  if (
    loading ||
    companyLoading ||
    officesLoading ||
    employeesBeingCreated == null ||
    employeesBeingUpdated == null ||
    employeesActuallyBeingUpdated == null ||
    employeesBeingDeleted == null ||
    dependantsBeingCreated == null ||
    dependantsBeingUpdated == null ||
    dependantsActuallyBeingUpdated == null ||
    dependantsBeingDeleted == null
  ) {
    return <LoadingPage />;
  }

  if (
    invalidEmployeesBeingCreated.length === 0 &&
    employeesBeingCreated?.length === 0 &&
    invalidEmployeesBeingUpdated.length === 0 &&
    employeesActuallyBeingUpdated?.length === 0 &&
    employeesBeingDeleted.length === 0 &&
    invalidDependantsBeingCreated.length === 0 &&
    dependantsBeingCreated?.length === 0 &&
    invalidDependantsBeingUpdated.length === 0 &&
    dependantsActuallyBeingUpdated?.length === 0 &&
    dependantsBeingDeleted.length === 0
  ) {
    return (
      <div className={styles.review}>
        <p className={styles.empty}>There are no changes to be made with this file.</p>
      </div>
    );
  }

  return (
    <div className={styles.review}>
      {((Array.isArray(employeesBeingCreated) && employeesBeingCreated.length > 0) ||
        (Array.isArray(invalidEmployeesBeingUpdated) && invalidEmployeesBeingUpdated.length > 0) ||
        (Array.isArray(employeesActuallyBeingUpdated) && employeesActuallyBeingUpdated.length > 0) ||
        (Array.isArray(employeesBeingDeleted) && employeesBeingDeleted.length > 0)) && (
        <h2 className={styles.reviewSubTitle}>Employees Changes</h2>
      )}
      {Array.isArray(validEmployeesBeingCreated) && validEmployeesBeingCreated.length > 0 && (
        <Expandable
          className={styles.reviewExpandable}
          leadingIcon='group_add'
          title={`${validEmployeesBeingCreated.length} employees being created.`}
        >
          <EmployeeList rows={validEmployeesBeingCreated} />
        </Expandable>
      )}
      {Array.isArray(invalidEmployeesBeingCreated) && invalidEmployeesBeingCreated.length > 0 && (
        <Expandable
          className={styles.reviewExpandable}
          leadingIcon='group_off'
          title={`${invalidEmployeesBeingCreated.length} employees to be created containing errors.`}
        >
          <EmployeeList includeErrorColumn={true} rows={invalidEmployeesBeingCreated} />
        </Expandable>
      )}
      {Array.isArray(validEmployeesBeingUpdated) && validEmployeesBeingUpdated.length > 0 && (
        <Expandable
          className={styles.reviewExpandable}
          leadingIcon='edit'
          title={`${validEmployeesBeingUpdated.length} employees being updated.`}
        >
          <EmployeeList rows={validEmployeesBeingUpdated} />
        </Expandable>
      )}
      {Array.isArray(invalidEmployeesBeingUpdated) && invalidEmployeesBeingUpdated.length > 0 && (
        <Expandable
          className={styles.reviewExpandable}
          leadingIcon='edit'
          title={`${invalidEmployeesBeingUpdated.length} employees to be updated containing errors.`}
        >
          <EmployeeList includeErrorColumn={true} rows={invalidEmployeesBeingUpdated} />
        </Expandable>
      )}
      {Array.isArray(employeesBeingDeleted) && employeesBeingDeleted.length > 0 && (
        <Expandable
          className={styles.reviewExpandable}
          leadingIcon='delete'
          title={`${employeesBeingDeleted.length} employees being deleted.`}
        >
          <EmployeeList rows={employeesBeingDeleted} />
        </Expandable>
      )}
      {((Array.isArray(dependantsBeingCreated) && dependantsBeingCreated.length > 0) ||
        (Array.isArray(invalidDependantsBeingUpdated) && invalidDependantsBeingUpdated.length > 0) ||
        (Array.isArray(dependantsActuallyBeingUpdated) && dependantsActuallyBeingUpdated.length > 0) ||
        (Array.isArray(dependantsBeingDeleted) && dependantsBeingDeleted.length > 0)) && (
        <h2 className={styles.reviewSubTitle}>Dependants Changes</h2>
      )}
      {Array.isArray(validDependantsBeingCreated) && validDependantsBeingCreated.length > 0 && (
        <Expandable
          className={styles.reviewExpandable}
          leadingIcon='group_add'
          title={`${validDependantsBeingCreated.length} dependants being created.`}
        >
          <DependantList rows={validDependantsBeingCreated} />
        </Expandable>
      )}
      {Array.isArray(invalidDependantsBeingCreated) && invalidDependantsBeingCreated.length > 0 && (
        <Expandable
          className={styles.reviewExpandable}
          leadingIcon='group_add'
          title={`${invalidDependantsBeingCreated.length} dependants to be  created containing errors.`}
        >
          <DependantList includeErrorColumn={true} rows={invalidDependantsBeingCreated} />
        </Expandable>
      )}
      {Array.isArray(validDependantsBeingUpdated) && validDependantsBeingUpdated.length > 0 && (
        <Expandable
          className={styles.reviewExpandable}
          leadingIcon='edit'
          title={`${validDependantsBeingUpdated.length} dependants being updated.`}
        >
          <DependantList rows={validDependantsBeingUpdated} />
        </Expandable>
      )}
      {Array.isArray(invalidDependantsBeingUpdated) && invalidDependantsBeingUpdated.length > 0 && (
        <Expandable
          className={styles.reviewExpandable}
          leadingIcon='edit'
          title={`${invalidDependantsBeingUpdated.length} dependants to be updated containing errors.`}
        >
          <DependantList includeErrorColumn={true} rows={invalidDependantsBeingUpdated} />
        </Expandable>
      )}
      {Array.isArray(dependantsBeingDeleted) && dependantsBeingDeleted.length > 0 && (
        <Expandable
          className={styles.reviewExpandable}
          leadingIcon='delete'
          title={`${dependantsBeingDeleted.length} dependants being deleted.`}
        >
          <DependantList rows={dependantsBeingDeleted} />
        </Expandable>
      )}
    </div>
  );
};
