import { FetchPolicy, useQuery } from '@apollo/client';
import { TConnectionItem, subscribe } from '@chocolate-soup-inc/cs-api-consumer-utils';
import _ from 'lodash';
import { useEffect, useMemo } from 'react';
import { serializeError } from 'serialize-error';
import { client } from '../../config/apollo/api';
import { onSubscriptionData } from '../../config/apollo/cache';
import {
  EmployeeWithAddressFieldsFragmentDoc,
  GetEmployeeDocument,
  ListCompanyEmployeesDocument,
  OnAnyEmployeeChangedDocument,
  TBasicEmployeeFieldsFragment,
  TEmployeeWithAddressFieldsFragment,
  TEmployeeWithDependantsFieldsFragment,
  TGetEmployeeQuery,
  TGetEmployeeQueryVariables,
  TListCompanyEmployeesWithDependantsQuery,
  TListCompanyEmployeesWithDependantsQueryVariables,
  TListEmployeesQuery,
  TListEmployeesQueryVariables,
  TOnAnyEmployeeChangedSubscription,
  TOnAnyEmployeeChangedSubscriptionVariables,
  useGetEmployeeLazyQuery,
  useListCompanyEmployeesWithDependantsLazyQuery,
  useListEmployeesLazyQuery,
} from '../../generated/graphql';
import { useAllAddressesMap } from '../addresses/queries';
import { useAllCompaniesMap } from '../companies/queries';
import { useAllOfficesMap } from '../office/queries';
import { useFragmentOrFetch } from '../shared/useFragmentOrFetch';
import { useMap } from '../shared/useMap';
import { useQueryAll } from '../shared/useQueryAll';

// SUBSCRIBE

const onEmployeeSubscriptionData = (
  data?: TOnAnyEmployeeChangedSubscription,
  vars?: TOnAnyEmployeeChangedSubscriptionVariables,
) => {
  const { id, companyId, _deleted } = data?.onAnyEmployeeChanged || {};

  if (_deleted) {
    return onSubscriptionData(data as Record<string, any>, vars);
  } else if (id && companyId) {
    client
      .query<TGetEmployeeQuery, TGetEmployeeQueryVariables>({
        query: GetEmployeeDocument,
        variables: {
          id,
          companyId,
        },
      })
      .then((response) => {
        const employeeData = response?.data?.getEmployee;

        if (employeeData) {
          const subscriptionData = {
            onAnyEmployeeChanged: employeeData as TConnectionItem,
          };

          // CHANGED DATA FOR QUERIES THAT DEPEND ON NO VARIABLE (SINCE VARS SHOULD BE AN EMPTY OBJECT IN THIS SITUATION)
          onSubscriptionData(subscriptionData, vars);

          // CHANGED DATA FOR ADDRESS TOO!
          onSubscriptionData(
            {
              onAnyAddressChanged: employeeData.address as TConnectionItem,
            },
            vars,
          );
        }
      })
      .catch((error) => {
        console.error('Error', serializeError(error));
      });
  }
};

let onAnyEmployeeChangedSubscribed = false;

export const useSubscribeToEmployeeChanged = () => {
  useEffect(() => {
    if (!onAnyEmployeeChangedSubscribed) {
      subscribe({
        client,
        query: OnAnyEmployeeChangedDocument,
        onSubscriptionData: (data, vars) => {
          return onEmployeeSubscriptionData(
            data as TOnAnyEmployeeChangedSubscription,
            vars as TOnAnyEmployeeChangedSubscriptionVariables,
          );
        },
        variables: {},
      });

      onAnyEmployeeChangedSubscribed = true;
    }
  }, []);
};

// LIST

export const useFullEmployees = <T extends TBasicEmployeeFieldsFragment>(employees: T[]) => {
  const { data: companiesMap, error: companiesError, loading: companiesLoading } = useAllCompaniesMap();

  const { data: officesMap, error: officesError, loading: officesLoading } = useAllOfficesMap();

  const { data: addressesMap, error: addressesError, loading: addressesLoading } = useAllAddressesMap();

  const fullEmployees = useMemo(() => {
    return employees.map((e) => {
      return {
        ...e,
        company: e.companyId ? companiesMap[e.companyId] : undefined,
        office: e.officeId ? officesMap[e.officeId] : undefined,
        address: addressesMap[`${process.env.REACT_APP_EMPLOYEES_TABLE_NAME}|${e.id}`],
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressesMap, companiesMap, officesMap, JSON.stringify(employees)]);

  return {
    data: fullEmployees,
    error: companiesError || officesError || addressesError,
    loading: companiesLoading || officesLoading || addressesLoading,
  };
};

export const useCompanyQueryById = (companyId: string) => {
  useSubscribeToEmployeeChanged();
  const { data, loading, error } = useQuery(ListCompanyEmployeesDocument, { variables: { companyId } });
  return { data, loading, error };
};

export type TEmployeeType<T extends TBasicEmployeeFieldsFragment = TBasicEmployeeFieldsFragment> = ReturnType<
  typeof useFullEmployees<T>
>['data'][number];

export const useQueryAllEmployees = () => {
  useSubscribeToEmployeeChanged();

  const { data, error, loading } = useQueryAll<TListEmployeesQuery, TListEmployeesQueryVariables>({
    useQuery: useListEmployeesLazyQuery,
    variables: {},
  });

  const {
    data: employees,
    error: fullError,
    loading: fullLoading,
  } = useFullEmployees<TEmployeeWithAddressFieldsFragment>(_.compact(data?.listEmployees?.items));

  return {
    data: employees,
    error: error || fullError,
    loading: loading || fullLoading,
  };
};

export const useQueryAllCompanyEmployeesWithDependants = (
  variables: TListCompanyEmployeesWithDependantsQueryVariables,
  fetchPolicy?: FetchPolicy,
) => {
  useSubscribeToEmployeeChanged();

  const { data, error, loading } = useQueryAll<
    TListCompanyEmployeesWithDependantsQuery,
    TListCompanyEmployeesWithDependantsQueryVariables
  >({
    useQuery: useListCompanyEmployeesWithDependantsLazyQuery,
    variables,
    fetchPolicy,
  });

  const {
    data: employees,
    error: fullError,
    loading: fullLoading,
  } = useFullEmployees<TEmployeeWithDependantsFieldsFragment>(_.compact(data?.listCompanyEmployees?.items));

  return {
    data: employees,
    error: error || fullError,
    loading: loading || fullLoading,
  };
};

export const useAllEmployeesMap = () => {
  const { data, error, loading } = useQueryAllEmployees();

  const employeesMap = useMap<(typeof data)[number]>(data);

  return {
    data: employeesMap,
    error,
    loading: loading || employeesMap == null,
  };
};

// ITEMS

export const useFragmentOrFetchEmployee = (variables: TGetEmployeeQueryVariables) => {
  useSubscribeToEmployeeChanged();

  const { data, error, loading } = useFragmentOrFetch<
    TEmployeeWithAddressFieldsFragment,
    TGetEmployeeQuery,
    TGetEmployeeQueryVariables
  >({
    fragmentDoc: EmployeeWithAddressFieldsFragmentDoc,
    useLazyQuery: useGetEmployeeLazyQuery,
    variables,
    __typename: 'Employee',
  });

  const {
    data: employees,
    error: fullError,
    loading: fullLoading,
  } = useFullEmployees(_.compact([data]) as TEmployeeWithAddressFieldsFragment[]);

  return {
    data: employees[0],
    error: error || fullError,
    loading: loading || fullLoading,
  };
};
