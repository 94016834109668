import type { TControlledFormComponentProps } from '@chocolate-soup-inc/cs-frontend-components/lib/esm/components/Form/types/ControlledFormField';
import { TWorkAnniversaryGiftVariations } from '../../generated/graphql';
import { TCompanyType } from './queries';

export const getCompanyFormFields = (
  company?: TCompanyType,
  companyManagers?: Record<'label' | 'value', string>[],
): TControlledFormComponentProps[] => {
  const fields: TControlledFormComponentProps[] = [
    {
      type: 'section',
      title: 'Basic Configuration',
    },
    {
      type: 'textField',
      label: 'Company Name',
      name: 'name',
      inputOptions: {
        multiline: false,
        autoComplete: 'off',
      },
    },
    {
      type: 'select',
      label: 'Delivery Method',
      name: 'deliveryMethod',
      inputOptions: {
        multiple: false,
        autoComplete: 'off',
        options: [
          {
            label: 'Home',
            value: 'home',
          },
          {
            label: 'Hybrid',
            value: 'hybrid',
          },
          {
            label: 'Office',
            value: 'office',
          },
        ],
        variant: 'outlined',
      },
    },
    {
      type: 'select',
      label: 'Chocolate Soup Manager',
      name: 'accountManager',
      inputOptions: {
        multiple: false,
        autoComplete: 'off',
        options: companyManagers ?? [],
        variant: 'outlined',
      },
    },
    // {
    //   type: 'switch',
    //   label: 'Active',
    //   name: 'active',
    // },
    {
      type: 'switch',
      label: 'Live',
      name: 'isLive',
      inputOptions: {
        disabled: company?.isLive || false,
      },
    },
    {
      type: 'section',
      title: 'Access Configuration',
    },
  ];

  if (company == null) {
    fields.push({
      type: 'textField',
      label: 'Subdomain',
      name: 'subdomain',
      inputOptions: {
        disabled: company != null,
        multiline: false,
        autoComplete: 'off',
        supportingText:
          'The subdomain will be used to generate the user access URL. The final access link for the client user will be https://${subdomain}.chocolatesoup.ca',
      },
    });
  }

  fields.push(
    {
      type: 'textArea',
      label: 'Email Domains',
      name: 'emailDomains',
      inputOptions: {
        multiline: true,
        autoComplete: 'off',
        rows: 3,
        supportingText:
          'All users from that company will need an email from this domain to login in the client portal. You can register 1 email per line.',
        transformIn: (data: string[]) => data.join('\n'),
        transformOut: (data: string) => data.split('\n'),
      },
    },
    {
      type: 'section',
      title: 'Gifts Configuration',
    },
    {
      type: 'checkbox',
      label: 'Employee New Hire Kit',
      name: 'employeeNewHireKitActivated',
    },
    {
      type: 'checkbox',
      label: 'Employee Work Anniversary',
      name: 'employeeWorkAnniversaryActivated',
    },
    {
      type: 'checkbox',
      label: 'Employee Birthday',
      name: 'employeeBirthdayActivated',
    },
    {
      type: 'checkbox',
      label: 'Significant Other Birthday',
      name: 'significantOtherBirthdayActivated',
    },
    {
      type: 'checkbox',
      label: 'Child Birthday',
      name: 'childBirthdayActivated',
    },
    {
      type: 'checkbox',
      label: 'Pet Birthday',
      name: 'petBirthdayActivated',
    },
    {
      type: 'checkbox',
      label: 'Has Custom Onboard Brick',
      name: 'hasCustomOnboardBrick',
      show: (data) => data.employeeNewHireKitActivated === true,
    },
    {
      type: 'section',
      title: 'Work Anniversary Configuration',
      show: (data) => data.employeeWorkAnniversaryActivated === true,
    },
    {
      type: 'select',
      label: 'Work Anniversary Variation',
      name: 'workAnniversaryGiftVariation',
      show: (data) => data.employeeWorkAnniversaryActivated === true,
      inputOptions: {
        multiple: false,
        variant: 'outlined',
        options: [
          {
            label: 'Deluxe',
            value: TWorkAnniversaryGiftVariations.Deluxe,
          },
          {
            label: 'Mini',
            value: TWorkAnniversaryGiftVariations.Mini,
          },
          {
            label: 'Brick Only',
            value: TWorkAnniversaryGiftVariations.BrickOnly,
          },
          {
            label: 'Custom',
            value: TWorkAnniversaryGiftVariations.Custom,
          },
        ],
      },
    },
    {
      type: 'textField',
      label: 'Custom Work Anniversary Notes',
      name: 'customWorkAnniversaryNotes',
      show: (data) =>
        data.employeeWorkAnniversaryActivated === true &&
        data.workAnniversaryGiftVariation === TWorkAnniversaryGiftVariations.Custom,
    },
    {
      type: 'section',
      title: 'Gift Customization',
    },
    {
      type: 'textField',
      label: 'Lego Color',
      name: 'legoColor',
      inputOptions: {
        multiline: false,
        autoComplete: 'off',
      },
    },
    {
      type: 'textField',
      label: 'Tissue Color',
      name: 'tissueColor',
      inputOptions: {
        multiline: false,
        autoComplete: 'off',
      },
    },
    {
      type: 'textArea',
      label: 'Gift Special Notes',
      name: 'specialNote',
      inputOptions: {
        multiline: true,
        autoComplete: 'off',
      },
    },
    {
      type: 'textArea',
      label: 'New Hire Notes',
      name: 'swag',
      inputOptions: {
        multiline: true,
        autoComplete: 'off',
      },
    },
    {
      type: 'textField',
      label: 'Sign As',
      name: 'signAs',
      inputOptions: {
        multiline: false,
        autoComplete: 'off',
      },
    },
    {
      type: 'section',
      title: 'Service Configuration',
    },
    {
      type: 'datePicker',
      label: 'Start Date',
      name: 'startDate',
      inputOptions: {
        disabled: (company?.isLive === true && company.startDate != null) || false,
      },
    },
    {
      type: 'datePicker',
      label: 'End Date',
      name: 'endDate',
    },
    {
      type: 'section',
      title: 'Contact',
    },
    {
      name: 'contact.name',
      label: 'Contact Name',
      type: 'textField',
      inputOptions: {
        multiline: false,
        autoComplete: 'off',
      },
    },
    {
      name: 'contact.email',
      label: 'Contact Email',
      type: 'textField',
      inputOptions: {
        multiline: false,
        autoComplete: 'off',
      },
    },
    {
      name: 'contact.phoneNumber',
      label: 'Contact Phone number',
      type: 'textField',
      inputOptions: {
        multiline: false,
        autoComplete: 'off',
      },
    },
    {
      title: 'Address',
      type: 'section',
    },
    {
      name: 'address.address1',
      label: 'Address Line 1',
      type: 'textField',
      inputOptions: {
        multiline: false,
        autoComplete: 'off',
      },
    },
    {
      name: 'address.address2',
      label: 'Address Line 2',
      type: 'textField',
      inputOptions: {
        multiline: false,
        autoComplete: 'off',
      },
    },
    {
      name: 'address.city',
      label: 'City',
      type: 'textField',
      inputOptions: {
        multiline: false,
        autoComplete: 'off',
      },
    },
    {
      name: 'address.state',
      label: 'Province / State',
      type: 'textField',
      inputOptions: {
        multiline: false,
        autoComplete: 'off',
      },
    },
    {
      name: 'address.zipCode',
      label: 'Zip Code',
      type: 'textField',
      inputOptions: {
        multiline: false,
        autoComplete: 'off',
      },
    },
    {
      name: 'address.country',
      label: 'Country',
      type: 'countrySelect',
      inputOptions: {
        autoComplete: 'off',
        variant: 'outlined',
      },
    },
    {
      title: 'Billing',
      type: 'section',
    },
    {
      name: 'subscriptionPricePerUser',
      label: 'Subscription Price per User',
      type: 'textField',
      inputOptions: {
        multiline: false,
        type: 'number',
        autoComplete: 'off',
        variant: 'outlined',
      },
    },
    {
      name: 'subscriptionPriceCurrency',
      label: 'Subscription Price Currency',
      type: 'select',
      inputOptions: {
        multiple: false,
        variant: 'outlined',
        options: [
          {
            label: 'CAD',
            value: 'CAD',
          },
          {
            label: 'USD',
            value: 'USD',
          },
        ],
      },
    },
    {
      title: 'Custom Shipping Configuration',
      type: 'section',
    },
    {
      type: 'fieldArray',
      name: 'customShippingConfiguration',
      addLabel: 'Add Shipping Configuration',
      label: '',
      removeLabel: 'Remove Shipping Configuration',
      fields: [
        {
          name: 'carrier',
          label: 'Carrier',
          type: 'select',
          inputOptions: {
            multiple: false,
            variant: 'outlined',
            options: [
              {
                label: 'UPS',
                value: 'ups',
              },
            ],
          },
        },
        {
          type: 'textField',
          label: 'Account Number',
          name: 'accountNumber',
          inputOptions: {
            autoComplete: 'off',
            variant: 'outlined',
            multiline: false,
            type: 'password',
          },
        },
        {
          type: 'textField',
          label: 'Postal Code',
          name: 'postalCode',
          inputOptions: {
            autoComplete: 'off',
            variant: 'outlined',
            multiline: false,
          },
        },
        {
          type: 'textField',
          label: 'Country Code',
          name: 'countryCode',
          inputOptions: {
            autoComplete: 'off',
            variant: 'outlined',
            multiline: false,
          },
        },
      ],
    },
  );

  return fields;
};
