import { IconButton, TableInner, Tooltip } from '@chocolate-soup-inc/cs-frontend-components';
import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import { CountryName } from '../../../components/coutryName/CountryName';
import { TCustomsMappingType } from '../../../entities/giftPackages/queries';
import { pickProductsMapDefaultValues } from '../../../entities/products/shared';
import { TShipmentType } from '../../../entities/shipments/queries';
import { useShipmentAddressInfo } from '../../../entities/shipments/shared';
import { TProduct, TProductFieldsFragment } from '../../../generated/graphql';
import { getDefaultCustoms } from '../PrintLabelModal/customs';
import { PrintLabelModal } from '../PrintLabelModal/PrintLabelModal';
import styles from './PrintMultipleLabelModal.module.scss';

type TShipmentCustomsTableProps = {
  shipment: TShipmentType;
  setShipmentsToUpdate: React.Dispatch<React.SetStateAction<TShipmentType[]>>;
  defaultPackages: TCustomsMappingType;
};

export const ShipmentCustomsTable = ({
  shipment,
  setShipmentsToUpdate,
  defaultPackages,
}: TShipmentCustomsTableProps) => {
  const { recipientName } = useShipmentAddressInfo(shipment);

  const isInternational = useMemo(() => shipment.address?.country !== 'CAN', [shipment]);
  const [openModal, setOpenModal] = useState(false);

  const defaultCustoms = useMemo(() => {
    if (!isInternational) return null;
    if (!shipment.customsData) return getDefaultCustoms(shipment, defaultPackages);
    return pickProductsMapDefaultValues(shipment.customsData as TProduct[]);
  }, [shipment, isInternational, defaultPackages]);

  useEffect(() => {
    setShipmentsToUpdate((prev) => {
      const index = prev.findIndex((s) => s.id === shipment.id);
      if (index === -1) return prev;
      prev[index].customsData = defaultCustoms;
      return prev;
    });
  }, [shipment, defaultPackages, isInternational, setShipmentsToUpdate, defaultCustoms]);

  return (
    <div className={styles.shipmentTableContainer}>
      <div className={styles.topTitle}>
        <h4 className={styles.topTitle}>
          Shipment for {recipientName} at {shipment.company?.name ?? '-'}
        </h4>
        <Tooltip message={isInternational ? 'Edit customs information' : 'Shipment destination is Canada'}>
          <IconButton disabled={!isInternational} icon={'edit'} onClick={() => setOpenModal(true)} variant='outlined' />
        </Tooltip>
      </div>

      {isInternational && (
        <div>
          <TableInner<TProductFieldsFragment>
            className={clsx(styles.containerTablePrintingMultipleLabel)}
            columns={[
              {
                header: 'HS Code',
                cell: ({ cell }) => {
                  return (
                    <div className={clsx(styles.tableSpace75px)}>
                      <span>{cell.row.original.hsCode}</span>
                    </div>
                  );
                },
              },
              {
                header: 'Description',
                cell: ({ cell }) => {
                  return (
                    <div className={clsx(styles.tableSpace125px)}>
                      <span>{cell.row.original.description}</span>
                    </div>
                  );
                },
              },
              {
                header: 'Value',
                cell: ({ cell }) => {
                  return (
                    <div className={clsx(styles.tableSpace90px)}>
                      <span>${cell.row.original.value} CAD</span>
                    </div>
                  );
                },
              },
              {
                header: 'Quantity',
                cell: ({ cell }) => {
                  return (
                    <div className={clsx(styles.tableSpace75px)}>
                      <span>{cell.row.original.quantity}</span>
                    </div>
                  );
                },
              },
              {
                header: 'Country',
                cell: ({ cell }) => {
                  return (
                    <div className={clsx(styles.tableSpace125px)}>
                      <CountryName name={cell.row.original.country || undefined} />
                    </div>
                  );
                },
              },
            ]}
            data={(shipment.customsData as TProductFieldsFragment[]) ?? []}
            emptyText='No products were found.'
            hoverableRows={true}
            virtual={true}
          />
        </div>
      )}
      {openModal && (
        <PrintLabelModal
          shipment={shipment}
          onCancel={() => setOpenModal(false)}
          onSuccess={() => setOpenModal(false)}
          defaultPackages={defaultPackages}
          fromMultiple={true}
          setShipmentsToUpdate={setShipmentsToUpdate}
        />
      )}
    </div>
  );
};
