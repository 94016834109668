import { isDependant } from '../../../entities/recipients/shared';
import { TShipmentType } from '../../../entities/shipments/queries';
import {
  TDependantType,
  TGiftPackageType,
  TGiftTypes,
  TWorkAnniversaryGiftVariations,
} from '../../../generated/graphql';
import { TCustomsMappingType, TDefaultPackageType } from '../../../entities/giftPackages/queries';
import { differenceInYears } from 'date-fns';
import { normalizeString } from '../../../entities/shared/utils';
import { TCuratedProduct } from '../../../entities/products/queries';
import _ from 'lodash';

export const getDefaultCustoms = (shipment: TShipmentType, defaultPackages: TCustomsMappingType) => {
  let allProducts: TCuratedProduct[] = [];

  const company = shipment.company;

  const hasNewHireOverride =
    company?.employeeNewHireKitActivated &&
    company?.hydratedProducts &&
    company?.hydratedProducts[TGiftPackageType.NewHire].length;

  const hasWorkAnniversaryOverride =
    company?.employeeWorkAnniversaryActivated &&
    company?.hydratedProducts &&
    company?.hydratedProducts[TGiftPackageType.WorkAnniversary].length;

  const hasEmployeeBirthdayOverride =
    company?.employeeBirthdayActivated &&
    company?.hydratedProducts &&
    company?.hydratedProducts[TGiftPackageType.EmployeeBirthday].length;

  const hasSpouseBirthdayOverride =
    company?.significantOtherBirthdayActivated &&
    company?.hydratedProducts &&
    company?.hydratedProducts[TGiftPackageType.SpouseBirthday].length;

  for (const gift of shipment.gifts || []) {
    if (gift.type === TGiftTypes.NewHire) {
      if (company?.hydratedProducts && hasNewHireOverride) {
        allProducts = [...allProducts, ...company.hydratedProducts[TGiftPackageType.NewHire]];
      } else {
        allProducts = [...allProducts, ...defaultPackages['default new hire kit']];
      }
    } else if (gift.type === TGiftTypes.WorkAnniversary) {
      if (company?.hydratedProducts && hasWorkAnniversaryOverride) {
        allProducts = [...allProducts, ...company.hydratedProducts[TGiftPackageType.WorkAnniversary]];
      } else if (company?.workAnniversaryGiftVariation === TWorkAnniversaryGiftVariations.BrickOnly) {
        allProducts = [...allProducts, ...defaultPackages['default work ann brick only']];
      } else if (company?.workAnniversaryGiftVariation === TWorkAnniversaryGiftVariations.Deluxe) {
        allProducts = [...allProducts, ...defaultPackages['default work ann large plan']];
      } else if (company?.workAnniversaryGiftVariation === TWorkAnniversaryGiftVariations.Mini) {
        allProducts = [...allProducts, ...defaultPackages['default work ann small plan']];
      }
    } else if (gift.type === TGiftTypes.Birthday) {
      const recipient = gift.recipient;

      if (recipient) {
        if (isDependant(recipient)) {
          if (recipient.type === TDependantType.SignificantOther) {
            if (company?.hydratedProducts && hasSpouseBirthdayOverride) {
              allProducts = [...allProducts, ...company.hydratedProducts[TGiftPackageType.SpouseBirthday]];
            } else {
              allProducts = [...allProducts, ...defaultPackages['default spouse birthday']];
            }
          } else if (recipient.type === TDependantType.Pet) {
            const petType = normalizeString(recipient.petType as string);

            switch (petType) {
              case 'dog':
              default:
                allProducts = [...allProducts, ...defaultPackages['default pet birthday dog']];
                break;
              case 'cat':
                allProducts = [...allProducts, ...defaultPackages['default pet birthday cat']];
                break;
            }
          } else if (recipient.type === TDependantType.Child) {
            const yearsDiff = differenceInYears(new Date(), new Date(recipient.birthDate as string));

            switch (true) {
              case yearsDiff >= 1 && yearsDiff <= 9:
                allProducts = [
                  ...allProducts,
                  ...defaultPackages[`default child birthday ${yearsDiff} yo` as TDefaultPackageType],
                ];
                break;
              case yearsDiff >= 10 && yearsDiff <= 14:
                allProducts = [...allProducts, ...defaultPackages['default child birthday 10 to 14 yo']];
                break;
              case yearsDiff >= 15 && yearsDiff <= 18:
                allProducts = [...allProducts, ...defaultPackages['default child birthday 15 to 18 yo']];
                break;
              default:
                break;
            }
          }
        } else {
          if (company?.hydratedProducts && hasEmployeeBirthdayOverride) {
            allProducts = [...allProducts, ...company.hydratedProducts[TGiftPackageType.EmployeeBirthday]];
          } else {
            allProducts = [...allProducts, ...defaultPackages['default employee birthday']];
          }
        }
      }
    }
  }

  return _.map(_.groupBy(allProducts, 'id'), (group) =>
    _.pick(
      {
        ...group[0],
        quantity: _.sumBy(group, 'quantity'),
        //value: _.sumBy(group, 'value'),
      },
      ['value', 'hsCode', 'quantity', 'description', 'country'],
    ),
  );
};
