import _ from 'lodash';
import { useMemo } from 'react';
import {
  OnCompanyUserChangedDocument,
  TListUsersIncludingCsUsersQuery,
  TListUsersIncludingCsUsersQueryVariables,
  TOnCompanyUserChangedSubscriptionVariables,
  TUserFieldsFragment,
  useListAdminCognitoUsersQuery,
  useListUsersIncludingCsUsersLazyQuery,
} from '../../generated/graphql';
import { useAllCompaniesMap } from '../companies/queries';
import { useQueryAll } from '../shared/useQueryAll';

// LIST

export const useFullUsers = <T extends TUserFieldsFragment>(users: T[]) => {
  const { data: companiesMap, error: companiesError, loading: companiesLoading } = useAllCompaniesMap();

  const fullUsers = useMemo(() => {
    return users.map((u) => {
      return {
        ...u,
        company: u.companyId ? companiesMap[u.companyId] : undefined,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companiesMap, JSON.stringify(users)]);

  return {
    data: fullUsers,
    error: companiesError,
    loading: companiesLoading,
  };
};

export type TCognitoUser = {
  sub: string;
  identities: string;
  email_verified: string;
  name: string;
  given_name: string;
  family_name: string;
  email: string;
  picture: string;
};

export type TCognitoUsersMap = Record<string, TCognitoUser>;

export const useAdminCognitoUserMap = (userPoolId = process.env.REACT_APP_COGNITO_USER_POOL_ID as string) => {
  const {
    data: allAdminCognitoUsers,
    error,
    loading,
  } = useListAdminCognitoUsersQuery({
    variables: { userPoolId },
  });

  const adminCognitoUserMap = useMemo<TCognitoUsersMap>(() => {
    if (!allAdminCognitoUsers) return {};

    const parsedCognitoUsers = JSON.parse(allAdminCognitoUsers.listAdminCognitoUsers as string);

    const result = parsedCognitoUsers.Users.reduce((acc: Record<any, any>, user: any) => {
      // Get the username to use as the key
      const username = user.Username;
      // Flatten the attributes array into an object
      const attributes = user.Attributes.reduce((attrAcc: Record<string, any>, attr: any) => {
        attrAcc[attr.Name] = attr.Value;
        return attrAcc;
      }, {});
      // Add the user attributes to the result object with username as the key
      acc[username] = { ...attributes, ..._.omit(user, ['Attributes']) };
      return acc;
    }, {});

    const resultObjEnt = Object.entries(result);
    const isInternalUserPool = userPoolId === (process.env.REACT_APP_COGNITO_USER_POOL_ID as string);

    const finalResult = resultObjEnt.reduce((acc: Record<string, any>, [key, value]) => {
      if (key.includes('google') && !isInternalUserPool) return acc;
      // Parse the value from type unknown
      const cognitoValue = value as TCognitoUser;
      // Check if a google user with this' email exists
      const googleUser = resultObjEnt.find(
        ([gKey, gValue]) => gKey.includes('google') && (gValue as TCognitoUser).email === cognitoValue.email,
      )?.[1];
      // If not return as normal
      if (!googleUser || isInternalUserPool) {
        acc[key] = value;
        return acc;
      }
      // If it has, parse its keys with the prefix "google:"
      const googleInfo = Object.entries(googleUser).reduce((attrAcc: Record<string, any>, [key, value]: any) => {
        attrAcc[`google:${key}`] = value;
        return attrAcc;
      }, {});
      // Add the google attributes to avoid duplicates
      acc[key] = { ...cognitoValue, ...googleInfo };
      return acc;
    }, {});

    return finalResult;
  }, [allAdminCognitoUsers, userPoolId]);

  return {
    data: adminCognitoUserMap,
    error,
    loading: loading || adminCognitoUserMap == null,
  };
};

export const useCSAdminCognitoUserMap = () => {
  const { data, error, loading } = useAdminCognitoUserMap();

  const curatedAdmins = useMemo(
    () =>
      Object.entries(data || {}).reduce((acc, [key, value]) => {
        if (!value.email.includes('@chocolatesoup.ca')) return acc;

        return {
          ...acc,
          [key]: value,
        };
      }, {}),
    [data],
  );

  return {
    data: curatedAdmins,
    loading,
    error,
  };
};

export type TUserType<T extends TUserFieldsFragment = TUserFieldsFragment> = ReturnType<
  typeof useFullUsers<T>
>['data'][number];

export const useQueryAllUsers = (variables: TListUsersIncludingCsUsersQueryVariables) => {
  const { data, error, loading } = useQueryAll<
    TListUsersIncludingCsUsersQuery,
    TListUsersIncludingCsUsersQueryVariables,
    TOnCompanyUserChangedSubscriptionVariables
  >({
    subscriptionDoc: OnCompanyUserChangedDocument,
    subscriptionVariables: {
      companyId: variables.companyId,
    },
    useQuery: useListUsersIncludingCsUsersLazyQuery,
    variables,
  });

  const {
    data: users,
    error: fullError,
    loading: fullLoading,
  } = useFullUsers(_.compact(data?.listUsersIncludingCSUsers?.items));

  return {
    data: users,
    error: error || fullError,
    loading: loading || fullLoading,
  };
};
