import { Storage } from '@chocolate-soup-inc/cs-api-consumer-utils';
import {
  Card,
  Chip,
  ConfirmationModal,
  CountryAvatar,
  FloatingMenu,
  getCountryName,
  getDateWithTimezoneFixed,
  Icon,
  IconButton,
  TConfirmationModalProps,
  TMenuItemProps,
  Tooltip,
} from '@chocolate-soup-inc/cs-frontend-components';
import clsx from 'clsx';
import { format } from 'date-fns';
import _ from 'lodash';
import { MouseEvent, useCallback, useMemo, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { serializeError } from 'serialize-error';
import { TDraggableCardRenderOptions } from '../../../components/board/DraggableCard';
import { useShipmentAddressInfo } from '../../../entities/shipments/shared';
import {
  TAlert,
  TShipmentStatuses,
  TShipStationAddressVerified,
  useDeleteShipmentMutation,
  usePrintLabelPhysicallyMutation,
  useRecreateShipmentMutation,
  useResyncShipmentWithShipStationMutation,
  useSetShipmentForPickupMutation,
} from '../../../generated/graphql';
import { COMPANY_PATH, DETAILS_PATH, SHIPMENT_PATH, SHIPMENTS_PATH } from '../../../routes/paths';

import { AlertsTooltip } from '../../../components/alerts/AlertsTooltip';
import { TShipmentTypeGroupable } from '../../../entities/shipments/queries';
import styles from './ShipmentCard.module.scss';

type TShipmentCardProps = {
  shipment: TShipmentTypeGroupable;
  options?: TDraggableCardRenderOptions & {
    hoverDisabled?: boolean;
  };
  onShipManually: () => void;
  onGroupable: () => void;
};

export const ShipmentCard = (props: TShipmentCardProps) => {
  const {
    shipment,
    onGroupable,
    options: {
      hoverDisabled = false,
      isSelected,
      isDragging,
      measureElement,
      overlay,
      rowIndexAttribute,
      rowIndexAttributeName,
    } = {},
    onShipManually,
  } = props;

  const [confirmationProps, setConfirmationProps] =
    useState<Omit<TConfirmationModalProps, 'closeModal' | 'confirmLoading' | 'onCancelClick'>>();

  const { addressTypeIcon, readableAddressType, recipientName } = useShipmentAddressInfo(shipment);

  const [openLabelLoading, setOpenLabelLoading] = useState<boolean>(false);

  const labelDataPresent = useMemo(() => {
    return shipment?.label?.labelFile?.key && shipment?.label?.labelFile?.bucket && shipment?.label?.labelFile?.region;
  }, [shipment?.label?.labelFile?.bucket, shipment?.label?.labelFile?.key, shipment?.label?.labelFile?.region]);

  const addressVerified = useMemo(() => {
    return shipment.addressVerified;
  }, [shipment?.addressVerified]);

  const [printLabelPhysically] = usePrintLabelPhysicallyMutation({});

  const openLabel = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      if (labelDataPresent) {
        setOpenLabelLoading(true);

        printLabelPhysically({
          variables: { companyId: shipment.companyId, id: shipment.id, version: shipment._version },
        })
          .then()
          .catch(() => toast.error('Error updating printing status'));

        Storage.get(shipment?.label?.labelFile?.key as string, {
          bucket: shipment?.label?.labelFile?.bucket as string,
          region: shipment?.label?.labelFile?.region as string,
        })
          .then((url) => {
            window.open(url, '_blank');
          })
          .catch((error) => {
            console.error(serializeError(error));
            toast.error('There was an error retrieving the label.');
          })
          .then(() => {
            setOpenLabelLoading(false);
          });
      }
    },
    [labelDataPresent, shipment, printLabelPhysically],
  );

  const [resyncShipmentWithShipStation, { loading: resyncWithShipStationLoading }] =
    useResyncShipmentWithShipStationMutation();

  const syncWithShipStation = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      if (shipment && [TShipmentStatuses.ReadyToShip, TShipmentStatuses.PrintingLabel].includes(shipment.status)) {
        resyncShipmentWithShipStation({
          variables: {
            id: shipment?.id,
            companyId: shipment?.companyId,
            version: shipment?._version,
          },
        })
          .then(() => {
            toast.success(
              'Shipment set for re-sync with ShipStation successfully. It might take a couple of minutes for resync to be done.',
            );
          })
          .catch((error) => {
            console.error(serializeError(error));
            toast.error('There was an error resyncing the shipment with ShipStation.');
          });
      }
    },
    [resyncShipmentWithShipStation, shipment],
  );

  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const [setShipmentForPickup, { loading: setShipmentForPickupLoading }] = useSetShipmentForPickupMutation({
    variables: {
      id: shipment.id,
      companyId: shipment.companyId,
      version: shipment._version,
    },
  });

  const onActualSetShipmentForPickup = useCallback(() => {
    setShipmentForPickup()
      .then()
      .catch((error) => {
        console.error(serializeError(error));
        toast.error('There was an error setting the shipment for pickup.');
      })
      .then(() => {
        setConfirmationProps(undefined);
      });
  }, [setShipmentForPickup]);

  const onSetForPickupClick = useCallback(() => {
    setConfirmationProps({
      headline: 'Set shipment for Pickup?',
      supportingText: 'Are you sure you want to set this shipment for pickup?',
      confirmLabel: 'Set for pickup',
      onConfirmClick: onActualSetShipmentForPickup,
    });
  }, [onActualSetShipmentForPickup]);

  const [deleteShipment, { loading: deleteLoading }] = useDeleteShipmentMutation({
    variables: {
      id: shipment.id,
      companyId: shipment.companyId,
      version: shipment._version,
    },
  });

  const onActualDelete = useCallback(() => {
    deleteShipment()
      .then()
      .catch((error) => {
        console.error(serializeError(error));
        toast.error('There was an error deleting the shipment.');
      })
      .then(() => {
        setConfirmationProps(undefined);
      });
  }, [deleteShipment]);

  const onDeleteClick = useCallback(() => {
    setConfirmationProps({
      headline: 'Delete shipment?',
      supportingText: 'Are you sure you want to delete this shipment?',
      confirmLabel: 'Delete',
      onConfirmClick: onActualDelete,
    });
  }, [onActualDelete]);

  const [recreateShipment, { loading: recreateLoading }] = useRecreateShipmentMutation({
    variables: {
      id: shipment.id,
      companyId: shipment.companyId,
    },
  });

  const onActualRecreate = useCallback(() => {
    recreateShipment()
      .then()
      .catch((error) => {
        console.error(serializeError(error));
        toast.error('There was an error recreating the shipment.');
      })
      .then(() => {
        setConfirmationProps(undefined);
      });
  }, [recreateShipment]);

  const onRecreateClick = useCallback(() => {
    setConfirmationProps({
      headline: 'Recreate shipment?',
      supportingText: 'Are you sure you want to recreate this shipment gifts?',
      confirmLabel: 'Recreate',
      onConfirmClick: onActualRecreate,
    });
  }, [onActualRecreate]);

  const menuOptions: TMenuItemProps[] = useMemo(() => {
    const opts: TMenuItemProps[] = [];

    if (shipment.status === TShipmentStatuses.Packaging) {
      opts.push({
        disabled: deleteLoading || recreateLoading,
        label: 'Set for Pickup',
        leadingIcon: 'home_work',
        loading: setShipmentForPickupLoading,
        type: 'text',
        onClick: onSetForPickupClick,
      });
      if (shipment.groupable) {
        opts.push({
          label: 'Group by Address',
          leadingIcon: 'view_compact_alt',
          type: 'text',
          onClick: () => {
            onGroupable();
            setMenuOpen(false);
          },
        });
      }
    }

    if (
      [
        TShipmentStatuses.PrintingLabel,
        TShipmentStatuses.LabelPrinted,
        TShipmentStatuses.Shipped,
        TShipmentStatuses.Attention,
        TShipmentStatuses.Delivered,
        TShipmentStatuses.Error,
        TShipmentStatuses.Canceled,
      ].includes(shipment.status)
    ) {
      opts.push({
        disabled: deleteLoading || setShipmentForPickupLoading,
        label: 'Recreate Shipment',
        leadingIcon: 'control_point_duplicate',
        loading: recreateLoading,
        type: 'text',
        onClick: onRecreateClick,
      });
    }

    if (shipment.trackingNumber) {
      opts.push({
        disabled: deleteLoading || recreateLoading || setShipmentForPickupLoading,
        label: 'Copy Tracking Number',
        leadingIcon: 'content_copy',
        type: 'text',
        onClick: () => {
          if (shipment.trackingNumber) {
            setMenuOpen(false);
            navigator.clipboard.writeText(shipment.trackingNumber);
            toast.success('Tracking Number Copied to Clipboard');
          }
        },
      });
    }

    if (shipment.trackingURL) {
      opts.push({
        disabled: deleteLoading || recreateLoading || setShipmentForPickupLoading,
        label: 'Track Shipment',
        leadingIcon: 'open_in_new',
        type: 'text',
        onClick: () => {
          if (shipment.trackingURL) {
            setMenuOpen(false);
            window.open(shipment.trackingURL, '_blank');
          }
        },
      });
    }

    if ([TShipmentStatuses.ReadyToShip, TShipmentStatuses.PrintingLabel].includes(shipment.status)) {
      opts.push({
        label: 'Ship Manually',
        leadingIcon: 'edit',
        loading: deleteLoading,
        onClick: () => {
          onShipManually();
          setMenuOpen(false);
        },
        type: 'text',
      });
    }

    if (
      [
        TShipmentStatuses.Packaging,
        TShipmentStatuses.ReadyToShip,
        TShipmentStatuses.LabelPrinted,
        TShipmentStatuses.PrintingLabel,
        TShipmentStatuses.ReadyForPickup,
      ].includes(shipment.status)
    ) {
      opts.push({
        disabled: setShipmentForPickupLoading || recreateLoading,
        label: 'Delete Shipment',
        leadingIcon: 'delete',
        loading: deleteLoading,
        onClick: onDeleteClick,
        type: 'text',
      });
    }
    return opts;
  }, [
    deleteLoading,
    onDeleteClick,
    onRecreateClick,
    onSetForPickupClick,
    recreateLoading,
    setShipmentForPickupLoading,
    shipment,
    onShipManually,
    onGroupable,
  ]);

  const onMoreClick = useCallback((e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setMenuOpen((open) => !open);
  }, []);

  const navigate = useNavigate();

  const onCardClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      if (!e.shiftKey && !e.ctrlKey && !e.metaKey) {
        navigate(
          generatePath(`${SHIPMENTS_PATH}/${COMPANY_PATH}/${SHIPMENT_PATH}/${DETAILS_PATH}`, {
            companyId: shipment.companyId,
            shipmentId: shipment.id,
          }),
        );
      }
    },
    [navigate, shipment.companyId, shipment.id],
  );

  const countryName = useMemo(() => {
    return getCountryName(shipment.address?.country || undefined);
  }, [shipment.address?.country]);

  const shipmentHistory = useMemo(() => {
    return shipment?.history || [];
  }, [shipment?.history]);

  const isReship = useMemo(() => {
    return shipmentHistory.length > 0;
  }, [shipmentHistory]);

  const employeeIsDeleted = useMemo(() => {
    return shipment?.addressId.includes(process.env.REACT_APP_EMPLOYEES_TABLE_NAME as string) && !shipment?.employee;
  }, [shipment]);

  const allShipmentAlertsHaveBeenDismissed = useMemo(() => {
    if (!shipment.alerts) return true;
    return shipment.alerts?.every((a) => {
      return !!a?.acknowledgedAt;
    });
  }, [shipment.alerts]);

  const isPrintedPhysically = useMemo(
    () => shipment.isPrintedPhysically && shipment.status == TShipmentStatuses.LabelPrinted,
    [shipment],
  );

  return (
    <>
      {!_.isEmpty(confirmationProps) && (
        <ConfirmationModal
          {...confirmationProps}
          closeModal={() => setConfirmationProps(undefined)}
          confirmLoading={deleteLoading || setShipmentForPickupLoading || recreateLoading}
          onCancelClick={() => setConfirmationProps(undefined)}
        />
      )}
      <div
        ref={measureElement}
        {...(rowIndexAttributeName ? { [rowIndexAttributeName]: rowIndexAttribute } : {})}
        onClick={onCardClick}
      >
        <Card
          className={clsx(
            styles.card,
            (!allShipmentAlertsHaveBeenDismissed || isReship || !recipientName) && styles.alert,
            employeeIsDeleted && styles.deletedEmployee,
            isPrintedPhysically && styles.isPrintedPhysically,
            shipment.groupable && styles.groupable,
          )}
          readOnly={hoverDisabled || overlay}
          variant={isSelected ? 'elevated' : 'outlined'}
        >
          <>
            <div className={styles.icons}>
              <div className={styles.options}>
                <div className={styles.alertIcons}>
                  {shipment.label?.isThirdParty && (
                    <Tooltip message='Third party billed shipment.'>
                      <Icon className={styles.addressVerifiedSuccess} icon='output' />
                    </Tooltip>
                  )}
                  <AlertsTooltip alerts={shipment.alerts as TAlert[]} />
                  {shipment.groupable && (
                    <Tooltip message='This shipment can be combined.'>
                      <Icon className={styles.addressVerifiedSuccess} icon='view_compact_alt' />
                    </Tooltip>
                  )}
                  {addressVerified === TShipStationAddressVerified.Success && (
                    <Tooltip message='ShipStation address verification succeeded.'>
                      <Icon className={styles.addressVerifiedSuccess} icon='check_circle' />
                    </Tooltip>
                  )}
                  {addressVerified === TShipStationAddressVerified.Failed && (
                    <Tooltip message='ShipStation address verification failed.'>
                      <Icon className={styles.addressVerifiedFail} icon='error_circle_rounded' />
                    </Tooltip>
                  )}
                  {addressVerified === TShipStationAddressVerified.Warning && (
                    <Tooltip message='ShipStation address verification warning.'>
                      <Icon className={styles.addressVerifiedWarning} icon='warning' />
                    </Tooltip>
                  )}
                </div>
                {[TShipmentStatuses.ReadyToShip, TShipmentStatuses.PrintingLabel].includes(shipment.status) && (
                  <Tooltip
                    message={`Resync shipment with ShipStation.${
                      shipment.status === TShipmentStatuses.PrintingLabel
                        ? ` This will also try to print the label again.`
                        : ''
                    }`}
                  >
                    <IconButton
                      disabled={resyncWithShipStationLoading}
                      icon='sync'
                      onClick={syncWithShipStation}
                      variant='standard'
                    />
                  </Tooltip>
                )}
                {labelDataPresent && (
                  <IconButton disabled={openLabelLoading} icon='print' onClick={openLabel} variant='standard' />
                )}
                <FloatingMenu
                  autoPlacement={false}
                  placement='bottom-end'
                  open={menuOpen}
                  onOpenChange={setMenuOpen}
                  options={menuOptions}
                >
                  <IconButton icon='more_vert' onClick={onMoreClick} variant='standard' />
                </FloatingMenu>
              </div>
            </div>
            <div className={styles.country}>
              {readableAddressType && addressTypeIcon && shipment.address?.country && countryName && (
                <Tooltip autoPlacement={false} message={countryName} placement='bottom'>
                  <CountryAvatar elevated={true} size='medium' code={shipment.address?.country} />
                </Tooltip>
              )}
            </div>
            <span className={styles.companyName}>{shipment.company?.name}</span>
            <span className={styles.recipientName}>{recipientName}</span>
            <div className={styles.tags}>
              <Tooltip autoPlacement={false} disabled={isDragging} message='Delivery Date' placement='bottom'>
                <Chip
                  innerContainerClassName={styles.chip}
                  leadingIcon='local_shipping'
                  leadingIconProps={{
                    filled: true,
                  }}
                  label={format(
                    getDateWithTimezoneFixed(shipment.actualShippingDate || shipment.shippingDate || undefined) as Date,
                    'dd/MM/yyyy',
                  )}
                  readonly={true}
                  variant='input'
                />
              </Tooltip>
              <Tooltip autoPlacement={false} disabled={isDragging} message='Address Type' placement='bottom'>
                <Chip
                  innerContainerClassName={styles.chip}
                  leadingIcon={addressTypeIcon}
                  leadingIconProps={{
                    filled: true,
                  }}
                  label={readableAddressType || 'unknown'}
                  readonly={true}
                  variant='input'
                />
              </Tooltip>
              {isReship && (
                <Tooltip
                  autoPlacement={false}
                  disabled={isDragging}
                  message='This is a Reship of a returned shipment'
                  placement='bottom'
                >
                  <Chip
                    innerContainerClassName={styles.chip}
                    leadingIcon='replay'
                    leadingIconProps={{
                      weight: 500,
                    }}
                    label='Reship'
                    readonly={true}
                    variant='input'
                  />
                </Tooltip>
              )}
              {shipment.gifts && shipment?.gifts.length > 0 && (
                <Tooltip autoPlacement={false} disabled={isDragging} message='Gift count' placement='bottom'>
                  <Chip
                    innerContainerClassName={styles.chip}
                    leadingIcon='card_giftcard'
                    label={`${shipment.gifts.length} Gift${shipment.gifts.length > 1 ? 's' : ''}`}
                    readonly={true}
                    variant='input'
                  />
                </Tooltip>
              )}
            </div>
          </>
        </Card>
      </div>
    </>
  );
};
