import { ExtendedFAB, Icon, readableDate, TableInner, Tooltip } from '@chocolate-soup-inc/cs-frontend-components';
import { v4 as uuidv4 } from 'uuid';

import { RowSelectionState } from '@tanstack/react-table';
import { createRef, useCallback, useEffect, useMemo, useState } from 'react';
import { ONE_OFF_EVENT_TYPES, translateEventType } from '../../../../entities/events/shared';
import { TEventTypes, useCreateEventGiftMutation, useSkipEventMutation } from '../../../../generated/graphql';

import { CountryName } from '../../../../components/coutryName/CountryName';
import { Filters, TFiltersProps } from '../../../../components/filters/Filters';
import { FloatingActions } from '../../../../components/floatingActions/FloatingActions';
import { TablePage, TTablePageRef } from '../../../../components/tablePage/TablePage';

import clsx from 'clsx';
import _ from 'lodash';
import tableStyles from '../../../../components/tablePage/TablePage.module.scss';
import { useFilter } from '../../../../contexts/filters';
import { useUpcomingOneOffEvents } from '../../../../entities/events/queries';
import { useEventsFilters } from '../../shared';
import styles from './List.module.scss';

export const EventsOneOffList = () => {
  const { filtersPageMode } = useFilter();
  useEffect(() => {
    filtersPageMode();
  }, []); //eslint-disable-line

  const [selectedRows, setSelectedRows] = useState<string[]>();
  const [skipLoading, setSkipLoading] = useState<boolean>(false);
  const [createGiftsLoading, setCreateGiftsLoading] = useState<boolean>(false);

  const tableRef = createRef<TTablePageRef>();

  const { data: oneOffEvents, error, loading } = useUpcomingOneOffEvents();

  const {
    companyEventsFilteredByDate,
    filteredCompanies,
    filterByCompany,
    setCompanyFilter,
    companyFilter,
    months,
    filterByMonth,
    setMonthFilter,
    monthFilter,
  } = useEventsFilters({ events: oneOffEvents });

  const [eventTypeFilter, setEventTypeFilter] = useState<TEventTypes>();

  const filterByEventType = useCallback(
    (e: (typeof oneOffEvents)[number]) => {
      return !(eventTypeFilter && e.type !== eventTypeFilter);
    },
    [eventTypeFilter],
  );

  const [officeFilter, setOfficeFilter] = useState<string>();

  const setCompany = useCallback(
    (companyId: any) => {
      setOfficeFilter(undefined);
      setCompanyFilter(companyId ? companyId?.toString() : undefined);
    },
    [setCompanyFilter],
  );

  const setMonth = useCallback(
    (month: any) => {
      setOfficeFilter(undefined);
      setMonthFilter(month ? month?.toString() : undefined);
    },
    [setMonthFilter],
  );

  const setOffice = useCallback((officeId: any) => {
    setOfficeFilter(officeId ? officeId?.toString() : undefined);
  }, []);

  const [skipEvent] = useSkipEventMutation();
  const [createEventGift] = useCreateEventGiftMutation();

  const filterByOffice = useCallback(
    (e: (typeof oneOffEvents)[number]) => {
      if (officeFilter && e.employee?.office?.id != officeFilter) return false;
      return true;
    },
    [officeFilter],
  );

  const offices = useMemo(() => {
    return _.uniq(
      _.compact(
        oneOffEvents
          .filter((e) => {
            return filterByMonth(e) && filterByCompany(e);
          })
          .map((e) => e.employee?.office),
      ),
    );
  }, [filterByCompany, filterByMonth, oneOffEvents]);

  const filteredOneOffEvents: typeof oneOffEvents = useMemo(() => {
    return oneOffEvents.filter((e) => {
      return filterByCompany(e) && filterByOffice(e) && filterByMonth(e) && filterByEventType(e);
    });
  }, [filterByCompany, filterByMonth, filterByOffice, oneOffEvents, filterByEventType]);

  const skipEvents = useCallback(() => {
    setSkipLoading(true);
    return Promise.all(
      (selectedRows || []).map((id) => {
        return new Promise((resolve, reject) => {
          const event = oneOffEvents.find((e) => e.id === id);

          if (event == null) return resolve(undefined);

          skipEvent({
            variables: {
              id: event.id,
              companyId: event.companyId,
              version: event._version,
            },
          })
            .then(resolve)
            .catch(reject);
        });
      }),
    )
      .then()
      .then(() => {
        tableRef.current?.resetConfirmationModal();
        setSkipLoading(false);
        setSelectedRows(undefined);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oneOffEvents, selectedRows, skipEvent, tableRef, tableRef.current]);

  const createGifts = useCallback(() => {
    setCreateGiftsLoading(true);

    let group: string | undefined;
    if ((selectedRows || []).length > 1) {
      group = uuidv4();
    }

    const selectedEvents = selectedRows?.map((v) => oneOffEvents.find((e) => e.id == v));
    const groupedEvents = _.groupBy(selectedEvents, (e) => e?.employee?.officeId ?? e?.addressId);

    return Promise.all(
      Object.keys(groupedEvents).map((officeId) => {
        group = uuidv4();
        const events = groupedEvents[officeId];

        return Promise.all(
          events.map((event) => {
            return new Promise((resolve, reject) => {
              if (event == null) return resolve(undefined);

              const variables = {
                eventId: event.id,
                companyId: event.companyId,
                eventVersion: event._version,
                group,
              };

              return createEventGift({
                variables,
              })
                .then(resolve)
                .catch(reject);
            });
          }),
        );
      }),
    )
      .then()
      .then(() => {
        tableRef.current?.resetConfirmationModal();
        setCreateGiftsLoading(false);
        setSelectedRows(undefined);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createEventGift, oneOffEvents, selectedRows, tableRef, tableRef.current]);

  const onSkipEventsClick = useCallback(() => {
    tableRef.current?.setConfirmationProps({
      headline: `Skip ${selectedRows?.length} Events?`,
      supportingText: `Are you sure you want to skip these ${selectedRows?.length} events?`,
      confirmLabel: 'Skip',
      onConfirmClick: skipEvents,
      open: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows?.length, skipEvents, tableRef, tableRef.current]);

  const onCreateGiftsClick = useCallback(() => {
    tableRef.current?.setConfirmationProps({
      headline: `Create ${selectedRows?.length} Gifts?`,
      supportingText: `Are you sure you want to create these ${selectedRows?.length} gifts?`,
      confirmLabel: 'Create',
      onConfirmClick: createGifts,
      open: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createGifts, selectedRows?.length, tableRef, tableRef.current]);

  const onRowSelectionChange = useCallback((rowSelection: RowSelectionState) => {
    console.log('selectedRows?:', rowSelection);
    setSelectedRows(Object.keys(rowSelection));
  }, []);

  const getRowId = useCallback((event: (typeof oneOffEvents)[number]) => {
    return event.id;
  }, []);

  const getRowClassName = useCallback(
    (index: number) => {
      const event = filteredOneOffEvents[index];
      if (event.address == null) return styles.noAddressRow;
    },
    [filteredOneOffEvents],
  );

  const filters = useMemo(() => {
    const filtersList: TFiltersProps['filters'] = [
      {
        type: 'singleSelect',
        label: 'Date',
        name: 'date',
        onChange: setMonth,
        options: months.map((m) => ({ label: readableDate(m, 'MMM, yyyy') as string, value: m })),
        value: monthFilter,
      },
    ];

    filtersList.push({
      type: 'singleSelect',
      label: 'Company',
      name: 'companyId',
      onChange: setCompany,
      value: companyFilter,
      options: filteredCompanies.map((c) => ({
        label: `${c.name} (${companyEventsFilteredByDate[c.id]})`,
        value: c.id,
      })),
    });

    if (companyFilter && offices.length > 0) {
      filtersList.push({
        type: 'singleSelect',

        label: 'Office',
        name: 'office',
        onChange: setOffice,
        options: offices.map((o) => ({ label: o.name, value: o.id })),
        value: officeFilter,
      });
    }

    filtersList.push({
      type: 'singleSelect',
      label: 'Event Type',
      name: 'eventType',
      onChange: (v) => {
        if (v == null) setEventTypeFilter(undefined);
        else setEventTypeFilter(v as TEventTypes);
      },
      options: Object.entries(ONE_OFF_EVENT_TYPES).map(([key, value]) => ({ label: value, value: key })),
      value: eventTypeFilter,
    });

    return filtersList;
  }, [
    filteredCompanies,
    companyFilter,
    monthFilter,
    months,
    officeFilter,
    offices,
    setCompany,
    setMonth,
    setOffice,
    companyEventsFilteredByDate,
    eventTypeFilter,
  ]);

  return (
    <TablePage
      actionsLoading={createGiftsLoading || skipLoading}
      dataLoading={loading}
      error={error}
      ref={tableRef}
      title='Upcoming One-Off Events'
    >
      <Filters filters={filters} />
      <TableInner<(typeof oneOffEvents)[number]>
        enableSorting={true}
        data={filteredOneOffEvents}
        emptyText={
          oneOffEvents.length === 0
            ? 'No upcoming one-off events.'
            : 'No upcoming one-off events for the current filters.'
        }
        expandable={true}
        fillSelectedRows={true}
        fixedHeader={true}
        forceExpanded={true}
        getRowId={getRowId}
        getRowClassName={getRowClassName}
        hideSubRowsSelect={true}
        hoverableRows={!skipLoading && !createGiftsLoading}
        onRowSelectionChange={onRowSelectionChange}
        selectableRows={true}
        selectingChildSelectsFather={true}
        virtual={true}
        columns={[
          {
            header: 'Company Name',
            accessorKey: 'company.name',
            cell: ({ cell }) => {
              return (
                <div className={clsx(styles.companyName, tableStyles.tableSpacePrimary)}>
                  {cell.row.original.wasPaused && (
                    <Tooltip message='This gift was paused.'>
                      <Icon icon='pause_circle' />
                    </Tooltip>
                  )}
                  <span>{cell.row.original.company?.name}</span>
                </div>
              );
            },
          },
          {
            header: 'Recipient Name',
            accessorKey: 'recipient.fullName',
            cell: ({ cell }) => {
              let text = '';
              if (cell.row.original.employee?.pausePeriod) {
                const { fromDate, toDate } = cell.row.original.employee.pausePeriod;
                if (fromDate) text += ` since ${readableDate(fromDate)}`;
                if (toDate) text += ` to ${readableDate(toDate)}`;
              }
              return (
                <div className={clsx(styles.companyName, tableStyles.tableSpacePrimary)}>
                  {cell.row.original.employee?.isPauseEnabled && (
                    <Tooltip message={`Employee paused${text}.`}>
                      <Icon icon='pause_circle' />
                    </Tooltip>
                  )}
                  <span>{cell.row.original.recipient?.fullName}</span>
                </div>
              );
            },
          },
          {
            header: 'Event Type',
            accessorKey: 'type',
            cell: ({ cell }) => {
              return (
                <div className={clsx(tableStyles.tableSpaceTertiary)}>
                  <span>
                    {cell.row.original.type === TEventTypes.Other
                      ? cell.row.original.customType
                      : translateEventType(cell.row.original.type)}
                  </span>
                </div>
              );
            },
          },
          {
            header: 'Event Date',
            accessorKey: 'eventDate',
            cell: ({ cell }) => {
              return (
                <div className={clsx(tableStyles.tableSpaceTertiary)}>
                  <span>{readableDate(cell.row.original.eventDate)}</span>
                </div>
              );
            },
          },
          {
            header: 'Office',
            accessorKey: 'office.name',
            cell: ({ cell }) => {
              return (
                <div className={clsx(tableStyles.tableSpaceSecondary)}>
                  <span>{cell.row.original.employee?.office?.name || '-'}</span>
                </div>
              );
            },
          },
          {
            header: 'Country',
            accessorKey: 'address.country',
            cell: ({ cell }) => {
              if (cell.row.original.address != null) {
                return (
                  <div className={clsx(tableStyles.tableSpacePrimary)}>
                    <CountryName name={cell.row.original.address.country || undefined} />
                  </div>
                );
              } else {
                return (
                  <div className={clsx(styles.missingAddress, tableStyles.tableSpacePrimary)}>
                    <Icon icon='warning' />
                    <span>Missing address</span>
                  </div>
                );
              }
            },
          },
        ]}
      />
      {selectedRows && selectedRows.length > 0 && (
        <FloatingActions>
          <ExtendedFAB
            disabled={createGiftsLoading}
            label={`Skip ${selectedRows.length} Events`}
            leadingIcon='exit_to_app'
            loading={skipLoading}
            onClick={onSkipEventsClick}
            variant='tertiary'
          />
          <ExtendedFAB
            disabled={skipLoading}
            label={`Create ${selectedRows.length} Gifts`}
            leadingIcon='add'
            loading={createGiftsLoading}
            onClick={onCreateGiftsClick}
            variant='surface'
          />
        </FloatingActions>
      )}
    </TablePage>
  );
};
