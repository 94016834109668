import { TProduct, TProductFieldsFragment, useDeleteProductMutation } from '../../../../generated/graphql';
import {
  ConfirmationModal,
  Icon,
  LoadingPage,
  TableInner,
  TConfirmationModalProps,
  Tooltip,
} from '@chocolate-soup-inc/cs-frontend-components';
import { TablePage } from '../../../../components/tablePage/TablePage';
import clsx from 'clsx';
import tableStyles from '../../../../components/tablePage/TablePage.module.scss';
import { useQueryAllProducts } from '../../../../entities/products/queries';
import styles from './GiftProductList.module.scss';
import { CountryName } from '../../../../components/coutryName/CountryName';
import _ from 'lodash';
import { useCallback, useState } from 'react';
import { serializeError } from 'serialize-error';
import { toast } from 'react-toastify';
import { CellContext } from '@tanstack/react-table';
import { generatePath, useNavigate } from 'react-router-dom';
import { EDIT_PATH, GIFT_PRODUCTS_LIST_PATH, PRODUCT_PATH } from '../../../../routes/paths';

const CellOptions = (
  props: CellContext<TProduct, unknown> & {
    setConfirmationProps: (v: any) => void;
    setActionLoading: (v: boolean) => void;
  },
) => {
  const { cell, setConfirmationProps, setActionLoading } = props;
  const navigate = useNavigate();

  const {
    id: selectedProductId,
    _version: selectedProductVersion,
    description: selectedProductDescription,
  } = cell.row.original;

  const [deleteProduct, { loading: deleteLoading }] = useDeleteProductMutation();

  const onActualDelete = useCallback(() => {
    setActionLoading(true);
    if (!selectedProductId || !selectedProductVersion) return;
    return deleteProduct({
      variables: {
        id: selectedProductId,
        version: selectedProductVersion,
      },
    })
      .then(() => {
        toast.success('Product deleted successfully.', { autoClose: 3000 });
      })
      .catch((error) => {
        console.error(serializeError(error));
        toast.error('There was an error deleting the product.');
      })
      .then(() => {
        setConfirmationProps(undefined);
        setActionLoading(false);
      });
  }, [deleteProduct, setConfirmationProps, setActionLoading, selectedProductId, selectedProductVersion]);

  const onDeleteClick = useCallback(() => {
    setConfirmationProps({
      headline: 'Delete Product?',
      supportingText: `Are you sure you want to delete "${selectedProductDescription}"?`,
      confirmLabel: 'Delete',
      onConfirmClick: onActualDelete,
    });
  }, [onActualDelete, selectedProductDescription, setConfirmationProps]);

  return (
    <div className={clsx(tableStyles.tableSpaceTertiary)}>
      <Tooltip message={`Edit "${cell.row.original.description}"`}>
        <Icon
          icon='edit'
          className={clsx(styles.actionIcon)}
          onClick={() =>
            navigate(
              generatePath(`${GIFT_PRODUCTS_LIST_PATH}/${PRODUCT_PATH}/${EDIT_PATH}`, {
                productId: selectedProductId,
              }),
            )
          }
        />
      </Tooltip>
      <Tooltip message={`Delete "${cell.row.original.description}"`}>
        <Icon
          icon='delete'
          className={clsx(styles.actionIcon)}
          onClick={() => {
            if (deleteLoading) return;
            onDeleteClick();
          }}
        />
      </Tooltip>
    </div>
  );
};
export const GiftProductList = () => {
  const { data: products, loading, error } = useQueryAllProducts();
  const [actionLoading, setActionLoading] = useState<boolean>();

  const [confirmationProps, setConfirmationProps] =
    useState<Omit<TConfirmationModalProps, 'closeModal' | 'confirmLoading' | 'onCancelClick'>>();

  return (
    <TablePage dataLoading={loading} error={error} title='Products'>
      {loading && <LoadingPage />}
      {!loading && (
        <>
          {!_.isEmpty(confirmationProps) && (
            <ConfirmationModal
              {...confirmationProps}
              closeModal={() => setConfirmationProps(undefined)}
              confirmLoading={actionLoading}
              onCancelClick={() => setConfirmationProps(undefined)}
            />
          )}
        </>
      )}

      <TableInner<TProductFieldsFragment>
        enableSorting={true}
        columns={[
          {
            header: 'HS Code',
            accessorKey: 'hsCode',
            cell: ({ cell }) => {
              return (
                <div className={clsx(tableStyles.tableSpaceTertiary)}>
                  <span>{cell.row.original.hsCode}</span>
                </div>
              );
            },
          },
          {
            header: 'Description',
            accessorKey: 'description',
            cell: ({ cell }) => {
              return (
                <div className={clsx(tableStyles.tableSpacePrimary)}>
                  <span>{cell.row.original.description}</span>
                </div>
              );
            },
          },
          {
            header: 'Value',
            accessorKey: 'value',
            cell: ({ cell }) => {
              return (
                <div className={clsx(tableStyles.tableSpaceTertiary)}>
                  <span>${cell.row.original.value} CAD</span>
                </div>
              );
            },
          },
          {
            header: 'Quantity',
            accessorKey: 'quantity',
            cell: ({ cell }) => {
              return (
                <div className={clsx(tableStyles.tableSpaceTertiary)}>
                  <span>{cell.row.original.quantity}</span>
                </div>
              );
            },
          },
          {
            header: 'Country',
            accessorKey: 'country',
            cell: ({ cell }) => {
              return (
                <div className={clsx(tableStyles.tableSpaceTertiary)}>
                  <CountryName name={cell.row.original.country || undefined} />
                </div>
              );
            },
          },
          {
            header: 'Actions',
            cell: (cell) => (
              <CellOptions {...cell} setConfirmationProps={setConfirmationProps} setActionLoading={setActionLoading} />
            ),
          },
        ]}
        data={products}
        emptyText='No products were found.'
        hoverableRows={true}
        virtual={true}
      />
    </TablePage>
  );
};
