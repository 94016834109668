import { CommonButton, DatePicker, Loading, Modal } from '@chocolate-soup-inc/cs-frontend-components';
import { useCallback, useMemo, useState } from 'react';

import clsx from 'clsx';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { TShipmentType, TShipmentTypeGroupable } from '../../../entities/shipments/queries';
import {
  useAddGiftsToShipmentMutation,
  useDeleteShipmentMutation,
  useUpdateShipmentMutation,
} from '../../../generated/graphql';
import { GroupedModalCard } from './GroupedModalCard';
import styles from './SetAsReadyToShipGropuedModal.module.scss';

export type TSetAsReadyToShipGropuedModalProps = {
  shipments: TShipmentType[];
  onCancel: () => void;
  onSuccess: () => void;
};

export const SetAsReadyToShipGropuedModal = (props: TSetAsReadyToShipGropuedModalProps) => {
  const { shipments, onCancel, onSuccess } = props;

  const [shipmentsGropued, setShipmentsGropued] = useState<TShipmentTypeGroupable[] | undefined>(shipments);
  const [shippingDate, setShippingDate] = useState<Date>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [updateShipment] = useUpdateShipmentMutation();
  const [addGiftsToShipment] = useAddGiftsToShipmentMutation();
  const [deleteShipment] = useDeleteShipmentMutation();

  const headline = useMemo(() => {
    return `Group Shipments`;
  }, []);

  const supportingText = useMemo(() => {
    return `Please set a Shipping Date. All Shipments will be removed. Their Gifts will be added to a New Shipment going to this address.`;
  }, []);

  const onSubmit = useCallback(async () => {
    if (!shippingDate) return toast.error('There no Shipping Date set.');

    try {
      setIsLoading(true);
      const allShipments = shipmentsGropued?.flatMap((shipment) => ({
        id: shipment.id,
        companyId: shipment.companyId,
        version: shipment._version,
      }));

      const remainingShipment = allShipments?.[0];

      allShipments?.shift();

      const allGifts = (shipmentsGropued || []).flatMap((shipment) => {
        return (shipment.gifts || []).map((gift) => gift.id);
      });

      await updateShipment({
        variables: {
          id: remainingShipment?.id as string,
          companyId: remainingShipment?.companyId as string,
          version: remainingShipment?.version as number,
          input: {
            shippingDate: format(shippingDate, 'yyyy-MM-dd'),
          },
        },
      });

      await addGiftsToShipment({
        variables: {
          companyId: remainingShipment?.companyId as string,
          shipmentId: remainingShipment?.id as string,
          giftsIds: allGifts,
        },
      });

      (allShipments || []).map(async (shipment) => {
        return await deleteShipment({
          variables: {
            id: shipment?.id as string,
            companyId: shipment?.companyId as string,
            version: shipment?.version as number,
          },
        });
      });

      setIsLoading(false);
      onSuccess();
    } catch (e) {
      console.warn('onSubmit', e);
      toast.error('There was an error: ' + e);
    }
  }, [shipmentsGropued, updateShipment, shippingDate, addGiftsToShipment, deleteShipment, onSuccess]);

  const bottomComponent = useCallback(() => {
    if (!shipments) return <></>;
    return (
      <div className={styles.topComponent}>
        <CommonButton
          className={clsx(styles.addBtn)}
          leadingIcon='close'
          label='Cancel'
          disabled={isLoading}
          onClick={onCancel}
          variant='outlined'
        />
        <CommonButton
          className={clsx(styles.addBtn)}
          leadingIcon='check'
          label='Group'
          disabled={isLoading}
          onClick={onSubmit}
          variant='filled'
        />
        {isLoading && <Loading className={clsx(styles.loading)} />}
      </div>
    );
  }, [shipments, onCancel, onSubmit, isLoading]);

  const removeShipmentGroupable = (id: string) => {
    setShipmentsGropued((prev) => prev?.filter((shipment) => shipment.id !== id));
  };

  return (
    <Modal
      headline={headline}
      size='large'
      supportingText={supportingText}
      closeModal={onCancel}
      showCustomButton={true}
      customButton={bottomComponent}
    >
      <div className={clsx(styles.containerCards)}>
        <DatePicker
          label='Shipping Date'
          name='shippingDate'
          className={clsx(styles.datePicker)}
          value={shippingDate}
          onChange={(v) => setShippingDate(v as Date)}
        />
        <div className={clsx(styles.rows)}>
          {shipmentsGropued &&
            shipmentsGropued.map((shipment) => (
              <div key={shipment.id} className={clsx(styles.items)}>
                <GroupedModalCard shipment={shipment} removeShipment={() => removeShipmentGroupable(shipment.id)} />
              </div>
            ))}
        </div>
      </div>
    </Modal>
  );
};
