import {
  Card,
  Chip,
  CountryAvatar,
  getCountryName,
  getDateWithTimezoneFixed,
  Icon,
  IconButton,
  Tooltip,
} from '@chocolate-soup-inc/cs-frontend-components';
import clsx from 'clsx';
import { format } from 'date-fns';
import { useMemo } from 'react';
import { AlertsTooltip } from '../../../components/alerts/AlertsTooltip';
import { TShipmentTypeGroupable } from '../../../entities/shipments/queries';
import { useShipmentAddressInfo } from '../../../entities/shipments/shared';
import { TAlert, TShipStationAddressVerified } from '../../../generated/graphql';
import styles from '../ShipmentCard/ShipmentCard.module.scss';

type TShipmentModalCardProps = {
  shipment: TShipmentTypeGroupable;
  removeShipment: () => void;
};

export const GroupedModalCard = (props: TShipmentModalCardProps) => {
  const { shipment, removeShipment } = props;

  const addressVerified = useMemo(() => {
    return shipment.addressVerified;
  }, [shipment?.addressVerified]);

  const employeeIsDeleted = useMemo(() => {
    return shipment?.addressId.includes(process.env.REACT_APP_EMPLOYEES_TABLE_NAME as string) && !shipment?.employee;
  }, [shipment]);

  const { addressTypeIcon, readableAddressType, recipientName } = useShipmentAddressInfo(shipment);

  const countryName = useMemo(() => {
    return getCountryName(shipment.address?.country || undefined);
  }, [shipment.address?.country]);

  const allShipmentAlertsHaveBeenDismissed = useMemo(() => {
    if (!shipment.alerts) return true;
    return shipment.alerts?.every((a) => {
      return !!a?.acknowledgedAt;
    });
  }, [shipment.alerts]);

  return (
    <div>
      <Card
        className={clsx(
          styles.card,
          (!allShipmentAlertsHaveBeenDismissed || !recipientName) && styles.alert,
          employeeIsDeleted && styles.deletedEmployee,
        )}
        variant='outlined'
      >
        <>
          <div className={styles.icons}>
            <div className={styles.options}>
              <div className={styles.alertIcons}>
                {shipment.label?.isThirdParty && (
                  <Tooltip message='Third party billed shipment.'>
                    <Icon className={styles.addressVerifiedSuccess} icon='output' />
                  </Tooltip>
                )}
                <AlertsTooltip alerts={shipment.alerts as TAlert[]} />
                {shipment.groupable && (
                  <Tooltip message='This shipment can be combined.'>
                    <Icon className={styles.addressVerifiedSuccess} icon='view_compact_alt' />
                  </Tooltip>
                )}
                {addressVerified === TShipStationAddressVerified.Success && (
                  <Tooltip message='ShipStation address verification succeeded.'>
                    <Icon className={styles.addressVerifiedSuccess} icon='check_circle' />
                  </Tooltip>
                )}
                {addressVerified === TShipStationAddressVerified.Failed && (
                  <Tooltip message='ShipStation address verification failed.'>
                    <Icon className={styles.addressVerifiedFail} icon='error_circle_rounded' />
                  </Tooltip>
                )}
                {addressVerified === TShipStationAddressVerified.Warning && (
                  <Tooltip message='ShipStation address verification warning.'>
                    <Icon className={styles.addressVerifiedWarning} icon='warning' />
                  </Tooltip>
                )}
              </div>
              <Tooltip message='Ungroup'>
                <IconButton icon='close' onClick={removeShipment} variant='standard' />
              </Tooltip>
            </div>
          </div>
          <div className={styles.country}>
            {readableAddressType && addressTypeIcon && shipment.address?.country && countryName && (
              <Tooltip autoPlacement={false} message={countryName} placement='bottom'>
                <CountryAvatar elevated={true} size='medium' code={shipment.address?.country} />
              </Tooltip>
            )}
          </div>
          <span className={styles.companyName}>{shipment.company?.name}</span>
          <span className={styles.recipientName}>{recipientName}</span>
          <div className={styles.tags}>
            <Tooltip autoPlacement={false} message='Delivery Date' placement='bottom'>
              <Chip
                innerContainerClassName={styles.chip}
                leadingIcon='local_shipping'
                leadingIconProps={{
                  filled: true,
                }}
                label={format(
                  getDateWithTimezoneFixed(shipment.actualShippingDate || shipment.shippingDate || undefined) as Date,
                  'dd/MM/yyyy',
                )}
                readonly={true}
                variant='input'
              />
            </Tooltip>
            <Tooltip autoPlacement={false} message='Address Type' placement='bottom'>
              <Chip
                innerContainerClassName={styles.chip}
                leadingIcon={addressTypeIcon}
                leadingIconProps={{
                  filled: true,
                }}
                label={readableAddressType || 'unknown'}
                readonly={true}
                variant='input'
              />
            </Tooltip>
            {shipment.gifts && shipment?.gifts.length > 0 && (
              <Tooltip autoPlacement={false} message='Gift count' placement='bottom'>
                <Chip
                  innerContainerClassName={styles.chip}
                  leadingIcon='card_giftcard'
                  label={`${shipment.gifts.length} Gift${shipment.gifts.length > 1 ? 's' : ''}`}
                  readonly={true}
                  variant='input'
                />
              </Tooltip>
            )}
          </div>
        </>
      </Card>
    </div>
  );
};
