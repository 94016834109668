import Joi from 'joi';
import { TEmployeeWithDependantsFieldsFragment } from '../../generated/graphql';
import { TOfficeType } from '../office/queries';
import { clearableField } from '../shared/clearableField';
import countryExtension from '../shared/countryExtension';
import { AWSDateSchema } from '../shared/utils';
import { TEmployeeType } from './queries';

type TSchemaProps = {
  offices?: TOfficeType[];
  companyEmployeesAndDependants?: TEmployeeType<TEmployeeWithDependantsFieldsFragment>[];
};

export const schema = (props: TSchemaProps) => {
  const { offices, companyEmployeesAndDependants } = props;

  let employeeSchema = Joi.object()
    .unknown(true)
    .or('firstName', 'preferredFirstName')
    .keys({
      externalId: Joi.alternatives(
        Joi.string().trim().empty(Joi.valid('', null)),
        Joi.number().empty(Joi.valid('', null)),
      ),
      firstName: clearableField(Joi.string().trim()),
      preferredFirstName: clearableField(Joi.string().trim()),
      lastName: clearableField(Joi.string().trim()),
      hireDate: clearableField(AWSDateSchema),
      birthDate: clearableField(AWSDateSchema),
      email: clearableField(
        Joi.string()
          .trim()
          .lowercase()
          .email({
            tlds: {
              allow: false,
            },
          }),
      ),
      phoneNumber: clearableField(Joi.alternatives().try(Joi.string().trim(), Joi.number().cast('string'))),
      foodPreferences: clearableField(Joi.string().trim()),
      customOnboardBrick: clearableField(Joi.string().trim()),
      title: clearableField(Joi.string().lowercase().trim()),
      organization: clearableField(Joi.string().lowercase().trim()),
      tShirtSize: clearableField(Joi.string().trim()),
      sweaterSize: clearableField(Joi.string().trim()),
      donateBirthdayGift: Joi.boolean().empty(Joi.valid(null, '')),
      donateWorkAnniversaryGift: Joi.boolean().empty(Joi.valid(null, '')),
      address: Joi.object().keys({
        address1: clearableField(Joi.string().trim().uppercase()),
        address2: clearableField(Joi.alternatives().try(Joi.number().cast('string'), Joi.string().trim().uppercase())),
        city: clearableField(Joi.string().trim().uppercase()),
        state: clearableField(Joi.string().trim().uppercase()),
        zipCode: clearableField(
          Joi.alternatives().try(
            Joi.number().cast('string'),
            Joi.string().trim().uppercase().empty(Joi.valid(null, '')),
          ),
        ),
        country: clearableField(Joi.extend(countryExtension).country().trim().uppercase().min(3).max(3)),
      }),
      paused: clearableField(Joi.alternatives().try(Joi.number().cast('string'), Joi.string().trim())),
    });

  // if (company.employeeNewHireKitActivated || company.employeeWorkAnniversaryActivated) {
  //   employeeSchema = employeeSchema.fork('hireDate', (s) => s.required());
  // }

  // if (company.employeeBirthdayActivated) {
  //   employeeSchema = employeeSchema.fork('birthDate', (s) => s.required());
  // }

  if (companyEmployeesAndDependants != null && companyEmployeesAndDependants.length > 0) {
    employeeSchema = employeeSchema.fork(['externalId'], (s) => s.required());
  }

  if (offices && Array.isArray(offices) && offices.length > 0) {
    employeeSchema = employeeSchema.keys({
      officeId: clearableField(
        Joi.valid(...offices.map((o) => o.id))
          .allow(null)
          .optional(),
      ),
    });
  }

  return employeeSchema;
};
