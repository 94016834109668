import Joi from 'joi';
import _ from 'lodash';
import { TWorkAnniversaryGiftVariations } from '../../generated/graphql';
import { getAddressDefaultValues, getAddressSchema } from '../addresses/schema';
import { getContactDefaultValues, getContactSchema } from '../contact/schema';
import { clearableField } from '../shared/clearableField';
import { AWSDateSchema } from '../shared/utils';
import { TCompanyType } from './queries';

export const getCompanySchema = () => {
  const companySchema = Joi.object()
    .unknown(false)
    .custom((value, helpers) => {
      if (!(value?.googleLoginEnabled || value?.passwordLoginEnabled)) {
        // throw new Error('At least one login method is required.');
        return helpers.error('loginMethodMissing');
      }

      return value;
    })
    .custom((value, helpers) => {
      if (
        !(
          value?.employeeBirthdayActivated ||
          value?.employeeWorkAnniversaryActivated ||
          value?.employeeNewHireKitActivated ||
          value?.significantOtherBirthdayActivated ||
          value?.childBirthdayActivated ||
          value?.petBirthdayActivated
        )
      ) {
        // throw new Error('At least one gift type is required.');
        return helpers.error('giftTypesMissing');
      }

      return value;
    })
    .messages({
      loginMethodMissing: 'At least one login method is required.',
      giftTypesMissing: 'At least one gift type is required.',
    })
    .keys({
      id: Joi.forbidden(),
      customGiftConfiguration: Joi.forbidden(),
      rosterId: Joi.string().trim(),
      active: Joi.boolean().default(true).required(),
      isLive: clearableField(Joi.boolean().default(false).required()),
      name: Joi.string().trim().required(),
      deliveryMethod: Joi.string().trim().required().valid('home', 'hybrid', 'office'),
      accountManager: clearableField(Joi.string().trim()),
      lastRosterUpload: clearableField(Joi.string().trim()),
      subdomain: Joi.string()
        .trim()
        .lowercase()
        .disallow('auth', 'admin', 'client', 'internal')
        .required()
        .regex(/^[A-z0-9\-_]+$/),
      emailDomains: Joi.array()
        .items(
          Joi.string()
            .trim()
            .lowercase()
            .regex(/^[A-z0-9.\-_]+$/),
        )
        .min(1)
        .required(),
      googleLoginEnabled: Joi.boolean().required().default(false),
      passwordLoginEnabled: Joi.boolean().required().default(false),
      legoColor: Joi.string().trim().lowercase().empty(Joi.valid(null, '')).optional(),
      tissueColor: Joi.string().trim().lowercase().required(),
      signAs: Joi.string().trim().empty(Joi.valid(null, '')).optional(),
      specialNote: Joi.string().trim().empty(Joi.valid(null, '')).optional(),
      swag: Joi.string().trim().empty(Joi.valid(null, '')).optional(),
      startDate: AWSDateSchema.empty(Joi.valid(null, '')).required(),
      endDate: AWSDateSchema.empty(Joi.valid(null, '')).optional(),
      employeeBirthdayActivated: Joi.boolean().required().default(false),
      employeeWorkAnniversaryActivated: Joi.boolean().required().default(false),
      employeeNewHireKitActivated: Joi.boolean().required().default(false),
      significantOtherBirthdayActivated: Joi.boolean().required().default(false),
      childBirthdayActivated: Joi.boolean().required().default(false),
      petBirthdayActivated: Joi.boolean().required().default(false),
      workAnniversaryGiftVariation: Joi.string()
        .trim()
        .valid('deluxe', 'mini', 'brickOnly', 'custom')
        .default('deluxe'),
      customWorkAnniversaryNotes: clearableField(Joi.string().trim()),
      hasCustomOnboardBrick: clearableField(Joi.boolean().default(false)),
      importFromBambooHR: Joi.boolean().required().default(false),
      bambooHRSubdomain: Joi.string().trim().when('importFromBambooHR', {
        is: true,
        then: Joi.required(),
        otherwise: Joi.forbidden(),
      }),
      bambooHRAPIKey: Joi.string().trim().when('importFromBambooHR', {
        is: true,
        then: Joi.required(),
        otherwise: Joi.forbidden(),
      }),
      bambooHRPetsTableName: Joi.string().trim().when('importFromBambooHR', {
        is: true,
        otherwise: Joi.forbidden(),
      }),
      address: getAddressSchema(),
      subscriptionPricePerUser: clearableField(Joi.number()),
      subscriptionPriceCurrency: clearableField(Joi.string().trim()),
      contact: getContactSchema(),
      customShippingConfiguration: clearableField(
        Joi.array().items(
          Joi.object().keys({
            carrier: Joi.string().trim(),
            accountNumber: Joi.string().trim(),
            postalCode: Joi.string().trim(),
            countryCode: Joi.string().trim(),
          }),
        ),
      ),
    });

  return companySchema;
};

export const defaultValues = {
  // name: 'Chocolate Soup',
  // deliveryMethod: 'home' as TDeliveryMethod,
  // subdomain: 'chocolatesoup',
  // emailDomains: ['chocolatesoup.ca', 'qa.chocolatesoup.ca'],
  passwordLoginEnabled: true,
  googleLoginEnabled: true,
  childBirthdayActivated: false,
  employeeBirthdayActivated: false,
  employeeNewHireKitActivated: false,
  employeeWorkAnniversaryActivated: false,
  petBirthdayActivated: false,
  significantOtherBirthdayActivated: false,
  // legoColor: 'Green',
  // tissueColor: 'Green',

  active: true,
  isLive: false,
  // childBirthdayActivated: false,
  // employeeBirthdayActivated: false,
  // employeeNewHireKitActivated: false,
  // employeeWorkAnniversaryActivated: false,
  // googleLoginEnabled: false,
  importFromBambooHR: false,
  // passwordLoginEnabled: true,
  // petBirthdayActivated: false,
  // significantOtherBirthdayActivated: false,
  workAnniversaryGiftVariation: TWorkAnniversaryGiftVariations.Deluxe,
};

export const getCompanyDefaultValues = (company?: TCompanyType) => {
  return {
    ...defaultValues,
    ..._.omit(company || {}, [
      'id',
      'rosterId',
      'bambooHRSubdomain',
      'bambooHRAPIKey',
      'bambooHRPetsTableName',
      '__typename',
      'logo',
      '_version',
      '_deleted',
      'fullyConfigured',
      'customGiftConfiguration',
      'hydratedProducts',
      'employees',
      'configuration',
    ]),
    customShippingConfiguration: company?.customShippingConfiguration?.map((config) => _.omit(config, ['__typename'])),
    address: getAddressDefaultValues(company?.address),
    contact: getContactDefaultValues(company?.contact),
  };
};
