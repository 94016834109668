import { subscribe, TConnectionItem } from '@chocolate-soup-inc/cs-api-consumer-utils';
import _ from 'lodash';
import { useEffect, useMemo } from 'react';
import { serializeError } from 'serialize-error';
import { client } from '../../config/apollo/api';
import { onSubscriptionData } from '../../config/apollo/cache';
import {
  GetGiftPackageDocument,
  GiftPackageFieldsFragmentDoc,
  OnAnyGiftPackageChangedDocument,
  TGetGiftPackageQuery,
  TGetGiftPackageQueryVariables,
  TGiftPackage,
  TGiftPackageFieldsFragment,
  TListGiftPackagesQuery,
  TListGiftPackagesQueryVariables,
  TOnAnyGiftPackageChangedSubscription,
  TOnAnyGiftPackageChangedSubscriptionVariables,
  useGetGiftPackageLazyQuery,
  useListGiftPackagesLazyQuery,
} from '../../generated/graphql';
import { TCuratedProduct, useAllProductsMap } from '../products/queries';
import { pickProductsMapDefaultValues } from '../products/shared';
import { useFragmentOrFetch } from '../shared/useFragmentOrFetch';
import { useQueryAll } from '../shared/useQueryAll';
import { normalizeString } from '../shared/utils';

export type TCuratedPackageType = Pick<TGiftPackage, 'id' | '_version' | 'products'>;

export type TDefaultPackageType =
  | 'default employee birthday'
  | 'default new hire kit'
  | 'default work ann large plan'
  | 'default work ann small plan'
  | 'default work ann brick only'
  | 'default spouse birthday'
  | 'default pet birthday dog'
  | 'default pet birthday cat'
  | 'default child birthday 1 yo'
  | 'default child birthday 2 yo'
  | 'default child birthday 3 yo'
  | 'default child birthday 4 yo'
  | 'default child birthday 5 yo'
  | 'default child birthday 6 yo'
  | 'default child birthday 7 yo'
  | 'default child birthday 8 yo'
  | 'default child birthday 9 yo'
  | 'default child birthday 10 to 14 yo'
  | 'default child birthday 15 to 18 yo';

export type TCustomsMappingType = Record<TDefaultPackageType, TCuratedProduct[]>;

const queryGiftPackageOnSubscriptionData = (
  data?: TOnAnyGiftPackageChangedSubscription,
  vars?: TOnAnyGiftPackageChangedSubscriptionVariables,
) => {
  const { id, _deleted } = data?.onAnyGiftPackageChanged || {};

  if (_deleted) {
    return onSubscriptionData({ ...data } as Record<string, any>, vars);
  } else if (id) {
    client
      .query<TGetGiftPackageQuery, TGetGiftPackageQueryVariables>({
        query: GetGiftPackageDocument,
        variables: {
          id,
        },
      })
      .then((response) => {
        const packageData = response?.data?.getGiftPackage as TConnectionItem;

        if (packageData) {
          const subscriptionData = {
            onAnyGiftPackageChanged: packageData as TConnectionItem,
          };

          // CHANGED DATA FOR QUERIES THAT DEPEND ON NO VARIABLE (SINCE VARS SHOULD BE AN EMPTY OBJECT IN THIS SITUATION)
          onSubscriptionData(subscriptionData, vars);
        }
      })
      .catch((error) => {
        console.error('Error', serializeError(error));
      });
  }
};

let anyGiftPackageChangedSubscribed = false;

export const useSubscribeToGiftPackageChanged = () => {
  useEffect(() => {
    if (!anyGiftPackageChangedSubscribed) {
      subscribe({
        client,
        query: OnAnyGiftPackageChangedDocument,
        onSubscriptionData: (data, vars) => {
          return queryGiftPackageOnSubscriptionData(
            data as TOnAnyGiftPackageChangedSubscription,
            vars as TOnAnyGiftPackageChangedSubscriptionVariables,
          );
        },
        variables: {},
      });
    }

    anyGiftPackageChangedSubscribed = true;
  }, []);
};

export const useFullGiftPackages = <T extends TGiftPackageFieldsFragment>(packages: T[]) => {
  const { data: productsMap, loading: productsLoading, error: productsError } = useAllProductsMap();

  const fullGiftPackages = useMemo(() => {
    return packages.map((p) => {
      const products = p.products.reduce(
        (agg, productId) => {
          agg[productId as string] = productsMap[productId as string];
          return agg;
        },
        {} as Record<string, any>,
      );

      return {
        ...p,
        products,
        productsQuantity: Object.keys(products).length,
      };
    });
  }, [productsMap, JSON.stringify(packages)]); //eslint-disable-line

  return {
    data: fullGiftPackages,
    loading: productsLoading,
    error: productsError,
  };
};

export type TGiftPackageFullType<T extends TGiftPackageFieldsFragment = TGiftPackageFieldsFragment> = ReturnType<
  typeof useFullGiftPackages<T>
>['data'][number];

export const useFragmentOrFetchGiftPackage = (variables: TGetGiftPackageQueryVariables) => {
  useSubscribeToGiftPackageChanged();

  const {
    data,
    error: queryError,
    loading: queryLoading,
  } = useFragmentOrFetch<TGiftPackageFieldsFragment, TGetGiftPackageQuery, TGetGiftPackageQueryVariables>({
    fragmentDoc: GiftPackageFieldsFragmentDoc,
    useLazyQuery: useGetGiftPackageLazyQuery,
    variables,
    __typename: 'GiftPackage',
  });

  const {
    data: giftPackages,
    loading: fullLoading,
    error: fullError,
  } = useFullGiftPackages(_.compact([data]) as TGiftPackageFieldsFragment[]);

  return {
    data: giftPackages[0],
    loading: queryLoading || fullLoading,
    error: queryError || fullError,
  };
};

export const useQueryAllGiftPackages = () => {
  useSubscribeToGiftPackageChanged();

  const {
    data,
    loading: queryLoading,
    error: queryError,
  } = useQueryAll<TListGiftPackagesQuery, TListGiftPackagesQueryVariables>({
    useQuery: useListGiftPackagesLazyQuery,
    variables: {},
  });

  const {
    data: giftPackages,
    loading: fullLoading,
    error: fullError,
  } = useFullGiftPackages(_.compact(_.orderBy(data?.listGiftPackages.items, ['name'], ['asc'])));

  return {
    data: giftPackages,
    loading: queryLoading || fullLoading,
    error: queryError || fullError,
  };
};

export const useQueryAllDefaultGiftPackages = () => {
  const { data, loading, error } = useQueryAllGiftPackages();

  const filteredDefaults = data.filter((pack) => normalizeString(pack.name).includes('default'));

  const mappedDefaults = filteredDefaults.map((pack) => ({
    ...pack,
    customsMapping: pickProductsMapDefaultValues(Object.values(pack.products), ['id']),
  }));

  return { data: mappedDefaults, loading, error };
};
