import { ApolloError } from '@apollo/client/errors';
import { DatePicker, Loading, Modal, Select, TextField } from '@chocolate-soup-inc/cs-frontend-components';
import { format } from 'date-fns';
import _ from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useQueryAllCompanies } from '../../../../entities/companies/queries';
import { useQueryAllEmployees } from '../../../../entities/employees/queries';
import { getEventDefaultValues } from '../../../../entities/events/schema';
import { ONE_OFF_EVENT_TYPES } from '../../../../entities/events/shared';
import { TEventTypes, useCreateOneOffEventMutation } from '../../../../generated/graphql';
import { EVENTS_PATH, ONE_OFF_EVENTS_PATH, UPCOMING_PATH } from '../../../../routes/paths';
import styles from './Form.module.scss';

export const EventCreateForm = () => {
  const [selectedCompanyId, setSelectedCompanyId] = useState<string | undefined>();
  const [selectedEmployeeIds, setSelectedEmployeeIds] = useState<string[] | undefined>();
  const [typeSelect, setTypeSelect] = useState<TEventTypes | undefined>();
  const [typeText, setTypeText] = useState<string | undefined>();
  const [selectedEventDate, setSelectedEventDate] = useState<Date | undefined>(new Date());

  const navigate = useNavigate();
  const [createOneOffEvent] = useCreateOneOffEventMutation();

  const { data: companies, loading: companiesLoading } = useQueryAllCompanies();
  const { data: employees, loading: employeesLoading } = useQueryAllEmployees();

  const companiesList = useMemo(
    () =>
      _.orderBy(
        companies
          ?.filter((company) => {
            const hasReachedEndDate =
              company.endDate && new Date(company.endDate as string).getTime() < new Date().getTime();
            return company.active && company.isLive && !hasReachedEndDate;
          })
          .map((company) => ({ label: company?.name, value: company?.id })),
        ['label'],
        ['asc'],
      ),
    [companies],
  );
  const employeesList = useMemo(
    () =>
      _.orderBy(
        employees
          ?.filter((employee) => employee?.companyId === selectedCompanyId && employee.active)
          ?.map((employee) => ({ label: employee?.fullName as string, value: employee?.id as string })),
        ['label'],
        ['asc'],
      ),
    [employees, selectedCompanyId],
  );
  const fullSelectedCompany = useMemo(
    () => companies?.find((company) => company.id === selectedCompanyId),
    [companies, selectedCompanyId],
  );

  const closeModal = useCallback(() => {
    navigate(generatePath(`${EVENTS_PATH}/${UPCOMING_PATH}/${ONE_OFF_EVENTS_PATH}`));
  }, [navigate]);

  const onSubmit = useCallback(async () => {
    if (!selectedCompanyId || !selectedEmployeeIds || !selectedEventDate || !typeSelect) return;

    selectedEmployeeIds.forEach((employeeId) => {
      const fullSelectedEmployee = employees?.find((employee) => employee.id === employeeId);

      const input = {
        ...getEventDefaultValues(),
        companyId: selectedCompanyId,
        companyDeliveryMethod: fullSelectedCompany?.deliveryMethod,
        employeeId: fullSelectedEmployee?.id,
        employeeOfficeId: fullSelectedEmployee?.officeId,
        eventDate: format(selectedEventDate, 'yyyy-MM-dd'),
        type: typeSelect,
        customType: typeText,
        recipientId: `${process.env.REACT_APP_EMPLOYEES_TABLE_NAME}|${fullSelectedEmployee?.id}`,
        referenceEventId: null,
      };
      createOneOffEvent({ variables: { input: JSON.stringify(input) } })
        .then(() => {
          toast.success(`Successfully created the event for ${fullSelectedEmployee?.fullName}.`);
          closeModal();
        })
        .catch((error: ApolloError) => {
          toast.error(`There was an error creating the event for ${fullSelectedEmployee?.fullName}.`);
          error.graphQLErrors.forEach((err) => toast.error(err.message));
        });
    });
  }, [
    closeModal,
    createOneOffEvent,
    selectedEmployeeIds,
    typeSelect,
    typeText,
    selectedCompanyId,
    selectedEventDate,
    employees,
    fullSelectedCompany?.deliveryMethod,
  ]);

  if (companiesLoading || employeesLoading) return <Loading />;

  return (
    <Modal
      closeModal={closeModal}
      confirmLabel='Create'
      headline='New One-Off Event'
      onConfirmClick={onSubmit}
      confirmDisabled={
        !selectedEmployeeIds || !typeSelect || (typeSelect === TEventTypes.Other && !typeText) || !selectedEventDate
      }
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      showConfirmButton={true}
      size='large'
    >
      <p className={styles.supportText}>Select the Employee whose event is going to be created.</p>
      <div className={styles.filters}>
        <Select
          className={styles.filter}
          label='Company'
          multiple={false}
          name='companyId'
          onChange={(v) => {
            setSelectedCompanyId(v as string);
            setSelectedEmployeeIds(undefined);
          }}
          options={companiesList}
          value={selectedCompanyId}
          variant='outlined'
        />
        <Select
          className={styles.filter}
          label='Employee'
          multiple={true}
          disabled={!selectedCompanyId}
          name='employeeId'
          onChange={(v) => {
            setSelectedEmployeeIds(v as string[]);
          }}
          options={employeesList}
          value={selectedEmployeeIds}
          variant='outlined'
        />
      </div>
      <div className={styles.filters}>
        <Select
          className={styles.filter}
          label='Event Type'
          multiple={false}
          name='type'
          onChange={(v) => {
            setTypeSelect(v as TEventTypes);
            setTypeText(undefined);
          }}
          options={Object.entries(ONE_OFF_EVENT_TYPES).map(([key, value]) => ({ label: value, value: key }))}
          value={typeSelect}
          variant='outlined'
        />
        {typeSelect === TEventTypes.Other && (
          <TextField
            className={styles.filter}
            disabled={typeSelect !== TEventTypes.Other}
            label='Custom Event Type'
            multiline={false}
            name='typeCustom'
            onChange={(v) => {
              setTypeText(v as string);
            }}
            value={typeText}
            variant='outlined'
          />
        )}
      </div>
      <div className={styles.filters}>
        <DatePicker
          label='Event Date'
          className={styles.filter}
          name='eventDate'
          onChange={(v) => {
            if (v !== null) setSelectedEventDate(v);
          }}
          value={selectedEventDate}
          variant='outlined'
        />
      </div>
    </Modal>
  );
};
