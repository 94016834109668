import { useMemo } from 'react';

export const useMap = <T extends Record<string, any>>(data: T[], idColumn = 'id') => {
  const map = useMemo(() => {
    return data.reduce(
      (agg, item) => {
        agg[item[idColumn]] = item;
        return agg;
      },
      {} as Record<string, T>,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(data), idColumn]);

  return map;
};
