import {
  DocumentNode,
  LazyQueryHookOptions,
  LazyQueryResultTuple,
  OperationVariables,
  TypedDocumentNode,
  useFragment,
} from '@apollo/client';
import { getFragmentName } from '@chocolate-soup-inc/cs-api-consumer-utils';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { subscribe } from '../../config/apollo/cache';

type TUserFragmentOrFetchProps<TQuery, TQueryVariables extends OperationVariables, TSubscriptionVariables> = {
  fragmentDoc: DocumentNode | TypedDocumentNode;
  subscriptionDoc?: DocumentNode | TypedDocumentNode;
  subscriptionVariables?: TSubscriptionVariables;
  useLazyQuery: (
    baseOptions: LazyQueryHookOptions<TQuery, TQueryVariables>,
  ) => LazyQueryResultTuple<TQuery, TQueryVariables>;
  variables?: LazyQueryHookOptions<TQuery, TQueryVariables>['variables'];
  __typename: string;
};

export const useFragmentOrLazyFetch = <
  T extends Record<string, any>,
  TQuery,
  TQueryVariables extends OperationVariables,
  TSubscriptionVariables = undefined,
>(
  props: TUserFragmentOrFetchProps<TQuery, TQueryVariables, TSubscriptionVariables>,
) => {
  const { fragmentDoc, subscriptionDoc, subscriptionVariables, useLazyQuery, variables, __typename } = props;

  const { data: fragmentData, missing } = useFragment<T, TQueryVariables>({
    from: {
      ...(variables || {}),
      __typename,
    },
    fragment: fragmentDoc,
    fragmentName: getFragmentName(fragmentDoc),
  });

  const [fetchData, { data: fetchedData, error, loading }] = useLazyQuery({
    variables,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (subscriptionDoc && subscriptionVariables) {
      subscribe({
        query: subscriptionDoc,
        variables: subscriptionVariables,
      });
    }
  }, [subscriptionDoc, subscriptionVariables]);

  return {
    data: fragmentData,
    error,
    fetchedData,
    fetchData,
    loading,
    missing,
  };
};

export const useFragmentOrFetch = <
  T extends Record<string, any>,
  TQuery,
  TQueryVariables extends OperationVariables,
  TSubscriptionVariables = undefined,
>(
  props: TUserFragmentOrFetchProps<TQuery, TQueryVariables, TSubscriptionVariables>,
) => {
  const { variables } = props;
  const [fetched, setFetched] = useState<boolean>(false);
  const { data, error, fetchData, fetchedData, loading, missing } = useFragmentOrLazyFetch<
    T,
    TQuery,
    TQueryVariables,
    TSubscriptionVariables
  >(props);

  useEffect(() => {
    if ((_.isEmpty(data) || !_.isEmpty(missing)) && !loading && !error && !fetched) {
      setFetched(true);
      fetchData({
        variables,
      });
    }
  }, [data, error, fetchData, fetched, loading, missing, variables]);

  return {
    data: _.isEmpty(data) ? undefined : data,
    fetchedData,
    error,
    loading,
  };
};
