import { ExtendedFAB, Icon, readableDate, TableInner, Tooltip } from '@chocolate-soup-inc/cs-frontend-components';
import { RowSelectionState } from '@tanstack/react-table';
import { createRef, useCallback, useEffect, useMemo, useState } from 'react';
import { ONE_OFF_EVENT_TYPES, translateEventType } from '../../../entities/events/shared';
import { isDependant } from '../../../entities/recipients/shared';
import { TEventTypes, useUnskipEventMutation } from '../../../generated/graphql';

import clsx from 'clsx';
import { addMinutes, format } from 'date-fns';
import _ from 'lodash';
import { quickScore } from 'quick-score';
import { Filters, TFiltersProps } from '../../../components/filters/Filters';
import { TablePage, TTablePageRef } from '../../../components/tablePage/TablePage';
import tableStyles from '../../../components/tablePage/TablePage.module.scss';
import { useFilter } from '../../../contexts/filters';
import { getReadableRelationshipType } from '../../../entities/dependants/shared';
import { TEventType, useQueryAllSkippedEvents } from '../../../entities/events/queries';
import styles from './SkippedEvents.module.scss';

const generateMonthFilter = (d: Date) => {
  return `${d.getFullYear()}-${d.getMonth() + 1}`;
};

export const SkippedEvents = () => {
  const { filtersPageMode } = useFilter();
  useEffect(() => {
    filtersPageMode();
  }, []); //eslint-disable-line

  const [selectedRows, setSelectedRows] = useState<string[]>();
  const [unskipLoading, setUnskipLoading] = useState<boolean>(false);

  const tableRef = createRef<TTablePageRef>();

  const { data: skippedEvents, error, loading } = useQueryAllSkippedEvents();
  const [unskipEvent] = useUnskipEventMutation();

  const unskipEvents = useCallback(() => {
    setUnskipLoading(true);
    return Promise.all(
      (selectedRows || []).map((id) => {
        return new Promise((resolve, reject) => {
          const event = skippedEvents.find((e) => e.id === id);

          if (event == null) return resolve(undefined);

          unskipEvent({
            variables: {
              id: event.id,
              companyId: event.companyId,
              version: event._version,
            },
          })
            .then(resolve)
            .catch(reject);
        });
      }),
    )
      .then()
      .then(() => {
        tableRef.current?.resetConfirmationModal();
        setUnskipLoading(false);
        setSelectedRows(undefined);
      });
  }, [selectedRows, skippedEvents, tableRef, unskipEvent]);

  const onUnskipEventsClick = useCallback(() => {
    tableRef.current?.setConfirmationProps({
      headline: `Unskip ${selectedRows?.length} Events`,
      supportingText: `Are you sure you want to unskip these ${selectedRows?.length} events?`,
      confirmLabel: 'Unskip',
      onConfirmClick: unskipEvents,
      open: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows?.length, tableRef, tableRef.current, unskipEvents]);

  const onRowSelectionChange = useCallback((rowSelection: RowSelectionState) => {
    console.log('selectedRows?:', rowSelection);
    setSelectedRows(Object.keys(rowSelection));
  }, []);

  const getRowId = useCallback((event: TEventType) => {
    return event.id;
  }, []);

  const [monthFilter, setMonthFilter] = useState<string>();
  const [companyFilter, setCompanyFilter] = useState<string>();
  const [eventTypeFilter, setEventTypeFilter] = useState<TEventTypes>();
  const [recipientNameFilter, setRecipientNameFilter] = useState<string>();

  const filterByMonth = useCallback(
    (e: TEventType) => {
      let d = new Date(e.eventDate);
      d = addMinutes(d, d.getTimezoneOffset());
      if (monthFilter && generateMonthFilter(d) !== monthFilter) return false;
      return true;
    },
    [monthFilter],
  );

  const filterByCompany = useCallback(
    (e: TEventType) => {
      if (companyFilter && e.companyId !== companyFilter) return false;
      return true;
    },
    [companyFilter],
  );

  const filterByEventType = useCallback(
    (e: TEventType) => {
      if (eventTypeFilter && e.type !== eventTypeFilter) return false;
      return true;
    },
    [eventTypeFilter],
  );

  const filterByRecipientName = useCallback(
    (e: TEventType) => {
      if (
        recipientNameFilter != null &&
        recipientNameFilter !== '' &&
        (e.recipient?.fullName == null || quickScore(e.recipient.fullName, recipientNameFilter) < 0.7)
      ) {
        return false;
      }

      return true;
    },
    [recipientNameFilter],
  );

  const months = useMemo(() => {
    return _.uniq(
      _.compact(
        skippedEvents.map((e) => {
          let d = new Date(e.eventDate);
          d = addMinutes(d, d.getTimezoneOffset());

          return generateMonthFilter(d);
        }),
      ),
    ).sort((a, b) => b.localeCompare(a));
  }, [skippedEvents]);

  const companies = useMemo(() => {
    return _.uniqBy(_.compact(skippedEvents.map((e) => e.company)), 'id');
  }, [skippedEvents]);

  const filters = useMemo(() => {
    const filtersList: TFiltersProps['filters'] = [
      {
        includeEmptyOption: true,
        type: 'singleSelect',
        label: 'Date',
        name: 'date',
        onChange: (v) => {
          if (v == null) setMonthFilter(undefined);
          else setMonthFilter(v as string);
        },
        options: months.map((m) => ({ label: readableDate(m, 'MMM, yyyy') as string, value: m })),
        value: monthFilter,
      },
    ];

    filtersList.push({
      includeEmptyOption: true,
      type: 'singleSelect',
      label: 'Company',
      name: 'companyId',
      onChange: (v) => {
        if (v == null) setCompanyFilter(undefined);
        else setCompanyFilter(v as string);
      },
      options: companies.map((c) => ({ label: c.name, value: c.id })),
      value: companyFilter,
    });

    filtersList.push({
      type: 'textInput',
      label: 'Recipient Name',
      name: 'recipientName',
      onChange: (v) => {
        if (v == null) setRecipientNameFilter(undefined);
        else setRecipientNameFilter(v as string);
      },
      value: recipientNameFilter,
    });

    filtersList.push({
      includeEmptyOption: true,
      type: 'singleSelect',
      label: 'Event Type',
      name: 'eventType',
      onChange: (v) => {
        if (v == null) setEventTypeFilter(undefined);
        else setEventTypeFilter(v as TEventTypes);
      },
      options: [
        {
          label: 'Birthday',
          value: TEventTypes.Birthday,
        },
        {
          label: 'Work Anniversary',
          value: TEventTypes.WorkAnniversary,
        },
        {
          label: 'New Hire',
          value: TEventTypes.NewHire,
        },
        ...Object.entries(ONE_OFF_EVENT_TYPES).map(([key, value]) => ({ label: value, value: key })),
      ],
      value: eventTypeFilter,
    });

    return filtersList;
  }, [companies, companyFilter, eventTypeFilter, monthFilter, months, recipientNameFilter]);

  const filteredSkippedEvents = useMemo(() => {
    return skippedEvents.filter((e) => {
      return filterByCompany(e) && filterByMonth(e) && filterByEventType(e) && filterByRecipientName(e);
    });
  }, [filterByCompany, filterByEventType, filterByMonth, filterByRecipientName, skippedEvents]);

  return (
    <TablePage actionsLoading={unskipLoading} dataLoading={loading} error={error} ref={tableRef} title='Skipped Events'>
      <Filters filters={filters} />
      <TableInner<TEventType>
        enableSorting={true}
        data={filteredSkippedEvents}
        emptyText={skippedEvents.length === 0 ? 'No skipped events.' : 'No skipped events for the current filters.'}
        expandable={true}
        fillSelectedRows={true}
        fixedHeader={true}
        forceExpanded={true}
        getRowId={getRowId}
        hideSubRowsSelect={true}
        hoverableRows={!unskipLoading}
        onRowSelectionChange={onRowSelectionChange}
        selectableRows={true}
        selectingChildSelectsFather={true}
        virtual={true}
        columns={[
          {
            header: 'Company Name',
            accessorKey: 'company.name',
            cell: ({ cell }) => {
              return (
                <div className={clsx(tableStyles.tableSpacePrimary)}>
                  <span>{cell.row.original.company?.name ?? '-'}</span>
                </div>
              );
            },
          },
          {
            header: 'Recipient Name',
            accessorKey: 'recipient.fullName',
            cell: ({ cell }) => {
              let text = '';
              if (cell.row.original.employee?.pausePeriod) {
                const { fromDate, toDate } = cell.row.original.employee.pausePeriod;
                if (fromDate) text += ` since ${readableDate(fromDate)}`;
                if (toDate) text += ` to ${readableDate(toDate)}`;
              }
              return (
                <div className={clsx(styles.companyName, tableStyles.tableSpacePrimary)}>
                  {cell.row.original.employee?.isPauseEnabled && (
                    <Tooltip message={`Employee paused${text}.`}>
                      <Icon icon='pause_circle' />
                    </Tooltip>
                  )}
                  <span>{cell.row.original.recipient?.fullName}</span>
                </div>
              );
            },
          },
          {
            header: 'Event Type',
            accessorKey: 'type',
            cell: ({ cell }) => {
              return (
                <div className={clsx(tableStyles.tableSpaceSecondary)}>
                  <span>{translateEventType(cell.row.original.type)}</span>
                </div>
              );
            },
          },
          {
            header: 'Event Date',
            accessorKey: 'eventDate',
            cell: ({ cell }) => {
              return (
                <div className={clsx(tableStyles.tableSpaceTertiary)}>
                  <span>{readableDate(cell.row.original.eventDate)}</span>
                </div>
              );
            },
          },
          {
            header: 'Relationship',
            accessorKey: 'recipient',
            cell: ({ cell }) => {
              const { recipient } = cell.row.original;
              return (
                <div className={clsx(tableStyles.tableSpaceSecondary)}>
                  <span>
                    {recipient && isDependant(recipient)
                      ? `${getReadableRelationshipType(recipient)} of ${recipient.employee?.fullName}`
                      : 'Employee'}
                  </span>
                </div>
              );
            },
          },
          {
            header: 'Skipped At',
            accessorKey: 'skippedAt',
            cell: ({ cell }) => {
              return (
                <div className={clsx(tableStyles.tableSpacePrimary)}>
                  <span>{format(new Date(cell.row.original.skippedAt as string), 'do MMM, yyyy hh:mmaaa')}</span>
                </div>
              );
            },
          },
        ]}
      />
      {selectedRows && selectedRows.length > 0 && (
        <div className={styles.actions}>
          <ExtendedFAB
            label={`Unskip ${selectedRows.length} Events`}
            leadingIcon='exit_to_app'
            loading={unskipLoading}
            onClick={onUnskipEventsClick}
            variant='tertiary'
          />
        </div>
      )}
    </TablePage>
  );
};
